import React, { useEffect, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createProduct } from "../../redux/actions/product";
import { categoriesData } from "../../static/data";
import { toast } from "react-toastify";
import ColorPicker from './ColorPicker';
//import MultiSelector from "./MultiSelect";
import TagInput from "./TagInput";
import QuantityPriceInput from "./QuantityPriceInput";
import PackagingInput from "./PackageInput";



const CreateProduct = () => {
  const { seller } = useSelector((state) => state.seller);
  const { success, error } = useSelector((state) => state.products);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [images, setImages] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState([]);
  const [packaging, setPackaging] = useState([]);
  const [stock, setStock] = useState();
  const [maxOrder, setMaxOrder] = useState();
  const [minOrder, setMinOrder] = useState();
  const [priceInput, setPriceInput] = useState('');
  const [samplePriceInput, setSamplePriceInput] = useState('');
  const [metrics, setMetrics] = useState('');
  const [selectedColor, setSelectedColor] = useState('#000000');
  const [isCartChecked, setIsCartChecked] = useState(false);
  const [isContactChecked, setIsContactChecked] = useState(false);

  const handleCartChange = (e) => {
    setIsCartChecked(e.target.checked);
  };

  const handleContactChange = (e) => {
    setIsContactChecked(e.target.checked);
  };



  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      toast.success("Product created successfully!");
      navigate("/dashboard-products");
    //  window.location.reload();
    }
  }, [dispatch, error, success]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    setImages([]);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImages((old) => [...old, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleColorChange = (color) => {
    setSelectedColor(color);
  };
  const handlePriceChange = (newValue) => {
    setPriceInput(newValue);
  };
  const handleSamplePriceChange = (newValue) => {
    setSamplePriceInput(newValue);
  };
  const handlePackagingChange = (newValue) => {

    console.log('this has changed', newValue)
    setPackaging(newValue);

  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newForm = new FormData();
    console.log('67', priceInput);
    images.forEach((image) => {
      newForm.set("images", image);
    });
    newForm.append("name", name);
    newForm.append("description", description);
    newForm.append("category", category);
    newForm.append("tags", tags);
    // newForm.append("minOrder", minOrder);
    // newForm.append("maxOrder", maxOrder);
    newForm.append("isCartChecked", isCartChecked);
    newForm.append("isContactChecked", isContactChecked);
    // newForm.append("discountPrice", discountPrice);
    newForm.append("metrics", metrics);
    newForm.append("selectedColor", selectedColor);
    newForm.append("packaging", packaging);
    newForm.append("stock", stock);
    newForm.append("shopId", seller._id);
    newForm.append("priceInput", JSON.stringify(priceInput));
    newForm.append("samplePriceInput", JSON.stringify(samplePriceInput));
    console.log('68', priceInput, selectedColor, packaging, samplePriceInput);

    dispatch(
      createProduct({
        name,
        description,
        category,
        tags,
        stock,
        shopId: seller._id,
        images,
        priceInput,
        samplePriceInput,
        metrics,
        selectedColor,
        packaging,
        // minOrder,
        // maxOrder,
        isCartChecked,
        isContactChecked
      })
    );
  };

  return (
    <div className="w-full 600px:w-[60%] bg-white  shadow h-[90vh] rounded-[4px] p-5 overflow-y-scroll">

      <h5 className="text-[30px] font-Poppins text-center">Create Product</h5>
      <form onSubmit={handleSubmit}>
        <br />
        <div>
          <label className="pb-2">
            Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="name"
            value={name}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your product name..."
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
            Description <span className="text-red-500">*</span>
          </label>
          <textarea
            cols="30"
            required
            rows="8"
            type="text"
            name="description"
            value={description}
            className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter your product description..."
          ></textarea>
        </div>
        <br />

        <div className="flex flex-col md:flex-row">
          <div className="flex-1 md:mr-2 mb-2 md:mb-0">
            <label className="pb-2">
              Category <span className="text-red-500">*</span>
            </label>
            <select
              className="w-full mt-2 border h-[35px] rounded-[5px]"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="Choose a category">Choose a category</option>
              {categoriesData &&
                categoriesData.map((i) => (
                  <option value={i.title} key={i.title}>
                    {i.title}
                  </option>
                ))}
            </select>
          </div>

          <div className="flex-1 md:ml-2">
            <label className="pb-2">Tags</label>
            <TagInput label="Tags" tags={tags} setTags={setTags} />
          </div>
        </div>

        <br />
        <div>
          <label className="pb-2">Packaging</label>
          <PackagingInput onPackagingChange={handlePackagingChange} />
        </div>
        <br />

        <br />
        <div>
          <label className="pb-2">
            Price (With Discount) <span className="text-red-500">*</span>
          </label>
          <QuantityPriceInput onPriceChange={handlePriceChange} />
          <br />
          <hr />
          <br />
          <label className="pb-2 mt-5">
            Sample Product Price (With Discount) <span className="text-red-500">*</span>
          </label>
          <QuantityPriceInput onPriceChange={handleSamplePriceChange} />
        </div>
        <br />

        <div className="flex flex-col md:flex-row">
          <div className="flex-1 md:mr-2 mb-2 md:mb-0">
            <label className="pb-2">
              Product Stock <span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              name="price"
              value={stock}
              className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setStock(e.target.value)}
              placeholder="Enter your product stock..."
            />
          </div>

          <div className="flex-1 md:ml-2">
            <label className="block pb-2">Pick Your Color</label>
            <ColorPicker defaultColor={selectedColor} onColorChange={handleColorChange} />
          </div>
        </div>
        <br />

        {/* <div className="flex flex-col md:flex-row">
          <div className="flex-1 md:mr-2 mb-2 md:mb-0">
            <label className="pb-2">Maximum Order</label>
            <input
              type="number"
              name="maxOrder"
              value={maxOrder}
              className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setMaxOrder(e.target.value)}
              placeholder="Enter maximum order..."
            />
          </div>

          <div className="flex-1 md:ml-2">
            <label className="pb-2">Minimum Order</label>
            <input
              type="number"
              name="minOrder"
              value={minOrder}
              className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setMinOrder(e.target.value)}
              placeholder="Enter minimum order..."
            />
          </div>
        </div>
        <br /> */}

        <div className="flex flex-col md:flex-row">
          <div className="flex-1 md:mr-2 mb-2 md:mb-0">
            <label className="pb-2">
              Upload Images <span className="text-red-500">*</span>
            </label>
            <input
              type="file"
              id="upload"
              className="hidden"
              multiple
              onChange={handleImageChange}
            />
            <div className="w-full flex items-center flex-wrap">
              <label htmlFor="upload">
                <AiOutlinePlusCircle size={30} className="mt-3" color="#555" />
              </label>
              {images &&
                images.map((i) => (
                  <img
                    src={i}
                    key={i}
                    alt=""
                    className="h-[120px] w-[120px] object-cover m-2"
                  />
                ))}
            </div>
            <br />
          </div>

          <div className="flex-1 md:ml-2">
            <label className="pb-2">
              Checkout Option <span className="text-red-500">*</span>
            </label>
            <div className="flex items-center space-x-6 mt-2">
              <div className="flex items-center">
                <input
                  id="cart-checkbox"
                  type="checkbox"
                  checked={isCartChecked}
                  onChange={handleCartChange}
                  className="form-checkbox h-5 w-5 text-blue-600 transition duration-150 ease-in-out"
                />
                <label htmlFor="cart-checkbox" className="ml-2 text-sm font-medium text-gray-700">Shopping Cart</label>
              </div>
              <div className="flex items-center">
                <input
                  id="contact-checkbox"
                  type="checkbox"
                  checked={isContactChecked}
                  onChange={handleContactChange}
                  className="form-checkbox h-5 w-5 text-blue-600 transition duration-150 ease-in-out"
                />
                <label htmlFor="contact-checkbox" className="ml-2 text-sm font-medium text-gray-700">Direct Contact</label>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <input
              type="submit"
              value="Create"
              className="mt-4 cursor-pointer block w-full px-3 h-10 border border-gray-300 rounded-md placeholder-gray-400 bg-blue-500 text-white focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
        </div>
      </form>
    </div>);
};

export default CreateProduct;
