import React, { useState, useEffect } from "react";
import styles from "../../styles/styles";
import { categoriesData } from "../../static/data";
import {
  AiOutlineHeart,
  AiOutlineSearch,
  AiOutlineShoppingCart,
  AiOutlineMessage,
} from "react-icons/ai";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { BiMenuAltLeft } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import DropDown from "./DropDown";
import Navbar from "./Navbar";
import { useSelector, useDispatch } from "react-redux";
import Cart from "../cart/Cart";
import Wishlist from "../Wishlist/Wishlist";
import Message from "../message/Message";
import { RxCross1 } from "react-icons/rx";
import logo from "../../Assests/images/logoo.png";
import { Link, useNavigate } from 'react-router-dom';
import { FaUserCircle, FaSignOutAlt } from 'react-icons/fa';
import axios from "axios";
import { server } from "../../server";
import { getAllSellers } from "../../redux/actions/sellers";


const Header = ({ activeHeading }) => {
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const { isSeller, sellers } = useSelector((state) => state.seller);
  const { wishlist } = useSelector((state) => state.wishlist);
  //const { message } = useSelector((state) => state.message);
  const { cart } = useSelector((state) => state.cart);
  const { allProducts } = useSelector((state) => state.products);
  const { allEvents, isLoading } = useSelector((state) => state.events);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState(null);
  const [active, setActive] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const [openWishlist, setOpenWishlist] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [openMenue, setOpenMenue] = useState(false);

  const message = JSON.parse(localStorage.getItem("allMessage"));
  const unreadMessage = JSON.parse(localStorage.getItem("unreadMessage"));
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();



  useEffect(() => {
    dispatch(getAllSellers());
  }, [dispatch]);

  const handleLogout = async () => {

    axios.get(`${server}/user/logout`, {
      withCredentials: true,
    });
    window.location.reload();

  };



  const handleSearchChange = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    const filteredProducts = allProducts && allProducts.filter((product) =>

      product.name.toLowerCase().includes(term)
    );

    console.log('shoppe2', filteredProducts);
    console.log('shoppe3', sellers);
    const filteredShops = sellers && sellers.filter((shop) =>
      shop.name.toLowerCase().includes(term)
    );
    console.log('shoppe', filteredShops);
    const filteredEvents = allEvents.filter((event) =>
      event.name.toLowerCase().includes(term)
    );
    const combinedResults = [
      ...(Array.isArray(filteredProducts) ? filteredProducts.map((item) => ({ ...item, type: "product" })) : []),
      ...(Array.isArray(filteredShops) ? filteredShops.map((item) => ({ ...item, type: "shop" })) : []),
      ...(Array.isArray(filteredEvents) ? filteredEvents.map((item) => ({ ...item, type: "event" })) : []),
    ];

    setSearchData(combinedResults);
  };

  window.addEventListener("scroll", () => {
    if (window.scrollY > 70) {
      setActive(true);
    } else {
      setActive(false);
    }
  });

  return (
    <>
      <div className={`${styles.section}`}>
        <div className="hidden 800px:h-[50px] 800px:my-[20px] 800px:flex items-center justify-between">
          <div>
            <Link to="/">
              <img
                src={logo}
                width={120}
                height={35}
                alt=""
              />
            </Link>

          </div>
          {/* search box */}
          <div className="w-[50%] relative">
            <input
              type="text"
              placeholder="Search Product, Shop, or Event..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="h-[40px] w-full px-4 border border-blue-500 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
            />
            <AiOutlineSearch
              size={30}
              className="absolute right-2 top-1.5 cursor-pointer text-blue-500"
            />
            {searchData && searchData.length !== 0 ? (
              <div className="absolute min-h-[auto] bg-slate-50 shadow-sm-2 z-[9] p-4">
                {searchData.map((item, index) => (
                  <Link
                    key={index}
                    to={`/${item.type === 'product' || 'event' ? 'product' : item.type}/${item._id}${item.type === 'event' ? "/?isEvent=true" : ""}`}
                    className="block p-2 hover:bg-blue-100 flex items-center"
                  >
                    {/* {JSON.stringify(item)} */}
                    {item.type === 'product' || 'event' ? <img
                      src={`${item?.images[0]?.url}`}
                      alt=""
                      className="w-[40px] h-[40px] mr-3 object-cover rounded-full"
                    /> :
                      <img
                        src={`${item.avatar.url}`}
                        alt=""
                        className="w-[40px] h-[40px] mr-3 object-cover rounded-full"
                      />
                    }
                    <div>
                      <h1 className="font-semibold">{item.name}</h1>
                      <p className="text-sm text-gray-500 capitalize">{item.type}</p>
                    </div>
                  </Link>
                ))}
              </div>
            ) : null}
          </div>

          <div className={`${styles.button}`}>
            <Link to={`${isSeller ? "/dashboard" : "/shop-create"}`}>
              <h1 className="text-[#fff] flex items-center">
                {isSeller ? "Go Dashboard" : "Become Seller"}{" "}
                <IoIosArrowForward className="ml-1" />
              </h1>
            </Link>
          </div>

        </div>
      </div>
      <div
        className={`${active === true ? "shadow-sm fixed top-0 left-0 z-10" : null
          } transition hidden 800px:flex items-center justify-between w-full bg-[#0476D0] h-[70px]`}
      >
        <div
          className={`${styles.section} relative ${styles.noramlFlex} justify-between`}
        >
          {/* categories */}
          <div onClick={() => setDropDown(!dropDown)}>
            <div className="relative h-[60px] mt-[10px] w-[270px] hidden 1000px:block">
              <BiMenuAltLeft size={30} className="absolute top-3 left-2" />
              <button
                className={`h-[100%] w-full flex justify-between items-center pl-10 bg-white font-sans text-lg font-[500] select-none rounded-t-md`}
              >
                All Categories
              </button>
              <IoIosArrowDown
                size={20}
                className="absolute right-2 top-4 cursor-pointer"
                onClick={() => setDropDown(!dropDown)}
              />
              {dropDown ? (
                <DropDown
                  categoriesData={categoriesData}
                  setDropDown={setDropDown}
                />
              ) : null}
            </div>
          </div>
          {/* navitems */}
          <div className={`${styles.noramlFlex}`}>
            <Navbar active={activeHeading} />
          </div>

          <div className="flex">
            <div className={`${styles.noramlFlex}`}>
              <div
                className="relative cursor-pointer mr-[15px]"
                onClick={() => setOpenWishlist(true)}
              >
                <AiOutlineHeart size={30} color="rgb(255 255 255 / 83%)" />
                <span className="absolute right-0 top-0 rounded-full bg-orange-400 w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px] leading-tight text-center">
                  {wishlist && wishlist.length}
                </span>
              </div>
            </div>

            <div className={`${styles.noramlFlex}`}>
              <div
                className="relative cursor-pointer mr-[15px]"
                onClick={() => setOpenCart(true)}
              >
                <AiOutlineShoppingCart
                  size={30}
                  color="rgb(255 255 255 / 83%)"
                />
                <span className="absolute right-0 top-0 rounded-full bg-orange-400 w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px] leading-tight text-center">
                  {cart && cart.length}
                </span>

              </div>
            </div>
            {user &&
              <div className={`${styles.noramlFlex}`}>
                <div
                  className="relative cursor-pointer mr-[15px]"
                  onClick={() => setOpenMessage(true)}
                >
                  <AiOutlineMessage
                    size={30}
                    color="rgb(255 255 255 / 83%)"
                  />
                  <span className="absolute right-0 top-0 rounded-full bg-orange-400 w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px] leading-tight text-center">
                    {unreadMessage.length}
                  </span>

                </div>
              </div>}

            {/* <div className={`${styles.noramlFlex}`}>
              <div className="relative cursor-pointer mr-[15px]">
                {isAuthenticated ? (
                  <Link to="/profile">
                    <img
                      src={`${user?.avatar?.url}`}
                      className="w-[35px] h-[35px] rounded-full"
                      alt=""
                    />
                  </Link>
                ) : (
                  <Link to="/login">
                    <CgProfile size={30} color="rgb(255 255 255 / 83%)" />
                  </Link>
                )}
              </div>
            </div> */}
            <div className={`relative flex`}>
              <div
                className="cursor-pointer relative flex items-center  mr-[15px]"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                {isAuthenticated ? (
                  <img
                    src={user?.avatar?.url}
                    className="w-[35px] h-[35px] rounded-full"
                    alt="User Avatar"
                  />
                ) : (
                  <Link to="/login">
                    <CgProfile size={30} color="rgb(255 255 255 / 83%)" />
                  </Link>
                )}
              </div>

              {dropdownOpen && (
                <div className="mt-9 absolute left-0  w-48 bg-gray-100 rounded-md shadow-lg py-1 z-20 ">
                  <Link
                    to="/profile"
                    className="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100"
                    onClick={() => setDropdownOpen(false)}
                  >
                    <FaUserCircle className="mr-3" />
                    Profile
                  </Link>
                  <button
                    onClick={handleLogout}
                    className="flex items-center w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-100"
                  >
                    <FaSignOutAlt className="mr-3" />
                    Logout
                  </button>
                </div>
              )}
            </div>

            {/* cart popup */}
            {openCart ? <Cart setOpenCart={setOpenCart} /> : null}

            {/* wishlist popup */}
            {openWishlist ? (
              <Wishlist setOpenWishlist={setOpenWishlist} />
            ) : null}
            {/* message popup */}
            {openMessage ? (
              <Message setOpenMessage={setOpenMessage} />
            ) : null}
          </div>
        </div>
      </div>

      {/* mobile header */}
      <div
        className={`${active === true ? "shadow-sm fixed top-0 left-0 z-10" : null
          }
      w-full h-[60px] bg-[#fff] z-50 top-0 left-0 shadow-sm 800px:hidden`}
      >
        <div className="w-full flex items-center justify-between">
          <div>
            <BiMenuAltLeft
              size={40}
              className="ml-4"
              onClick={() => setOpenMenue(true)}
            />
          </div>
          <div>
            <Link to="/">
              <img src={logo} width={90} height={20} alt="Logo" />
            </Link>
          </div>
          <div>
            <div
              className="relative mr-[20px]"
              onClick={() => setOpenCart(true)}
            >
              <AiOutlineShoppingCart size={30} />
              <span class="absolute right-0 top-0 rounded-full bg-orange-400 w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px]  leading-tight text-center">
                {cart && cart.length}
              </span>
            </div>
          </div>
          {/* cart popup */}
          {openCart ? <Cart setOpenCart={setOpenCart} /> : null}

          {/* wishlist popup */}
          {openWishlist ? <Wishlist setOpenWishlist={setOpenWishlist} /> : null}
          {/* message popup */}
          {openMessage ? <Message setOpenMessage={setOpenMessage} /> : null}
        </div>

        {/* header sidebar */}
        {openMenue && (
          <div
            className={`fixed w-full bg-[#0000005f] z-20 h-full top-0 left-0`}
          >
            <div className="fixed w-[70%] bg-[#fff] h-screen top-0 left-0 z-10 overflow-y-scroll">
              <div className="w-full justify-between flex pr-3">
                <div>
                  {/* <Message setOpenMessage={setOpenMessage} /> */}
                  <div
                    className="relative mr-[15px]"
                    onClick={() => setOpenWishlist(true) || setOpenMenue(false)}
                  >
                    <AiOutlineHeart size={30} className="mt-5 ml-3" />
                    <span class="absolute right-0 top-0 rounded-full bg-orange-400 w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px]  leading-tight text-center">
                      {wishlist && wishlist.length}
                    </span>
                  </div>
                </div>
                <RxCross1
                  size={30}
                  className="ml-4 mt-5"
                  onClick={() => setOpenMenue(false)}
                />

              </div>

              <div className="my-8 w-[92%] m-auto h-[40px relative]">
                <input
                  type="search"
                  placeholder="Search Product..."
                  className="h-[40px] w-full px-2 border-[#0476D0] border-[2px] rounded-md"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                {/* {JSON.stringify(searchData)} */}
                {searchData && searchData.length !== 0 ? (
                  <div className="absolute min-h-[auto] bg-slate-50 shadow-sm-2 z-[9] p-4">
                    {searchData.map((item, index) => (
                      <Link
                        key={index}
                        to={`/${item.type === 'product' || 'event' ? 'product' : item.type}/${item._id}${item.type === 'event' ? "/?isEvent=true" : ""}`}
                        className="block p-2 hover:bg-blue-100 flex items-center"
                      >
                        {/* {JSON.stringify(item)} */}
                        {item.type === 'product' || 'event' ? <img
                          src={`${item?.images[0]?.url}`}
                          alt=""
                          className="w-[40px] h-[40px] mr-3 object-cover rounded-full"
                        /> :
                          <img
                            src={`${item.avatar.url}`}
                            alt=""
                            className="w-[40px] h-[40px] mr-3 object-cover rounded-full"
                          />
                        }
                        <div>
                          <h1 className="font-semibold">{item.name}</h1>
                          <p className="text-sm text-gray-500 capitalize">{item.type}</p>
                        </div>
                      </Link>
                    ))}
                  </div>
                ) : null}
              </div>

              <Navbar active={activeHeading} />
              <div className={`${styles.button} ml-4 !rounded-[4px]`}>
                <Link to="/shop-create">
                  <h1 className="text-[#fff] flex items-center">
                    Become Seller <IoIosArrowForward className="ml-1" />
                  </h1>
                </Link>
              </div>
              <br />

              <div className="relative flex w-full ml-2 justify-left items-center space-x-4">
                {isAuthenticated ? (
                  <div className="relative group">
                    <Link to="/profile" className="flex items-center space-x-4">
                      <img
                        src={`${user?.avatar?.url}`}
                        alt={`${user?.name}`}
                        className="w-[60px] h-[60px] rounded-full border-[3px] border-[#0eae88] object-cover"
                      />
                      <span className="text-black font-semibold text-lg">{user?.name}</span>
                    </Link>
                    <div className=" group-hover:block absolute top-full mt-2 left-0 bg-white shadow-md rounded-md w-48 z-10">
                      <button
                        onClick={handleLogout}
                        className="w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center"
                      >
                        <FaSignOutAlt className="mr-3" />
                        <span>Logout</span>
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    <Link to="/login" className="text-[18px] pr-[10px] text-[#000000b7]">
                      Login /
                    </Link>
                    <Link to="/sign-up" className="text-[18px] text-[#000000b7]">
                      Sign up
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
