import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import db from "../firebase";

const useFirebaseListener = (docPath) => {
  const [latestMessages, setLatestMessages] = useState([]);
  const { user } = useSelector((state) => state.user);
  const { seller } = useSelector((state) => state.seller);

  useEffect(() => {
    const unsubscribe = db.collection("messages")
      .orderBy("createdAt", "desc")
      .onSnapshot((snapshot) => {
        const newMessages = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log('newones', newMessages);
        setLatestMessages(newMessages);
      });

    return () => unsubscribe();
  }, [docPath]);
  let unreadMessage = [];
  if (seller !== undefined) {
    // Filter unread messages based on the user's ID
    unreadMessage = latestMessages.filter(message => !message?.read && message?.user?.recipient?.id === seller?._id);
    console.log('unread', unreadMessage, 'id', user, 'latest', latestMessages);
  }
  else {
    unreadMessage = latestMessages.filter(message => !message?.read && message?.user?.recipient?.id === user?.id);
    console.log('unread', unreadMessage, 'id', user, 'latest', latestMessages);
  }
  return { message: latestMessages, unreadMessage };
};

export default useFirebaseListener;
