import React, { useState } from 'react';

const ColorPicker = ({ defaultColor, onColorChange }) => {
  const [color, setColor] = useState(defaultColor);

  const handleChange = (e) => {
    const newColor = e.target.value;
    setColor(newColor);
    onColorChange(newColor);
  };

  return (
    <input
      type="color"
      value={color}
      onChange={handleChange}
      className="border border-gray-300 rounded-[3px] focus:outline-none focus:ring-blue-500 focus:border-blue-500"
    />
  );
};

export default ColorPicker;
