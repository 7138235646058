import { useState } from 'react';
import { FiMinimize, FiMaximize } from 'react-icons/fi';
import AddressForm from "./AddressForm";


const ShippingInfo = ({
  user,
  county,
  setCounty,
  city,
  setCity,
  address,
  setAddress,
  primaryPhoneNumber,
  setPrimaryPhoneNumber,
  secondaryPhoneNumber,
  setSecondaryPhoneNumber,
  userInfo,
  setUserInfo,
  additionalInformation,
  setAdditionalInformation,
  addressType,
  setAddressType,
  cityError,
  addressTypeError,
  countyError,
  addressError,
}) => {
  const [addressVisible, setAddressVisible] = useState(true);
  const [errors, setErrors] = useState({});
  const [isDefault, setIsDefault] = useState(false);

  const validateInputs = () => {
    const newErrors = {};
    if (!user.name) newErrors.name = "Full Name is required";
    if (!user.email) newErrors.email = "Email is required";
    if (!user.phoneNumber) newErrors.phoneNumber = "Primary Phone Number is required";
    if (!county) newErrors.county = "County is required";
    if (!city) newErrors.city = "City is required";
    if (!address) newErrors.address = "Address is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateInputs()) {
      // Handle form submission
    }
  };

  const toggleAddressVisibility = () => {
    setAddressVisible(!addressVisible);
  };

  return (
    <div className="w-full 800px:w-[95%] bg-white rounded-md p-5 pb-8 shadow-lg">
      <div className="flex items-center justify-between mb-4">
        <h5 className="text-lg font-semibold">Customer Address</h5>
        <button
          className="text-gray-500 hover:text-gray-700 focus:outline-none"
          onClick={toggleAddressVisibility}
        >
          {addressVisible ? (
            <>
              <span className="mr-1">Close</span> <FiMinimize className="inline" />
            </>
          ) : (
            <>
              <span className="mr-1">Open</span> <FiMaximize className="inline" />
            </>
          )}
        </button>
      </div>

      {addressVisible && (
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-4 pb-3">
            <div>
              <label htmlFor="fullName" className="block text-sm font-medium text-gray-700">Full Name </label>
              <input
                id="fullName"
                type="text"
                value={user && user.name}
                required
                className="input"
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email Address</label>
              <input
                id="email"
                type="email"
                value={user && user.email}
                required
                className="input"
              />
            </div>

          </div>
          {countyError}
          <AddressForm
            county={county}
            setCounty={setCounty}
            city={city}
            setCity={setCity}
            primaryPhoneNumber={primaryPhoneNumber}
            setPrimaryPhoneNumber={setPrimaryPhoneNumber}
            secondaryPhoneNumber={secondaryPhoneNumber}
            setSecondaryPhoneNumber={setSecondaryPhoneNumber}
            address={address}
            setAddress={setAddress}
            additionalInformation={additionalInformation}
            setAdditionalInformation={setAdditionalInformation}
            addressType={addressType}
            setAddressType={setAddressType}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            user={user}
            isDefault={isDefault}
            setIsDefault={setIsDefault}
            cityError={cityError}
            countyError={countyError}
            addressError={addressError}
            addressTypeError={addressTypeError}
          />


          <div className="mt-4">

          </div>
          {/* <button
            type="submit"
            className="mt-4 w-full py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300"
          >
            Submit
          </button> */}
        </form>
      )}
    </div>
  );
};



export default ShippingInfo;
