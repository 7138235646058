import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Login from "../components/Login/Login.jsx";
import { Helmet } from'react-helmet';

const LoginPage = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.user);

  useEffect(() => {
    if(isAuthenticated === true){
      navigate("/");
    }
  }, [])
  
  return (
    <div>
        <Helmet>
        <title>Login - Indazetu</title>
        <meta name="description" content="Login to your Indazetu account to access your profile, view orders, and explore our marketplace. Don't have an account? Sign up now!" />
        <meta name="keywords" content="login, user login, Indazetu account, login to Indazetu, access profile, view orders, online marketplace login" />
        <meta property="og:title" content="Login - Indazetu" />
        <meta property="og:description" content="Login to your Indazetu account to access your profile, view orders, and explore our marketplace. Don't have an account? Sign up now!" />
        <meta property="og:image" content="https://indazetu.com/static/media/logoo-removebg-preview.041ee5fc1e771ab093a6.png" />
        <meta property="og:url" content="https://indazetu.com/login" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://indazetu.com/login" />
      </Helmet>
        <Login />
    </div>
  )
}

export default LoginPage;