import React from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";

const Hero = () => {
  return (
    <div
    className={`relative min-h-[40vh] 400px:min-h-[40vh] w-full bg-no-repeat ${styles.noramlFlex}`}
    style={{
      backgroundImage: "url('/assets/images/vet3.gif')",
      backgroundSize: "cover",
    // backgroundSize: "cover" /* Or contain, initial */

    }}
  >
      <div className={`${styles.section} w-[90%] 800px:w-[60%]`}>
        <h1
          className={`text-[35px] leading-[1.2] 800px:text-[60px] text-[#0080FF] font-[600] capitalize`}
        >
          Your trusted partner in <br /> business transformation.
        </h1>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#0080FF]">
        Boost your business: Connect with top suppliers and find the perfect products.{" "}
          <br /> From raw materials to finished goods, find everything you need in one place. Join now!
        </p>
        <Link to="/products" className="inline-block">
            <div className={`${styles.button} mt-5`}>
                 <span className="text-[#ffa500] font-[Poppins] text-[18px]">
                    Shop Now
                 </span>
            </div>
        </Link>
      </div>
    </div>
  );
};

export default Hero;
