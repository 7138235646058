import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import { backend_url, server } from "../../server";
import { AiOutlineCamera } from "react-icons/ai";
import styles from "../../styles/styles";
import axios from "axios";
import { loadSeller } from "../../redux/actions/user";
import { toast } from "react-toastify";
import { useFormik } from 'formik';
import * as yup from 'yup';
import Loader from "../Layout/Loader";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';


const currencyOptions = [
  { code: "USD", name: "United States Dollar" },
  { code: "EUR", name: "Euro" },
  { code: "GBP", name: "British Pound" },
  { code: "KES", name: "Kenya Shilling" },
  // Add more currencies as needed
];

const validationSchema = yup.object({
  name: yup.string().required('Shop name is required'),
  description: yup.string().required('Shop description is required'),
  address: yup.string().required('Shop address is required'),
  // phoneNumber: yup
  //   .string()
  //   .required('Phone number is required'),
  zipCode: yup
    .string()
    .matches(/^[0-9]+$/, 'Zip code must be only digits')
    .required('Zip code is required'),
  openingTime: yup.string().required('Opening time is required'),
  closingTime: yup.string().required('Closing time is required'),
  days: yup.string().required('Working days are required'),
  currency: yup.string().required('Currency is required'),
});

const ShopSettings = () => {
  const { seller } = useSelector((state) => state.seller);
  const [avatar, setAvatar] = useState(seller?.avatar?.url);
  const [banner, setBanner] = useState(seller?.banner?.url);
  const [location, setLocation] = useState(seller?.location);
  const [phoneNumber, setPhoneNumber] = useState(seller?.phoneNumber);
  const [whatsAppNumber, setWhatsAppNumber] = useState(seller?.whatsAppNumber);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      name: seller?.name || '',
      description: seller?.description || '',
      address: seller?.address || '',
      // phoneNumber: seller?.phoneNumber || '',
      zipCode: seller?.zipCode || '',
      openingTime: seller?.workingHours?.openingTime || '',
      closingTime: seller?.workingHours?.closingTime || '',
      days: seller?.workingHours?.days || '',
      currency: seller?.currency || 'KES',
      supportEmail:seller?.supportEmail ||'',
      website:seller?.website ||'',
      //   accountType:seller?.accountType || 'individual',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        console.log('hellooo');
        const { openingTime, closingTime, days, ...otherValues } = values;
        const workingHours = { openingTime, closingTime, days };
        console.log('hellooo', phoneNumber);
        await axios.put(
          `${server}/shop/update-seller-info`,
          {
            ...otherValues,
            location,
            phoneNumber,
            whatsAppNumber,
            // supportEmail: otherValues.supportEmail || '',
            // website: otherValues.website || '',
            // accountType: otherValues.accountType || 'individual',
            workingHours,
          },
          { withCredentials: true }
        );
        toast.success('Shop info updated successfully!');
        dispatch(loadSeller());
      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
  });


  const handleSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      setLocation(latLng);
      formik.setFieldValue('address', address);
    } catch (error) {
      console.error('Error selecting address:', error);
    }
  };

  const handleImage = async (e, type) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        if (type === 'avatar') {
          setAvatar(reader.result);
          axios
            .put(
              `${server}/shop/update-shop-avatar`,
              { avatar: reader.result },
              { withCredentials: true }
            )
            .then(() => {
              dispatch(loadSeller());
              toast.success('Avatar updated successfully!');
            })
            .catch((error) => {
              toast.error(error.response.data.message);
            });
        } else if (type === 'banner') {
          setBanner(reader.result);
          axios
            .put(
              `${server}/shop/update-shop-banner`,
              { banner: reader.result },
              { withCredentials: true }
            )
            .then(() => {
              dispatch(loadSeller());
              toast.success('Banner updated successfully!');
            })
            .catch((error) => {
              toast.error(error.response.data.message);
            });
        }
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  if (!seller) {
    return <Loader />;
  }

  return (
    <div className="w-full min-h-screen flex flex-col items-center p-5 bg-gray-50">
    <div className="w-full lg:w-4/5 flex flex-col justify-center my-5 bg-white shadow-lg rounded-lg p-5">
      {/* Profile Image */}
      <div className="flex justify-center mb-5">
        <div className="relative">
          <label className="block pb-2 text-gray-600">Profile Image</label>
          <img
            src={avatar}
            alt="Profile"
            className="w-[100px] h-[100px] rounded-full cursor-pointer object-cover"
          />
          <div className="w-[30px] h-[30px] bg-gray-200 rounded-full flex items-center justify-center cursor-pointer absolute bottom-[10px] right-[15px]">
            <input
              type="file"
              id="avatarImage"
              className="hidden"
              onChange={(e) => handleImage(e, 'avatar')}
            />
            <label htmlFor="avatarImage" className="cursor-pointer">
              <AiOutlineCamera />
            </label>
          </div>
        </div>
      </div>

      {/* Banner Image */}
      <div className="flex justify-center mb-5">
        <div className="relative">
          <label className="block pb-2 text-gray-600">Banner Image</label>
          <img
            src={banner}
            alt="Banner"
            className="w-[300px] h-[100px] rounded-lg cursor-pointer object-cover"
          />
          <div className="w-[30px] h-[30px] bg-gray-200 rounded-full flex items-center justify-center cursor-pointer absolute bottom-[10px] right-[15px]">
            <input
              type="file"
              id="bannerImage"
              className="hidden"
              onChange={(e) => handleImage(e, 'banner')}
            />
            <label htmlFor="bannerImage" className="cursor-pointer">
              <AiOutlineCamera />
            </label>
          </div>
        </div>
      </div>

      {/* Shop Info Form */}
      <form className="w-full" onSubmit={formik.handleSubmit}>
        {/* Row 1: Shop Name and Currency */}
        <div className="flex flex-wrap justify-between mb-5">
          <div className="flex flex-col w-full md:w-[48%]">
            <label className="block pb-2 text-gray-600">Shop Name <span className="text-red-500">*</span></label>
            <input
              type="text"
              name="name"
              placeholder="Enter shop name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="p-2 border rounded-md mb-4"
              required
            />
            {formik.touched.name && formik.errors.name && (
              <div className="text-red-500 text-sm">{formik.errors.name}</div>
            )}
          </div>
          <div className="flex flex-col w-full md:w-[48%]">
            <label className="block pb-2 text-gray-600">Currency <span className="text-red-500">*</span></label>
            <select
              name="currency"
              value={formik.values.currency}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="p-2 border rounded-md mb-4"
            >
              {currencyOptions.map((option) => (
                <option key={option.code} value={option.code}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Row 2: Description */}
        <div className="flex flex-wrap mb-5">
          <div className="flex flex-col w-full">
            <label className="block pb-2 text-gray-600">Shop Description</label>
            <textarea
              name="description"
              placeholder="Enter shop description"
              value={formik.values.description}
              onChange={(e) => {
                const wordCount = e.target.value.split(/\s+/).filter(word => word.length > 0).length;
                if (wordCount <= 250) {
                  formik.handleChange(e);
                }
              }}
              onBlur={formik.handleBlur}
              className="p-2 border rounded-md mb-4"
              rows="4"
              required
            />
            {formik.touched.description && formik.errors.description && (
              <div className="text-red-500 text-sm">{formik.errors.description}</div>
            )}
            <div className="text-right text-gray-400">
              {formik.values.description.split(/\s+/).filter(word => word.length > 0).length} / 250 words
            </div>
          </div>
        </div>

        {/* Row 3: Shop Address and Shop Phone Number */}
        <div className="flex flex-wrap justify-between mb-5">
          <div className="flex flex-col w-full md:w-[48%]">
            <label className="block pb-2 text-gray-600">Shop Address</label>
            <PlacesAutocomplete
              value={formik.values.address}
              onChange={(value) => formik.setFieldValue('address', value)}
              onSelect={handleSelect}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <>
                  <input
                    {...getInputProps({
                      placeholder: "Enter your location",
                      className: "p-2 border rounded-md mb-4",
                    })}
                    required
                  />
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const style = {
                      backgroundColor: suggestion.active ? "#41b6e6" : "#fff",
                    };
                    return (
                      <div {...getSuggestionItemProps(suggestion, { style })}>
                        {suggestion.description}
                      </div>
                    );
                  })}
                </>
              )}
            </PlacesAutocomplete>
            {formik.touched.address && formik.errors.address && (
              <div className="text-red-500 text-sm">{formik.errors.address}</div>
            )}
          </div>
          <div className="flex flex-col w-full md:w-[48%]">
            <label className="block pb-2 text-gray-600">Shop Phone Number <span className="text-red-500">*</span></label>
            <PhoneInput
              country={'ke'}
              value={phoneNumber.toString()}
              onChange={phone => setPhoneNumber(phone)}
              inputClass="appearance-none mb-4 p-2 border rounded-md block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              required
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber && (
              <div className="text-red-500 text-sm">{formik.errors.phoneNumber}</div>
            )}
          </div>
        </div>

        {/* Row 4: Zip Code and Website */}
        <div className="flex flex-wrap justify-between mb-5">
          <div className="flex flex-col w-full md:w-[48%]">
            <label className="block pb-2 text-gray-600">Shop Zip Code</label>
            <input
              type="text"
              name="zipCode"
              placeholder="Enter zip code"
              value={formik.values.zipCode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="p-2 border rounded-md mb-4"
              required
            />
            {formik.touched.zipCode && formik.errors.zipCode && (
              <div className="text-red-500 text-sm">{formik.errors.zipCode}</div>
            )}
          </div>
          <div className="flex flex-col w-full md:w-[48%]">
            <label className="block pb-2 text-gray-600">Shop Website</label>
            <input
              type="text"
              name="website"
              placeholder="Enter Website"
              value={formik.values.website}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="p-2 border rounded-md mb-4"
              required
            />
            {formik.touched.website && formik.errors.website && (
              <div className="text-red-500 text-sm">{formik.errors.website}</div>
            )}
          </div>
        </div>

        {/* Row 5: Contact Email and Working Hours */}
        <div className="flex flex-wrap justify-between mb-5">
          <div className="flex flex-col w-full md:w-[48%]">
            <label className="block pb-2 text-gray-600">Shop Contact Email</label>
            <input
              type="email"
              name="supportEmail"
              placeholder="Enter Email Address"
              value={formik.values.supportEmail}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="p-2 border rounded-md mb-4"
              required
            />
            {formik.touched.supportEmail && formik.errors.supportEmail && (
              <div className="text-red-500 text-sm">{formik.errors.supportEmail}</div>
            )}
          </div>
          <div className="flex flex-col w-full md:w-[48%]">
            <label className="block pb-2 text-gray-600">Working Hours</label>
            <div className="flex items-center mb-4">
              <label className="block w-[25%] text-gray-400">Opening Time <span className="text-red-500">*</span></label>
              <input
                type="time"
                name="openingTime"
                value={formik.values.openingTime}
                onChange={formik.handleChange}
                className="w-[70%] p-2 border rounded-md"
                required
              />
            </div>
            <div className="flex items-center">
              <label className="block w-[25%] text-gray-400">Closing Time</label>
              <input
                type="time"
                name="closingTime"
                value={formik.values.closingTime}
                onChange={formik.handleChange}
                className="w-[70%] p-2 border rounded-md"
                required
              />
            </div>
          </div>
        </div>

        {/* Row 6: Working Days */}
        <div className="flex flex-wrap justify-between mb-5">
          <div className="flex flex-col w-full md:w-[48%]">
            <label className="block pb-2 text-gray-600">Working Days <span className="text-red-500">*</span></label>
            <input
              type="text"
              name="days"
              value={formik.values.days}
              onChange={formik.handleChange}
              placeholder="Days (e.g., Monday - Sunday)"
              className="p-2 border rounded-md mb-4"
              required
            />
            {formik.touched.days && formik.errors.days && (
              <div className="text-red-500 text-sm">{formik.errors.days}</div>
            )}
          </div>
          <div className="flex flex-col w-full md:w-[48%]">
            <label className="block pb-2 text-gray-600">Shop WhatsApp Number <span className="text-red-500">*</span></label>
            <PhoneInput
              country={'ke'}
              value={whatsAppNumber?.toString()}
              onChange={phone => setWhatsAppNumber(phone)}
              inputClass="appearance-none mb-4 p-2 border rounded-md block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              required
            />
            {formik.touched.whatsAppNumber && formik.errors.whatsAppNumber && (
              <div className="text-red-500 text-sm">{formik.errors.whatsAppNumber}</div>
            )}
          </div>
        </div>

      
        {/* Submit Button */}
        <div className="flex justify-center">
          <input
            type="submit"
            value="Update Shop"
            className="p-2 border rounded-md cursor-pointer bg-blue-500 text-white hover:bg-blue-600"
          />
        </div>
      </form>
    </div>
  </div>
  );
};

export default ShopSettings;