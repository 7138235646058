import React,{useState} from "react";
import styles from "../../styles/styles";
import CountDown from "./CountDown";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addTocart } from "../../redux/actions/cart";
import { toast } from "react-toastify";
import useShop from "../../hooks/useShop";

const EventCard = ({ active, data }) => {
  const { cart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const [cartType, setCartType] = useState("product");
  const [selectedRangeIndex, setSelectedRangeIndex] = useState(data.selectedRangeIndex || 0);
  const [selectedRange, setSelectedRange] = useState(data.selectedRange || '');
  
  const addToCartHandler = (data) => {
    let newData = { ...data };
    const isItemExists = cart && cart.find((i) => i._id === data._id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      newData.type = cartType;
      newData.selectedRange = selectedRange;
      newData.selectedRangeIndex = selectedRangeIndex;
      console.log("already", newData);
      if (newData.stock < 1) {
        toast.error("Product stock limited!");
      } else if (cart.length > 0 && shop?.shop?.currency !== cart[0]?.currency) {
        toast.error("Cant Add Different Currencies to Cart");
      } else {
        const cartData = { ...newData, qty: 1, currency: shop?.shop?.currency };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };
  const { shop, loading, error } = useShop(data.shopId);
  const sortedPrices = data?.priceInput.map(item => Number(item.price || item.discountPrice)).sort((a, b) => a - b);

  // Get the smallest and largest values from the sorted array
  const smallestPrice = sortedPrices[0].toFixed(2);
  const largestPrice = sortedPrices[sortedPrices.length - 1].toFixed(2);

  //min order
  const sortedOrders = data?.priceInput.map(item => Number(item.min)).sort((a, b) => a - b);
  const minOrder = sortedOrders[0].toFixed(2);

  return (
    <div className={`w-full block bg-white rounded-lg shadow-md ${active ? "unset" : "mb-12"} lg:flex p-4`}>
      <div className="w-full lg:w-1/4 flex justify-center items-center mb-4 lg:mb-0">
        <img src={`${data?.images[0]?.url}`} alt="" className="w-full h-auto object-cover rounded-lg" />
      </div>
      <div className="w-full lg:w-3/4 flex flex-col justify-center px-4">
        <h2 className={`${styles.productTitle} text-xl font-semibold mb-2`}>{data?.name}</h2>
        <p className="text-gray-700 mb-4">{data?.description}</p>
        <div className="flex flex-col lg:flex-row lg:justify-between items-start lg:items-center py-2">
          <div className="flex flex-col lg:flex-row items-start lg:items-center mb-4 lg:mb-0">
            <h5 className={`${styles.productDiscountPrice} text-lg font-bold `}>
              {shop?.shop?.currency}. (<small>from</small> {smallestPrice} - <small>to</small> {largestPrice})
            </h5>
            <span className="pr-4 font-medium text-lg text-green-500 ml-6">
              {data?.sold_out} sold
            </span>
            <div className="flex items-center ml-8">
              <label className=" text-gray-600">Min Order:</label>
              <span className="font-medium text-lg text-green-500 pl-1">
                {minOrder}
              </span>
            </div>
          </div>
          <CountDown data={data} />
        </div>
        <div className="flex items-center mt-4">
          <Link to={`/product/${data?._id}?isEvent=true`}>
            <div className={`${styles.button} text-white bg-black-500 hover:bg-blue-600 transition-colors duration-300 px-4 py-2 rounded-lg`}>
              See Details
            </div>
          </Link>
          <div className={`${styles.button} text-white bg-black-500 hover:bg-green-600 transition-colors duration-300 px-4 py-2 rounded-lg ml-4 cursor-pointer`} onClick={() => addToCartHandler(data)}>
            Add to cart
          </div>
        </div>
      </div>
    </div>
  )
};

export default EventCard;
