  import DashboardMessages from "./UserInbox/DashboardMessages";



import React from 'react'
import Header from './../components/Layout/Header'
// import DashboardSideBar from './../components/Shop/Layout/DashboardSideBar'

const DashboardMessage = () => {
  return (
    <div>
    <Header />
    <div className="flex items-start justify-between w-full">
      <div className="w-[80px] 800px:w-[330px]">
        {/* <DashboardSideBar active={8} /> */}
      </div>
      <DashboardMessages  />
    </div>
  </div>
  )
}

export default DashboardMessage