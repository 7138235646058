import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useParams } from "react-router-dom";
import { getAllProductsShop } from "../../redux/actions/product";
import styles from "../../styles/styles";
import ProductCard from "../Route/ProductCard/ProductCard";
import Ratings from "../Products/Ratings";
import { getAllEventsShop } from "../../redux/actions/event";
import { MdVerified, MdMessage } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import GalleryTabs from "./GalleryTabs";
import axios from "axios";
import { server } from "../../server";
import ShopAdminPostList from "./ShopAdminPostList";
import ShopAdminHomePost from "./ShopAdminHomePost";
import MessageModal from "./MessageModal";
import { Helmet } from "react-helmet";
import { loadSeller, loadUser } from "../../redux/actions/user";
import { setUserId, setUserProperties } from "firebase/analytics";
import { analytics } from '../../firebase';
import { postAnalyticsData } from "../../utils/trackPageEvent";

const ShopProfileData = ({ isOwner }) => {

  const [active, setActive] = useState(1);
  const { products } = useSelector((state) => state.products);
  const { events } = useSelector((state) => state.events);
  const { user } = useSelector((state) => state.user);
  const { seller } = useSelector((state) => state.seller);
  const userId = user ? user._id : null;
  const sellerId =seller? seller?._id : null;
  const [data, setData] = useState({});
  const { id } = useParams();
  
  const [isLoading, setIsLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);


  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  

  const dispatch = useDispatch();
  const allReviews =
    products && products.map((product) => product.reviews).flat();

  useEffect(() => {
    dispatch(getAllProductsShop(id));
    setIsLoading(true);
    axios
      .get(`${server}/shop/get-shop-info/${id}`)
      .then((res) => {
        setData(res.data.shop);
        localStorage.setItem('shop', JSON.stringify(res.data.shop));
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);


  useEffect(() => {
    if (id) {
      setUserId(analytics, userId);
      setUserProperties(analytics, { id });
      postAnalyticsData(userId, id, 'page_view', window.location.pathname);
    }

  }, [userId, id]);


  return (
    <div className="w-full">
      <Helmet>
        <title>{`${data?.name} - Shop on Indazetu`}</title>
        <meta name="description" content={`Explore ${data?.name} on Indazetu. Discover a wide range of products, read reviews, and view shop media. Join us and connect with this seller today!`} />
        <meta name="keywords" content={`${data?.name}, shop on Indazetu, ${data?.name} products, ${data?.name} reviews, ${data?.name} media, buy from ${data?.name}`} />
        <meta property="og:title" content={`${data?.name} - Shop on Indazetu`} />
        <meta property="og:description" content={`Explore ${data?.name} on Indazetu. Discover a wide range of products, read reviews, and view shop media. Join us and connect with this seller today!`} />
        <meta property="og:image" content={data?.avatar?.url} />
        <meta property="og:url" content={`https://indazetu.com/shop/${data?._id}`} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={`https://indazetu.com/shop/${data?._id}`} />
      </Helmet>
      {/* Header with Banner and Logo */}
      <div className="relative">
        {/* Banner with profile background image */}
        <div
          className="w-full h-[300px] mb-10 rounded-md overflow-hidden"
          style={{
            backgroundImage: `url(${data.banner?.url})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          {/* Avatar */}
          <img
            src={`${data.avatar?.url}`}
            alt="Logo"
            className="rounded-full w-24 h-24 absolute bottom-0 left-0 ml-5 mb-5 border-4 border-white"
          />
        </div>


      </div>


      <div className="flex w-full items-center justify-between mb-10" >

        <div className="w-full flex flex-wrap justify-center">
{user &&
  <>
          <button
            onClick={openModal}
            className="flex items-center bg-transparent border-blue-500 border-solid border rounded-md py-1 px-3 mr-5 mb-5 hover:bg-green-500 hover:text-white transition-colors duration-300"
            style={{ borderColor: '#0080FF' }}
          >
            <MdMessage className="text-orange-500 mr-1" style={{ fontSize: '1.5rem' }} />
            <span className="text-orange-600 text-sm font-semibold">Message Seller</span>
          </button>

          <MessageModal seller={data} type='shopDetail' isOpen={isModalOpen} onClose={closeModal} />
          </>
}

          <div
            className={`flex items-center ml-5 mb-5 ${active === 1 ? "border-b-2 border-red-500" : ""
              }`}
            onClick={() => setActive(1)}
          >
            <h5 className="font-[600] text-[20px] cursor-pointer pr-[20px] md:text-[20px] sm:text-[10px] font-[300]">
              Shop Home
            </h5>
          </div>

          <div
            className={`flex items-center ml-5 mb-5 ${active === 2 ? "border-b-2 border-red-500" : ""
              }`}
            onClick={() => setActive(2)}
          >
            <h5 className="font-[600] text-[20px] cursor-pointer pr-[20px] md:text-[20px] sm:text-[10px] font-[300]">
              Shop Products
            </h5>
          </div>
          <div
            className={`flex items-center ml-5 mb-5 ${active === 3 ? "border-b-2 border-red-500" : ""
              }`}
            onClick={() => setActive(3)}
          >
            <h5 className="font-[600] text-[20px] cursor-pointer pr-[20px] md:text-[20px] sm:text-[10px] font-[300]">
              Shop Reviews
            </h5>
          </div>

          <div
            className={`flex items-center ml-5 mb-5 ${active === 4 ? "border-b-2 border-red-500" : ""
              }`}
            onClick={() => setActive(4)}
          >
            <h5 className="font-[600] text-[20px] cursor-pointer pr-[20px] md:text-[20px] sm:text-[10px] font-[300]">
              Shop Media
            </h5>

          </div>


          <div
            className={`flex items-center ml-5 mb-5 ${active === 5 ? "border-b-2 border-red-500" : ""
              }`}
            onClick={() => setActive(5)}
          >
            <h5 className="font-[600] text-[20px] cursor-pointer pr-[20px] md:text-[20px] sm:text-[10px] font-[300]">
              Shop Posts
            </h5>
          </div>

        </div>
        <div>
          {isOwner && (
            <div>
              <Link to="/dashboard">
                <div className={`${styles.button} !rounded-[4px] h-[42px]`}>
                  <span className="text-[#fff]">Go Dashboard</span>
                </div>
              </Link>
            </div>
          )}
        </div>
      </div>



      {active === 1 && (
        <div className="grid grid-cols-1">
          <ShopAdminHomePost shop={id} />
        </div>
      )}
      {active === 2 && (
        <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-3 lg:gap-[25px] xl:grid-cols-4 xl:gap-[20px] mb-12 border-0">
          {products &&
            products.map((i, index) => (
              <ProductCard data={i} key={index} isShop={true} />
            ))}
        </div>
      )}



      {active === 3 && (
        <div className="w-full">
          {allReviews &&
            allReviews.map((item, index) => (
              <div className="w-full flex my-4">
                <img
                  src={`${item.user.avatar?.url}`}
                  className="w-[50px] h-[50px] rounded-full"
                  alt=""
                />
                <div className="pl-2">
                  <div className="flex w-full items-center">
                    <h1 className="font-[600] pr-2">{item.user.name}</h1>
                    <Ratings rating={item.rating} />
                  </div>
                  <p className="font-[400] text-[#000000a7]">{item?.comment}</p>
                  <p className="text-[#000000a7] text-[14px]">{"2days ago"}</p>
                </div>
              </div>
            ))}
          {allReviews && allReviews.length === 0 && (
            <h5 className="w-full text-center py-5 text-[18px]">
              No Reviews have for this shop!
            </h5>
          )}
        </div>
      )}


      {active === 4 && (
        <div className="w-full">
          <div className="grid grid-cols-1 gap-[20px]  border-0">
            <GalleryTabs shop={id} />
          </div>
        </div>
      )}
      {active === 5 && (
        <div className="w-full">
          <div className="grid grid-cols-1 gap-[20px]  border-0">
            <ShopAdminPostList shop={id} />
          </div>
        </div>
      )}

    </div>
  );
};

export default ShopProfileData;
