import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";
import ProductCard from "../components/Route/ProductCard/ProductCard";
import styles from "../styles/styles";
import { Helmet } from "react-helmet";

const ProductsPage = () => {
  const [searchParams] = useSearchParams();
  const categoryData = searchParams.get("category");
  const { allProducts, isLoading } = useSelector((state) => state.products);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (categoryData === null) {
      const d = allProducts;
      setData(d);
    } else {
      const d = allProducts && allProducts.filter((i) => i.category === categoryData);
      setData(d);
    }
    //    window.scrollTo(0,0);
  }, [allProducts, categoryData]);

  return (
    <>
      <Helmet>
        <title>Products - Indazetu</title>
        <meta name="description" content="Explore a wide range of products on Indazetu, Kenya's leading online marketplace. Shop by category and find quality products at great prices. Discover the best deals and discounts on electronics, fashion, home goods, and more." />
        <meta name="keywords" content="products, online shopping Kenya, shop by category, quality products, electronics, fashion, home goods, best deals, discounts, Indazetu marketplace, buy online Kenya, affordable products, Kenyan marketplace" />
        <meta property="og:title" content="Products - Indazetu" />
        <meta property="og:description" content="Explore a wide range of products on Indazetu, Kenya's leading online marketplace. Shop by category and find quality products at great prices. Discover the best deals and discounts on electronics, fashion, home goods, and more." />
        <meta property="og:image" content="https://indazetu.com/static/media/logoo-removebg-preview.041ee5fc1e771ab093a6.png" />
        <meta property="og:url" content="https://indazetu.com/products" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://indazetu.com/products" />
      </Helmet>
      {
        isLoading ? (
          <Loader />
        ) : (
          <div>
            <Header activeHeading={3} />
            <br />
            <br />
            <div className={`${styles.section}`}>
              <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
                {data && data.map((i, index) => <ProductCard data={i} key={index} />)}
              </div>
              {data && data.length === 0 ? (
                <h1 className="text-center w-full pb-[100px] text-[20px]">
                  No products Found!
                </h1>
              ) : null}
            </div>
            <Footer />
          </div>
        )
      }
    </>
  );
};

export default ProductsPage;
