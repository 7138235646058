import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";
import ProductCard from "../components/Route/ProductCard/ProductCard";
import styles from "../styles/styles";
import Footer from "../components/Layout/Footer";
import { Helmet } from "react-helmet";


const BestSellingPage = () => {
  const [data, setData] = useState([]);
  const { allProducts, isLoading } = useSelector((state) => state.products);

  useEffect(() => {
    const allProductsData = allProducts ? [...allProducts] : [];
    const sortedData = allProductsData?.sort((a, b) => b.sold_out - a.sold_out);
    setData(sortedData);
  }, [allProducts]);

  return (
    <>
      <Helmet>
        <title>Best Selling Products - Indazetu</title>
        <meta name="description" content="Discover the best selling products on Indazetu. Shop top-rated items with high customer satisfaction. Get amazing deals on bulk purchases and wholesale prices. Your one-stop marketplace for quality products in Kenya." />
        <meta name="keywords" content="best selling products, top rated products, high customer satisfaction, bulk purchase discounts, wholesale prices, buy in bulk, wholesale marketplace, bulk buying Kenya, top sellers, popular products, best deals, high demand products, Indazetu marketplace, quality products Kenya" />
        <meta property="og:title" content="Best Selling Products - Indazetu" />
        <meta property="og:description" content="Shop the best selling products on Indazetu. Get top-rated items with high customer satisfaction and enjoy great deals on bulk purchases. Explore our marketplace for quality products in Kenya." />
        <meta property="og:image" content="https://indazetu.com/static/media/logoo-removebg-preview.041ee5fc1e771ab093a6.png" />
        <meta property="og:url" content="https://indazetu.com/best-selling" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://indazetu.com/best-selling" />
      </Helmet>
      {
        isLoading ? (
          <Loader />
        ) : (
          <div>
            <Header activeHeading={2} />
            <br />
            <br />
            <div className={`${styles.section}`}>
              <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
                {data && data.map((i, index) => <ProductCard data={i} key={index} />)}
              </div>
            </div>
            <Footer />
          </div>
        )
      }
    </>
  );
};


export default BestSellingPage;
