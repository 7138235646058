import React, { useEffect, useMemo } from 'react';
import Select from 'react-select';
import countryList from 'react-select-country-list';

const CountrySelect = ({ defaultCountry, setCountry, country }) => {
  const options = useMemo(() => countryList().getData(), []);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      padding: '5px',
      borderRadius: '8px',
      border: '1px solid #ccc',
    }),
    option: (provided, state) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
    }),
    singleValue: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
    }),
  };

  const handleChange = (selectedOption) => {
    setCountry(selectedOption);
  };

  const formatOptionLabel = ({ value, label }) => (
    <div className="country-option">
      <img
        src={`https://flagcdn.com/w20/${value.toLowerCase()}.png`}
        alt={`Flag of ${label}`}
        style={{ marginRight: '10px' }}
      />
      {label}
    </div>
  );

  useEffect(() => {
    if (defaultCountry) {
      const defaultOption = options.find(
        (option) => option.label === defaultCountry
      );
      setCountry(defaultOption);
    }
  }, [defaultCountry, options, setCountry]);

  return (
    <Select
      options={options}
      value={country}
      onChange={handleChange}
      styles={customStyles}
      formatOptionLabel={formatOptionLabel}
      placeholder="Select a country"
    />
  );
};

export default CountrySelect;
