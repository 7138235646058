import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Loader from "../components/Layout/Loader";

const SellerProtectedRoute = ({ children }) => {
  const { isLoading, isSeller ,seller,sellers} = useSelector((state) => state.seller);
  
  console.log('Seller Protected Route - isLoading:', isLoading, 'isSeller:', isSeller,'seller',seller,'sellers',sellers);
  
  if (isLoading) {
    return <Loader />;
  } else {
    if (!isSeller) {
      return <Navigate to={`/shop-login`} replace />;
    }
    return children;
  }
};


export default SellerProtectedRoute;
