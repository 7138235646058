import { server } from "../server";

import { setUserId, setUserProperties, getAnalytics, logEvent } from 'firebase/analytics';
import { getFirestore, collection, addDoc } from "firebase/firestore";
// import { analytics,logEvent } from '../firebase';

const analytics = getAnalytics();

// const db = getFirestore();

// export const postAnalyticsData = async (userId, sellerId, eventType, pagePath) => {
//   try {
//     const docRef = await addDoc(collection(db, "indazetuAnalyticsCollection"), {
//       userId,
//       sellerId,
//       eventType,
//       pagePath,
//       timestamp: new Date().toISOString(),
//     });
//     console.log("Document written with ID: ", docRef.id);
//   } catch (e) {
//     console.error("Error adding document: ", e);
//   }
// };
export const postAnalyticsData = (userId, sellerId, eventType, pagePath) => {
  if (userId && sellerId) {
    // Set user ID
    setUserId(analytics, userId);

    // Set user properties, including sellerId
    setUserProperties(analytics, { sellerId });

    // Log event with sellerId as a parameter
    logEvent(analytics, eventType, {
      userId, getAnalytics, logEvent, sellerId,
      pagePath,
      timestamp: new Date().toISOString(),
    });
  }
};