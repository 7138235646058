import { useState,useEffect } from 'react';
import PickupStation from './PickupStation';
import ShippingInputs from "../../components/Shop/ShippingInputs";
import ShippingSettingList from "../../components/Shop/ShippingSettingList";
import { useSelector } from 'react-redux';
import { server } from '../../server';
import { toast } from 'react-toastify';
import axios from 'axios';



const Tab1 = () => (
  <div className="p-4">
    <h2 className="text-2xl font-bold mb-4">PickUp Stations</h2>
    <PickupStation />
  </div>
);

const Tab2 = () => {
  const [shippingInputs, setShippingInputs] = useState([]);
  const [settings, setSettings] = useState([]);
  const { seller } = useSelector((state) => state.seller);

  const handleSubmit = async () => {
    try {
      const postData = {
        shop: seller._id,
        shippingInputs,
        postType: 'shipping-inputs',
      };

      const response = await axios.post(
        `${server}/shippingCost/create-shipping-cost`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "image/*",
          },
        }
      );
      console.log('response',response);

      toast.success("Post Added Successfully");
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  const handleShippingInputsChange = (inputs) => {
    setShippingInputs(inputs);
  };

  useEffect(() => {
    const fetchSettings = async () => {
      if (!seller._id) return; // Ensure seller ID is available

      try {
        const response = await axios.get(`${server}/shop/shipping-setting/${seller._id}`);
        setSettings(response.data.data);
      } catch (error) {
        console.error('Error fetching settings:', error);
        toast.error("Failed to fetch settings.");
      }
    };

    fetchSettings();
  }, [seller._id]);

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Shipping Settings</h2>
      <ShippingInputs
        shippingInputs={shippingInputs}
        onShippingInputsChange={handleShippingInputsChange}
        onSubmit={handleSubmit}
        settings={settings}
        shop={seller._id}
      />
      <ShippingSettingList shop={seller._id} settings={settings} />
    </div>
  );
};

const ShippingPickupTabs = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="max-w-4xl mx-auto mt-10">
      <div className="flex border-b border-gray-200">
        <button
          className={`flex-1 p-4 text-center ${activeTab === 0 ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'} focus:outline-none`}
          onClick={() => setActiveTab(0)}
        >
         PickUp Stations
        </button>
        <button
          className={`flex-1 p-4 text-center ${activeTab === 1 ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'} focus:outline-none`}
          onClick={() => setActiveTab(1)}
        >
        Shipping Settings
        </button>
      </div>
      <div className="bg-white shadow-md rounded-lg mt-4">
        {activeTab === 0 && <Tab1 />}
        {activeTab === 1 && <Tab2 />}
      </div>
    </div>
  );
};

export default ShippingPickupTabs;
