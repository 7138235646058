import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ShopLogin from "../components/Shop/ShopLogin";
import { Helmet } from'react-helmet';

const ShopLoginPage = () => {
  const navigate = useNavigate();
  const { isSeller,isLoading } = useSelector((state) => state.seller);

  useEffect(() => {
    if(isSeller === true){
      navigate(`/dashboard`);
    }
  }, [isLoading,isSeller])
  return (
    <div>
       <Helmet>
        <title>Seller Login - Indazetu</title>
        <meta name="description" content="Login to your seller account on Indazetu to manage your shop, view orders, and more. Join our marketplace and start selling today!" />
        <meta name="keywords" content="seller login, shop login, Indazetu seller account, manage shop, online marketplace login" />
        <meta property="og:title" content="Seller Login - Indazetu" />
        <meta property="og:description" content="Login to your seller account on Indazetu to manage your shop, view orders, and more. Join our marketplace and start selling today!" />
        <meta property="og:image" content="https://indazetu.com/static/media/logoo-removebg-preview.041ee5fc1e771ab093a6.png" />
        <meta property="og:url" content="https://indazetu.com/shop-login" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://indazetu.com/shop-login" />
      </Helmet>
        <ShopLogin />
    </div>
  )
}

export default ShopLoginPage