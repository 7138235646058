import React, { useState, useEffect } from 'react';
import PlacesPicker from '@tasiodev/react-places-autocomplete'
import QuantityPriceInput from './QuantityPriceInput';
import MultiSelector from './MultiSelect';
import { useSelector } from "react-redux";
import { FaTags, FaTrash, FaPlus } from 'react-icons/fa';
import ShippingSrcDestInputs from './ShippingSrcDestInputs';
import LocationSearchInput from './LocationSearchInput';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { server } from '../../server';
import axios from 'axios';
import { toast } from 'react-toastify';
import Select from 'react-select'
import EstimatedTimeInput from './estimatedTimeInput';


const ShippingInputs = ({ onShippingInputsChange, shippingInputs, onSubmit, settings, shop }) => {
  const { allProducts } = useSelector((state) => state.products);
  const [pickupStations, setPickupStations] = useState([]);
  const [selectedDestination, setSelectedDestination] = useState(null);

  const filteredProducts = allProducts.filter(product => product.shopId === shop);

  useEffect(() => {
    fetchPickupStations();
  }, []);

  const fetchPickupStations = async () => {
    try {
      const response = await axios.get(`${server}/pickupstation/get-all-pickupstations`);
      const options = response.data.pickupStations.map(station => ({
        value: [station._id, station.address],
        label: station.stationName + ': ' + station.address.location,
        ...station
      }));
      setPickupStations(options);
    } catch (error) {
      console.error('Error fetching pickup stations:', error);
    }
  };

  const formik = useFormik({
    initialValues: {
      source: {},
      destination: {}, // Updated to hold the selected pickup station
      estimatedTime: '',
      deliveryType: '',
      description: '',
      selectedProducts: [],
      priceInput: {}
    },
    validationSchema: Yup.object({
      source: Yup.object().required('Source address is required'),
      destination: Yup.object().required('Destination address is required'),
      estimatedTime: Yup.string().required('Estimated time is required'),
      deliveryType: Yup.string().required('Delivery type is required'),
      description: Yup.string().required('Description is required'),
      selectedProducts: Yup.array().min(1, 'At least one product must be selected')
    }),
    onSubmit: values => {
      console.log('values', values)
      const newShippingInput = {
        source: values.source,
        destination: values.destination.value[1], // Use address for the destination
        destinationId: values.destination.value[0], // Include the pickup station ID
        estimatedTime: values.estimatedTime,
        shippingCost: values.priceInput,
        allProducts: values.selectedProducts,
        deliveryType: values.deliveryType,
        description: values.description
      };

      onShippingInputsChange([...shippingInputs, newShippingInput]);
      formik.resetForm();
    }
  });

  // Add this function within the ShippingInputs component
  const handleDeleteShippingInput = (index) => {
    const updatedInputs = [...shippingInputs];
    updatedInputs.splice(index, 1);
    onShippingInputsChange(updatedInputs);
  };

  return (
    <form onSubmit={formik.handleSubmit} className="max-w-4xl mx-auto p-4">
      <div className="mb-4 flex flex-col sm:flex-row">
        <div className="flex-1 sm:mr-2">
          <label className="block text-gray-700">Source</label>
          {/* {JSON.stringify(source)} */}
          <LocationSearchInput
            onSelect={(value) => formik.setFieldValue('source', value)}
          />
          {formik.touched.source && formik.errors.source && (
            <p className="text-red-500">{formik.errors.source}</p>
          )}
        </div>

        <div className="flex-1 sm:mr-2">
          <label className="block text-gray-700">
            Destination <small className="text-orange-500">(PickUp Station)</small>
          </label>
          <Select
            options={pickupStations}
            value={formik.values.destination}
            onChange={(selectedOption) => formik.setFieldValue('destination', selectedOption)}
            placeholder="Select a pickup station"
            className="w-full"
          />
          {formik.touched.destination && formik.errors.destination && (
            <p className="text-red-500">{formik.errors.destination}</p>
          )}
        </div>

      </div>

      <div className="mb-4 flex flex-col sm:flex-row">
        <div className="flex-1 sm:mr-2">
          <EstimatedTimeInput
            value={formik.values.estimatedTime}
            onChange={(newEstimatedTime) => formik.setFieldValue('estimatedTime', newEstimatedTime)}
          />
        </div>


        <div className="flex-1 mt-4 sm:mt-0">
          <label className="block text-gray-700">Delivery Type</label>
          <select
            value={formik.values.deliveryType}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="deliveryType"
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:ring focus:ring-blue-500 focus:outline-none"
          >
            <option value="">Select Delivery Type</option>
            <option value="special">Special</option>
            <option value="express">Express</option>
            <option value="regular">Regular</option>
          </select>
          {formik.touched.deliveryType && formik.errors.deliveryType ? (
            <p className="text-red-500">{formik.errors.deliveryType}</p>
          ) : null}
        </div>
      </div>

      <div className="mb-4 flex flex-col sm:flex-row">
        <div className="flex-1 sm:mr-2">
          <label className="block text-gray-700">Product</label>
          {/* {JSON.stringify(formik.values.destination.value[1])} */}
          {/* {JSON.stringify(formik.values)} */}
          <MultiSelector
            options={filteredProducts}
            settings={settings}
            onSelectionChange={(selected) => formik.setFieldValue('selectedProducts', selected)}
            deliveryType={formik.values.deliveryType}
            source={formik.values.source}
            destination={formik.values.destination?.value ? formik.values.destination.value[1] : null} // Check for both null and undefined
          />

          {formik.touched.selectedProducts && formik.errors.selectedProducts ? (
            <p className="text-red-500">{formik.errors.selectedProducts}</p>
          ) : null}
        </div>

        <div className="flex-1 mt-4 sm:mt-0">
          <label className="block text-gray-700">Description</label>
          <textarea
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="description"
            placeholder="Description"
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:ring focus:ring-blue-500 focus:outline-none"
            rows={4}
            style={{ minHeight: '100px' }}
          />
          {formik.touched.description && formik.errors.description ? (
            <p className="text-red-500">{formik.errors.description}</p>
          ) : null}
        </div>
      </div>

      <QuantityPriceInput onPriceChange={(newValue) => formik.setFieldValue('priceInput', newValue)} />

      <button type="submit" className="bg-green-300 text-black mt-5 px-4 py-2 rounded-md hover:bg-green-400 focus:outline-none focus:ring focus:ring-green-300">
        Preview and Submit
      </button>

      <div className="mt-4 flex justify-center">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-1 gap-2 w-full">
          {shippingInputs?.map((input, index) => (
            <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden">
              <div className="w-full p-6 flex justify-between">
                <div className="w-1/2">
                  <div className="p-6">
                    <h3 className="text-xl text-green-500 font-bold mb-2 border-b">Preview</h3>
                    <p className="text-gray-700 mb-2 mt-5"><span className="font-semibold">Source:</span> {input.source.location}</p>
                    <p className="text-gray-700 mb-2"><span className="font-semibold">Destination:</span> {input.destination.location}</p>
                    <p className="text-gray-700 mb-2"><span className="font-semibold">Estimated Time:</span> {input.estimatedTime}</p>
                    <div className="mt-5">
                      <h4 className="text-lg text-blue-400 font-semibold mb-2">Products:</h4>
                      <ul className="list-disc pl-6">
                        {input.allProducts.map((product, index) => (
                          <li key={index}>{product.label}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="border-b border-gray-200 w-1/2">
                  <div className="p-6">
                    <p className="text-blue-500 mb-2"><span className="font-semibold">Shipping Cost:</span></p>
                    <table className="table-auto">
                      <thead>
                        <tr>
                          <th className="px-4 py-2">Min Qty</th>
                          <th className="px-4 py-2">Max Qty</th>
                          <th className="px-4 py-2">Price</th>
                          <th className="px-4 py-2">Discount Price</th>
                          <th className="px-4 py-2">Metric</th>
                        </tr>
                      </thead>
                      <tbody>
                        {input?.shippingCost?.map((cost, index) => (
                          <tr key={index}>
                            <td className="border px-4 py-2">{cost?.min}</td>
                            <td className="border px-4 py-2">{cost?.max}</td>
                            <td className="border px-4 py-2">{cost?.price}</td>
                            <td className="border px-4 py-2">{cost?.discountPrice}</td>
                            <td className="border px-4 py-2">{cost?.metric}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="bg-gray-100 px-6 py-4 flex justify-between">
                <button
                  onClick={() => handleDeleteShippingInput(index)}
                  className="flex items-center bg-gray-300 text-red-500 hover:text-red-700 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:bg-gray-600"
                >
                  <FaTrash className="mr-2" /> Delete
                </button>
                <button
                  onClick={onSubmit}
                  className="flex items-center bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:bg-blue-600"
                >
                  <FaPlus className="mr-2" /> Add Shipping Input
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </form>
  );
};

export default ShippingInputs;