import React from 'react'

import Footer from '../components/Layout/Footer'
import Header from '../components/Layout/Header'
import ShopResetPassword from '../components/ForgotPassword/ShopResetPassword'

const ShopResetPasswordPage = () => {
  return (
    <div className='w-full min-h-screen bg-[#f6f9fc]'>
       <Header />
     
       <ShopResetPassword/>
       <br />
       <br />
       <Footer />
    </div>
  )
}

export default ShopResetPasswordPage