import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { server } from '../../server';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import Editor from '../../pages/Shop/Editor';


const RefundPolicyPost = ({ shop, setReturnPolicyIsEditing, content }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [post, setPost] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [updatedReturnPolicy, setUpdatedReturnPolicy] = useState('');

  const { isSeller } = useSelector((state) => state.seller);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPost = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${server}/shop/return-policy/${shop}`);
        setPost(response.data.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching post:", error);
        setIsLoading(false);
      }
    };

    fetchPost();
  }, [shop, isDeleted]); // Add isDeleted to dependency array

  const handleEdit = () => {
    setIsEditing(!isEditing);
    setReturnPolicyIsEditing(!isEditing);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${server}/shop/delete-post/${post._id}`);
      console.log('Post deleted successfully');
      setIsDeleted(true); // Update isDeleted state to trigger UI change
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  const handleContentChange = (content) => {
    setUpdatedReturnPolicy(content);
  };

  const handleSave = async () => {
    try {
      const updatedPolicy = {
        ...post,
        returnPolicy: content ? content : updatedReturnPolicy,
        // Assuming the property name is homeTag for tags
      };
  
      const response = await axios.put(`${server}/shop/update-return-policy/${shop}`, updatedPolicy);
      console.log('updates', response.data);
      setIsEditing(false);
      setPost(updatedPolicy); // Update the post state with the response data
      setUpdatedReturnPolicy(updatedPolicy.returnPolicy); // Update the updatedReturnPolicy state variable
    } catch (error) {
      console.error('Error updating post:', error);
    }
  };

  return (
    <div className="container mx-auto">
      <h2 className="text-2xl font-bold mb-4">Return/Refund Policy:</h2>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div className="grid">
          {isDeleted && (
            <div className="bg-white shadow-md rounded-lg p-4 mt-4">
              <p className="text-gray-800">Post Deleted Successfully</p>
            </div>
          )}

          {!isDeleted && !isEditing && post?.returnPolicy &&  !content && (
            <div className="bg-white shadow-md rounded-lg p-4 mt-4">
              <p className="text-gray-800" dangerouslySetInnerHTML={{ __html: post.returnPolicy }}></p>
              <br />
              <p className="text-gray-600 mb-4">{moment(post.createdAt).format('MMMM Do, YYYY')}</p>
              {isSeller && (
                <div className="flex mt-2">
                  <button onClick={handleEdit} className="bg-blue-500 text-white py-2 px-4 rounded-full mr-2">Edit</button>
                  <button onClick={handleDelete} className="bg-red-500 text-white py-2 px-4 rounded-full">Delete</button>
                </div>
              )}
            </div>
          )}
              {content && (
            <div className="bg-white shadow-md rounded-lg p-4 mt-4">
              <p className="text-gray-800" dangerouslySetInnerHTML={{ __html: content }}></p>
              <br />
              {/* <p className="text-gray-600 mb-4">{moment(post.createdAt).format('MMMM Do, YYYY')}</p> */}
              {/* {isSeller && (
                <div className="flex mt-2">
                  <button onClick={handleEdit} className="bg-blue-500 text-white py-2 px-4 rounded-full mr-2">Edit</button>
                  <button onClick={handleDelete} className="bg-red-500 text-white py-2 px-4 rounded-full">Delete</button>
                </div>
              )} */}
            </div>
          )}

          {!isDeleted && isEditing && (
            <div>
              <div className="mt-3">
             
                <Editor content={post?.returnPolicy} onContentChange={handleContentChange} />
              
              </div>
              <br />
              <br />
              <br/>
              <div className="bg-white shadow-md rounded-lg p-4 mt-4">
              <p className="text-gray-800" dangerouslySetInnerHTML={{ __html:updatedReturnPolicy }}></p>
            </div>
              <div className="flex mt-8">
                <button onClick={handleSave} className="bg-green-500 text-white py-2 px-4 rounded-full mr-2">Save</button>
                <button onClick={handleCancel} className="bg-red-500 text-white py-2 px-4 rounded-full">Cancel</button>
              </div>
            </div>
          )}

          {!isDeleted && !post?.returnPolicy && (
            <div className="bg-white shadow-md rounded-lg p-4 mt-4">
              <p className="text-gray-800">Return/Refund Policy is Null</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RefundPolicyPost;
