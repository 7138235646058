import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import styles from "../styles/styles";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";


const ContactUsPage = () => {
  return (
    <div>
      <Helmet>
        <title>About Us - Indazetu</title>
        <meta name="description" content="Learn more about Indazetu, our mission, vision, and the services we provide to connect suppliers and buyers globally." />
      </Helmet>
      <Header activeHeading={5} />
      <ContactUs />
      <Footer />
    </div>
  );
};


const ContactUs = () => {
  return (
    <div className="bg-gray-50 min-h-screen p-6">
      <Helmet>
        <title>Contact Us - Indazetu</title>
        <meta name="description" content="Get in touch with us at Indazetu for any inquiries or support." />
      </Helmet>
      <div className="container mx-auto bg-white shadow-md rounded-lg p-8">
        <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Contact Us</h1>
        <div className="flex flex-col md:flex-row justify-between items-center mb-8">
          <div className="md:w-1/3 text-center mb-4 md:mb-0">
            <FaPhoneAlt size={30} className="text-green-500 mx-auto mb-2" />
            <h2 className="text-xl font-semibold">Call Us</h2>
            <p className="text-gray-600">+254 700 000 000</p>
          </div>
          <div className="md:w-1/3 text-center mb-4 md:mb-0">
            <FaEnvelope size={30} className="text-green-500 mx-auto mb-2" />
            <h2 className="text-xl font-semibold">Email Us</h2>
            <p className="text-gray-600">support@indazetu.com</p>
          </div>
          <div className="md:w-1/3 text-center">
            <FaMapMarkerAlt size={30} className="text-green-500 mx-auto mb-2" />
            <h2 className="text-xl font-semibold">Visit Us</h2>
            <p className="text-gray-600">123 Business Street, Nairobi, Kenya</p>
          </div>
        </div>
        <form className="bg-gray-100 p-6 rounded-lg shadow-inner">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Send Us a Message</h2>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2" htmlFor="name">
              Name
            </label>
            <input
              type="text"
              id="name"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
              placeholder="Your Name"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
              placeholder="Your Email"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2" htmlFor="message">
              Message
            </label>
            <textarea
              id="message"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
              rows="5"
              placeholder="Your Message"
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full bg-green-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-green-600"
          >
            Send Message
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactUsPage;
