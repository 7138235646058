import React from 'react';
import { FaWhatsapp } from 'react-icons/fa'; // Import Font Awesome WhatsApp icon

const WhatsAppChat = ( {whatsAppNumber}) => {
  const phoneNumber = whatsAppNumber; // Replace with your WhatsApp number
  const message = 'Hello! I would like to know more about your services.'; // Optional predefined message

  const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  return (
    <div style={{ textAlign: 'center', margin: '20px' }}>
      <a
        href={whatsappURL}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          backgroundColor: '#25D366',
          color: '#fff',
          padding: '12px 20px',
          borderRadius: '30px',
          textDecoration: 'none',
          fontSize: '16px',
          fontWeight: 'bold',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'background-color 0.3s ease',
        }}
        onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#1EBF57')}
        onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#25D366')}
      >
        <FaWhatsapp style={{ marginRight: '10px', fontSize: '20px' }} />
        Chat with us on WhatsApp
      </a>
    </div>
  );
};

export default WhatsAppChat;
