// MessageForm.js

import React, { useState, useRef,useEffect } from 'react';
import { FaRegFileImage,FaTimes } from 'react-icons/fa';
import { collection,  addDoc, serverTimestamp } from "firebase/firestore";
import db, { storage } from "../../firebase";
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useDispatch, useSelector } from "react-redux";
import  { addMessage }from '../../redux/actions/message';


const MessageForm = ({ selectedConversation }) => {
  const [newMessage, setNewMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const fileInputRef = useRef(null);

  const { user } = useSelector((state) => state.user);
  const { seller } = useSelector((state) => state.seller);
  const currentUser = seller ? seller : user;

  const storageRef = ref(storage, '/files');

  useEffect(() => {
    if (typeof selectedConversation.messages === 'string') {
      setNewMessage(selectedConversation.messages);
      handleSubmit({ preventDefault: () => {} });
    }
  }, [selectedConversation]);

  const handleFileUpload = async (event) => {
    try {
      const file = event.target.files[0];
      const fileRef = ref(storageRef, file.name);
      await uploadBytes(fileRef, file);
      const downloadURL = await getDownloadURL(fileRef);
      setSelectedFile(downloadURL);
      setFilePreview(null);
    } catch (error) {
      console.error('Error uploading file:', error.message);
    }
  };

  const handlePreview = (event) => {
    if (event.target.type === 'file') {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreview(reader.result);
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    } else {
      setNewMessage(event.target.value);
    }
  };

  const handleSubmit = async (event) => {
    if (event) event.preventDefault();

    if (!selectedConversation) {
      console.error('No conversation selected');
      return;
    }

    const recipientId = selectedConversation?.recipient?.id || selectedConversation?.user?.recipient?._id;
    const recipientName = selectedConversation?.recipient?.name || selectedConversation?.user?.recipient?.name;
    const recipientAvatar = selectedConversation?.recipient?.avatar || selectedConversation?.user?.recipient?.avatar;

    if (!recipientId) {
      console.error('Recipient ID is undefined');
      return;
    }

    const message = {
      text: newMessage,
      file: selectedFile,
      createdAt: serverTimestamp(),
      read: false,
      user: {
        ...currentUser,
        recipient: {
          id: recipientId,
          name: recipientName,
          avatar: recipientAvatar,
        },
      },
    };

    try {
      await addDoc(collection(db, 'messages'), message);
    } catch (error) {
      console.error('Error sending message: ', error);
    }

    setNewMessage('');
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const cancelFileSelection = () => {
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div className='bg-gray-300 rounded-md'>
      <div className="mb-2">
        <p className="text-gray-500">{newMessage}</p>
        {selectedFile && (
          <>
            <small>file preview</small>
            <div className="flex items-center">
              <img src={selectedFile} alt="File Preview" className="max-w-[200px] mt-2" />
              <button
                onClick={cancelFileSelection}
                className="ml-2 p-1 rounded-full hover:bg-gray-500 hover:text-white focus:outline-none focus:bg-gray-500"
              >
                <FaTimes size={12} />
              </button>
            </div>
          </>
        )}
      </div>
      <form onSubmit={handleSubmit} className="flex items-center justify-between w-full mb-4">
        <textarea
          value={newMessage}
          onChange={handlePreview}
          placeholder="Type your message..."
          className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
          style={{ maxWidth: 'calc(100% - 8px)' }}
        />
        <input
          type="file"
          accept="image/*, .pdf, .doc, .docx"
          onChange={handleFileUpload}
          style={{ display: 'none' }}
          ref={fileInputRef}
        />
        <div className="flex items-center">
          <div
            className="cursor-pointer flex items-center text-blue-500"
            onClick={() => fileInputRef.current.click()}
          >
            <FaRegFileImage size={20} className="mr-2" />
            <span className="text-sm">Upload</span>
          </div>
          <button type="submit" className="ml-4 px-4 py-2 bg-blue-500 text-white rounded-md">
            Send
          </button>
        </div>
      </form>
    </div>
  );
};

export default MessageForm;
