import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { server } from '../../server';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import Editor from '../../pages/Shop/Editor';
import TagInput from './TagInput';

const ShopAdminHomePost = ({ shop, setHomeIsEditing, content }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [post, setPost] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [updatedContent, setUpdatedContent] = useState('');

  useEffect(() => {
    const fetchPost = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${server}/shop/home-post/${shop}`);
        console.log("bility5", response.data.data);
        setPost(response.data.data);
        setUpdatedContent(response.data.data?.homeContent || '');
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching post:", error);
        setIsLoading(false);
      }
    };

    fetchPost();
  }, [shop]);

  const handleEdit = () => {
    setIsEditing(!isEditing);
    setHomeIsEditing(!isEditing);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleDelete = async () => {
    try {
      const response = await axios.delete(`${server}/shop/delete-post/${post._id}`);
      console.log('Success', response.data);
      setPost(null);
      setIsDeleted(true);
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  const handleContentChange = (content) => {
    setUpdatedContent(content);
  };

  const handleSave = async () => {
    try {
      const updatedPost = {
        ...post,
        homeContent: updatedContent,
      };

      const response = await axios.put(`${server}/shop/update-home-post/${shop}`, updatedPost);
      console.log('updates', response.data);
      setIsEditing(false);
      setPost(updatedPost);
    } catch (error) {
      console.error('Error updating post:', error);
    }
  };

  return (
    <div className="container mx-auto">
      <h2 className="text-2xl font-bold mb-4">Home :</h2>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div className="grid ">
          {isDeleted && (
            <div className="bg-white shadow-md rounded-lg p-4 mt-4">
              <p className="text-gray-800">Post Deleted Successfully</p>
            </div>
          )}
          {!isDeleted && !isEditing && post?.returnPolicy && !content && (
            <div className="flex mt-2">
              <button onClick={handleEdit} className="bg-blue-500 text-white py-2 px-4 rounded-full mr-2">Edit</button>
              <button onClick={handleDelete} className="bg-red-500 text-white py-2 px-4 rounded-full">Delete</button>
            </div>
          )}

          {isEditing && !isDeleted &&  (
            <div>
              <div className='mt-3'>
                <Editor content={updatedContent} onContentChange={handleContentChange} />
              </div>
              <br />
              <br />
              <div className="flex mt-8">
                <button onClick={handleSave} className="bg-green-500 text-white py-2 px-4 rounded-full mr-2">Save</button>
                <button onClick={handleCancel} className="bg-red-500 text-white py-2 px-4 rounded-full">Cancel</button>
              </div>
            </div>
          )}
  {content && (
            <div className="bg-white shadow-md rounded-lg p-4 mt-4">
              <p className="text-gray-800" dangerouslySetInnerHTML={{ __html: content }}></p>  
              <br />
              </div>
            )}
          {post && !isDeleted && (
            <div className="bg-white shadow-md rounded-lg p-4 mt-4">
              <p className="text-gray-800" dangerouslySetInnerHTML={{ __html: post.homeContent }}></p>
            </div>
          )}
          {!post && !isDeleted && (
            <div className="bg-white shadow-md rounded-lg p-4 mt-4">
              <p className="text-gray-800" >Home is Null</p>
            </div>
          )}

        </div>
      )}
    </div>
  );
};

export default ShopAdminHomePost;
