import { useEffect, useState, useCallback } from 'react';
import { onSnapshot, query, orderBy, collection, limit, startAfter, getDocs, where } from 'firebase/firestore';
import db from "../firebase";
import { useSelector } from "react-redux";
const useConversations = () => {
  const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const { user } = useSelector((state) => state.user);
  const { seller } = useSelector((state) => state.seller);

  const currentUsers = seller ? seller : user;

  useEffect(() => {
    if (!currentUsers) return;

    const q = query(
      collection(db, 'messages'),
      orderBy('createdAt', 'desc'),
      limit(100)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const conversationData = [];
      const currentUser_id = currentUsers._id;
      snapshot.forEach((doc) => {
        const messageData = doc.data();
        const senderId = messageData.user?._id;
        const recipientId = messageData.user.recipient?.id;
        const isParticipant = senderId === currentUser_id || recipientId === currentUser_id;
        if (isParticipant) {
          conversationData.push({
            id: doc.id,
            sender: messageData.user,
            messages: [
              {
                id: doc.id,
                text: messageData?.text,
                file: messageData?.file,
                createdAt: messageData?.createdAt,
                read: messageData?.read || false,
              },
            ],
          });
        }
      });

      const groupedConversations = newGroupConversations(conversationData);
      if (groupedConversations.length > 0) {
        setConversations(groupedConversations);
      } else {
        console.error('groupedConversations is empty');
      }
    });

    return () => {
      unsubscribe();
    }
  }, [currentUsers]);

  return { conversations, loading, hasMore };
};

const newGroupConversations = (conversationData) => {
  const groupedConversations = {};

  conversationData.forEach((conversation) => {
    const participants = [conversation.sender?._id, conversation.sender?.recipient?.id];
    participants.sort(); // Sort participants to ensure consistent key
    const key = participants.join("-");

    if (!groupedConversations[key]) {
      groupedConversations[key] = {
        id: conversation.id,
        sender: conversation.sender,
        recipient: conversation.sender?.recipient,
        participants: key,
        messages: [],
      };
    }

    conversation.messages.forEach((message) => {
      groupedConversations[key].messages.push({
        id: message.id,
        text: message.text,
        file: message.file,
        createdAt: message.createdAt,
        read: message.read
      });
    });
  });

  return Object.values(groupedConversations);
};

export default useConversations;
