import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { server } from '../../server';
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import { FaTrash } from 'react-icons/fa';


const ShippingSettingList = ({ settings }) => {

  const [editedSetting, setEditedSetting] = useState(null);
  const [isDeleted, setIsDeleted] = useState(false);
  const { seller } = useSelector((state) => state.seller);


  // const handleEdit = (settingId) => {
  //   // Find the setting with the given ID
  //   const settingToEdit = settings.find(setting => setting._id === settingId);
  //   // Set the editedSetting state to the found setting
  //   setEditedSetting(settingToEdit);
  // };



  const handleDelete = async (settingId) => {
    if (window.confirm("Are you sure you want to delete this setting?")) {
      try {
        await axios.delete(`${server}/shippingCost/delete-shipping-cost/${settingId}`);
        console.log('Post deleted successfully');
        toast.success("Post deleted successfully!");
        setIsDeleted(true); // Update isDeleted state to trigger UI change
      } catch (error) {
        console.error("Error deleting post:", error);
      }
    } else {
      // User canceled deletion
      console.log('User canceled deletion');
    }
  };
  const handleCancelEdit = () => {
    // Clear the editedSetting state when canceling the edit
    setEditedSetting(null);
  };
  console.log('setoi3', settings);
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
      {settings?.map((setting, index) => (
        <div key={index} className="bg-white rounded-lg shadow-md">
          <div className="p-6">
            <h3 className="text-xl font-semibold mb-4">Setting {index + 1}</h3>
            <p className="text-gray-700 mb-2"><span className="font-semibold">Shop ID:</span> {setting.shop}</p>
            <p className="text-gray-700 mb-4"><span className="font-semibold">Delivery Type:</span> {setting?.shippingInputs[0]?.deliveryType}</p>

            <div className="border-b border-gray-200 mb-6">
              <h4 className="text-lg text-blue-500 font-semibold mb-2">Products:</h4>
              <ul className="list-disc pl-6">
                {setting?.shippingInputs[0]?.allProducts?.map((product, index) => (
                  <li key={index}>{product.label}</li>
                ))}
              </ul>
            </div>

            <div className="overflow-x-auto">
              <table className="w-full bg-white rounded-md shadow-md">
                <thead>
                  <tr className="bg-blue-500 text-white">
                    <th className="px-4 py-2">Min Qty</th>
                    <th className="px-4 py-2">Max Qty</th>
                    <th className="px-4 py-2">Price</th>
                    <th className="px-4 py-2">Discount Price</th>
                    <th className="px-4 py-2">Metric</th>
                  </tr>
                </thead>
                <tbody>
                  {setting?.shippingInputs[0]?.shippingCost?.map((cost, index) => (
                    <tr key={index} className={index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}>
                      <td className="border px-4 py-2">{cost.min}</td>
                      <td className="border px-4 py-2">{cost.max}</td>
                      <td className="border px-4 py-2">{cost.price}</td>
                      <td className="border px-4 py-2">{cost.discountPrice}</td>
                      <td className="border px-4 py-2">{cost.metric}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="mt-6">
              <label className="text-blue-700 mb-2 font-semibold">Description: </label>
              <p className="text-gray-700">{setting?.shippingInputs[0]?.description}</p>
            </div>

            <div className="mt-6">
              <label className="text-blue-700 mb-2 font-semibold">Source Location: </label>
              <p className="text-gray-700">{setting?.shippingInputs[0]?.source.location}</p>
            </div>

            <div className="mt-6">
              {/* {JSON.stringify(setting?.shippingInputs[0].destination)} */}
              <label className="text-blue-700 mb-2 font-semibold">Destination Location: </label>
              <p className="text-gray-700">{setting?.shippingInputs[0]?.destination.location}</p>
            </div>
          </div>

          <div className="flex justify-end p-4 bg-gray-100 rounded-b-lg">
            {/* <button onClick={() => handleEdit(setting._id)} className="bg-blue-500 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline hover:bg-blue-600 mr-2">
            Edit
          </button> */}
            <button onClick={() => handleDelete(setting._id)} className="bg-red-500 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline hover:bg-red-600">
              <FaTrash/>
            </button>
          </div>
        </div>
      ))}
    </div>

  );
};

export default ShippingSettingList;
