import React, { useEffect, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { categoriesData } from "../../static/data";
import { toast } from "react-toastify";
import { createevent } from "../../redux/actions/event";
import TagInput from "./TagInput";
import QuantityPriceInput from "./QuantityPriceInput";
import PackagingInput from "./PackageInput";
import ColorPicker from './ColorPicker';
//import MultiSelector from "./MultiSelect";


const CreateEvent = () => {
  const { seller } = useSelector((state) => state.seller);
  const { success, error } = useSelector((state) => state.events);
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const [images, setImages] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState([]);
  const [packaging, setPackaging] = useState([]);
  const [stock, setStock] = useState();
  const [maxOrder, setMaxOrder] = useState();
  const [minOrder, setMinOrder] = useState();
  const [priceInput, setPriceInput] = useState('');
  const [samplePriceInput, setSamplePriceInput] = useState('');
  const [metrics, setMetrics] = useState('');
  const [selectedColor, setSelectedColor] = useState('#000000');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [active, setActive] = useState(true);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);



  const handleColorChange = (color) => {
    setSelectedColor(color);
  };
  const handlePriceChange = (newValue) => {
    setPriceInput(newValue);
  };
  const handleSamplePriceChange = (newValue) => {
    setSamplePriceInput(newValue);
  };
  const handlePackagingChange = (newValue) => {

    console.log('this has changed', newValue)
    setPackaging(newValue);

  };


  const handleEndDateChange = (e) => {
    setEndDate(new Date(e.target.value));
  };


  const handleStartDateChange = (e) => {
    const startDate = new Date(e.target.value);

    // Calculate the minimum end date by adding 3 days to the start date
    const minEndDate = new Date(startDate.getTime() + 3 * 24 * 60 * 60 * 1000);

    setStartDate(startDate);
    setEndDate(null);

    // Set the minimum value for the end date input
    document.getElementById("end-date").min = minEndDate.toISOString().slice(0, 10);
  };


  const today = new Date().toISOString().slice(0, 10);

  const minEndDate = startDate
    ? new Date(startDate.getTime() + 3 * 24 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10)
    : "";

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      toast.success("Event created successfully!");
      navigate("/dashboard-events");
      window.location.reload();
    }
  }, [dispatch, error, success]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    setImages([]);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImages((old) => [...old, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newForm = new FormData();

    images.forEach((image) => {
      newForm.append("images", image);
    });
    const data = {
      name,
      description,
      category,
      tags,
      stock,
      shopId: seller._id,
      images,
      priceInput,
      samplePriceInput,
      selectedColor,
      packaging,
      minOrder,
      maxOrder,
      start_Date: startDate?.toISOString(),
      Finish_Date: endDate?.toISOString(),
    };
    console.log('trillion', data);
    dispatch(createevent(data));
  };
  return (
    <div className="w-full 600px:w-[60%] bg-white  shadow h-[90vh] rounded-[4px] p-5 overflow-y-scroll">
      
      <h5 className="text-2xl font-Poppins text-center mb-6">Create Event</h5>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block pb-2">
            Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="name"
            value={name}
            className="mt-2 block w-full px-3 h-10 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your event product name..."
          />
        </div>

        <div className="mb-4">
          <label className="block pb-2">
            Description <span className="text-red-500">*</span>
          </label>
          <textarea
            cols="30"
            required
            rows="5"
            name="description"
            value={description}
            className="mt-2 block w-full pt-2 px-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter your event product description..."
          ></textarea>
        </div>

        <div className="mb-4">
          <label className="block pb-2">
            Category <span className="text-red-500">*</span>
          </label>
          <select
            className="w-full mt-2 border h-10 rounded-md"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="Choose a category">Choose a category</option>
            {categoriesData &&
              categoriesData.map((i) => (
                <option value={i.title} key={i.title}>
                  {i.title}
                </option>
              ))}
          </select>
        </div>

        <div className="mb-4">
          <label className="block pb-2">Tags</label>
          <TagInput label="Tags" tags={tags} setTags={setTags} />
        </div>

        <div className="mb-4">
          <label className="block pb-2">Packaging</label>
          <PackagingInput onPackagingChange={handlePackagingChange} currency={seller.currency} />
        </div>

        <div className="relative mb-4">
          <label className="block pb-2">Pick Your Color</label>
          <ColorPicker defaultColor={selectedColor} onColorChange={handleColorChange} />
        </div>

        <div className="mb-4">
          <label className="block pb-2">
            Price (With Discount) <span className="text-red-500">*</span>
          </label>
          <QuantityPriceInput onPriceChange={handlePriceChange} currency={seller.currency} />
        </div>

        <div className="mb-4">
          <label className="block pb-2">
            Sample Product Price (With Discount) <span className="text-red-500">*</span>
          </label>
          <QuantityPriceInput onPriceChange={handleSamplePriceChange} currency={seller.currency} />
        </div>

        <div className="mb-4">
          <label className="block pb-2">
            Product Stock <span className="text-red-500">*</span>
          </label>
          <input
            type="number"
            name="stock"
            value={stock}
            className="mt-2 block w-full px-3 h-10 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setStock(e.target.value)}
            placeholder="Enter your product stock..."
          />
        </div>

        <div className="flex flex-col md:flex-row md:space-x-4 mb-4">
          <div className="flex-1">
            <label className="block pb-2">Maximum Order</label>
            <input
              type="number"
              name="maxOrder"
              value={maxOrder}
              className="mt-2 block w-full px-3 h-10 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setMaxOrder(e.target.value)}
              placeholder="Enter maximum order..."
            />
          </div>

          <div className="flex-1">
            <label className="block pb-2">Minimum Order</label>
            <input
              type="number"
              name="minOrder"
              value={minOrder}
              className="mt-2 block w-full px-3 h-10 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setMinOrder(e.target.value)}
              placeholder="Enter minimum order..."
            />
          </div>
        </div>

        <div className="mb-4">
          <label className="block pb-2">
            Event Start Date <span className="text-red-500">*</span>
          </label>
          <input
            type="date"
            id="start-date"
            className="mt-2 block w-full px-3 h-10 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={handleStartDateChange}
            value={startDate ? startDate.toISOString().slice(0, 10) : ""}
          />
        </div>

        <div className="mb-4">
          <label className="block pb-2">
            Event End Date <span className="text-red-500">*</span>
          </label>
          <input
            type="date"
            id="end-date"
            className="mt-2 block w-full px-3 h-10 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={handleEndDateChange}
            value={endDate ? endDate.toISOString().slice(0, 10) : ""}
          />
        </div>

        <div className="mb-4">
          <label className="block pb-2">
            Upload Images <span className="text-red-500">*</span>
          </label>
          <input
            type="file"
            id="upload"
            className="hidden"
            multiple
            onChange={handleImageChange}
          />
          <div className="flex items-center flex-wrap">
            <label htmlFor="upload">
              <AiOutlinePlusCircle size={30} className="mt-3" color="#555" />
            </label>
            {images &&
              images.map((i) => (
                <img
                  src={i}
                  key={i}
                  alt=""
                  className="h-30 w-30 object-cover m-2"
                />
              ))}
          </div>
        </div>

        <div>
          <input
            type="submit"
            value="Create"
            className="mt-4 cursor-pointer block w-full px-3 h-10 border border-gray-300 rounded-md placeholder-gray-400 bg-blue-500 text-white focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          />
        </div>
      </form>
    </div>

  );
}
export default CreateEvent;

