import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  message: [],
};

export const messageReducer = createReducer(initialState, {
  addMessage: (state, action) => {
    const item = action.payload;
    console.log('payer',item,'payer3',state.message);
    const isItemExist = state.message.find((i) => i.id === item._id);
    console.log('payer2',isItemExist);
    if (isItemExist) {
      return {
        ...state,
        message: state.message.map((i) =>
          i.id === isItemExist._id ? item : i
        ),
      };
    } else {
      return {
        ...state,
        message: [...state.message, item],
      };
    }
  },

  deleteMessage: (state, action) => {
    return {
      ...state,
      message: state.message.filter((i) => i._id !== action.payload),
    };
  },
});
