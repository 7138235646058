import axios from "axios";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";



const LoyaltyPointsConfigurator = () => {
  // State variables for loyalty points configuration
  const [pointsPerAmount, setPointsPerAmount] = useState(1);
  const [amountFrom, setAmountFrom] = useState(0);
  const [amountTo, setAmountTo] = useState(0);
  const [pointsWorth, setPointsWorth] = useState(0.01);
  const [expirationDate, setExpirationDate] = useState(""); // New state for expiration date

  // State variables for user interaction
  const [amountSpent, setAmountSpent] = useState(0);
  const [loyaltyPoints, setLoyaltyPoints] = useState(0);

  // State variable for the list of loyalty points configurations
  const [loyaltyConfigurations, setLoyaltyConfigurations] = useState([]);
  const { seller } = useSelector((state) => state.seller);

  useEffect(() => {
    // Fetch loyalty points configurations from backend on component mount
    fetchLoyaltyConfigurations();
  }, []);

  const fetchLoyaltyConfigurations = async () => {
    try {
      const response = await axios.get("http://localhost/shop");
      setLoyaltyConfigurations(response.data);
    } catch (error) {
      console.error("Error fetching loyalty configurations:", error);
    }
  };

  const handleSubmit = async () => {
    try {
      
      await axios.post("http://localhost/shop", {
        pointsPerAmount,
        amountFrom,
        amountTo,
        seller:seller._id,
        pointsWorth,
        expirationDate
      });
      // Refetch loyalty configurations after successful submission
      fetchLoyaltyConfigurations();
    } catch (error) {
      console.error("Error submitting loyalty configuration:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`http://localhost/shop/${id}`);
      // Refetch loyalty configurations after successful deletion
      fetchLoyaltyConfigurations();
    } catch (error) {
      console.error("Error deleting loyalty configuration:", error);
    }
  };

  const simulatePurchaseAndEarnPoints = () => {
    const calculatedPoints =
      Math.floor(
        (amountSpent - amountFrom) / (amountTo - amountFrom) * pointsPerAmount
      ) || 0;
    setLoyaltyPoints(loyaltyPoints + calculatedPoints);
  };

  // Function to handle redeeming loyalty points
  const redeemPoints = () => {
    const redeemedAmount = loyaltyPoints * pointsWorth;
    alert(`You have redeemed ${loyaltyPoints} points worth $${redeemedAmount.toFixed(2)}`);
    setLoyaltyPoints(0); // Reset loyalty points after redemption
  };

  return (
    <div className="max-w-500 mx-auto mt-8 p-4 bg-gray-100 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-4">Loyalty Points Configuration</h2>
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
          <label className="block mb-2">Points per Amount Spent:</label>
          <input
            type="number"
            value={pointsPerAmount}
            onChange={(e) => setPointsPerAmount(parseInt(e.target.value))}
            className="border rounded-md p-2 w-full"
          />
        </div>
        <div>
          <label className="block mb-2">Amount From ($):</label>
          <input
            type="number"
            value={amountFrom}
            onChange={(e) => setAmountFrom(parseFloat(e.target.value))}
            className="border rounded-md p-2 w-full"
          />
        </div>
        <div>
          <label className="block mb-2">Amount To ($):</label>
          <input
            type="number"
            value={amountTo}
            onChange={(e) => setAmountTo(parseFloat(e.target.value))}
            className="border rounded-md p-2 w-full"
          />
        </div>
        <div>
          <label className="block mb-2">Points Worth ($):</label>
          <input
            type="number"
            value={pointsWorth}
            onChange={(e) => setPointsWorth(parseFloat(e.target.value))}
            className="border rounded-md p-2 w-full"
          />
        </div>
        <div>
          <label className="block mb-2">Expiration Date:</label>
          <input
            type="date"
            value={expirationDate}
            onChange={(e) => setExpirationDate(e.target.value)}
            className="border rounded-md p-2 w-full"
          />
        </div>
      </div>

      <hr className="my-8" />

      <h2 className="text-2xl font-bold mb-4">Simulate Purchases and Redeem Loyalty Points</h2>
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
          <label className="block mb-2">Amount Spent ($):</label>
          <input
            type="number"
            value={amountSpent}
            onChange={(e) => setAmountSpent(parseFloat(e.target.value))}
            className="border rounded-md p-2 w-full"
          />
        </div>
        <div className="flex items-end">
          <button
            onClick={simulatePurchaseAndEarnPoints}
            className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-400"
          >
            Simulate Purchase and Earn Points
          </button>
          <button
            onClick={redeemPoints}
            className="bg-red-500 text-white py-2 px-4 rounded-lg ml-4 hover:bg-red-600 focus:outline-none focus:ring focus:ring-red-400"
          >
            Redeem Loyalty Points
          </button>
        </div>
      </div>

      <hr className="my-8" />

      <div>
        <h2 className="text-2xl font-bold mb-4">Current Status</h2>
        <p>Current Loyalty Points: {loyaltyPoints}</p>
        <p>Redemption Value: ${loyaltyPoints * pointsWorth}</p>
        <p>Expiration Date: {expirationDate}</p>
      </div>


      <div className="mt-6">
        <button
          onClick={handleSubmit}
          className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 focus:outline-none focus:ring focus:ring-green-400"
        >
          Save Configuration
        </button>
        <hr />
        {/* List of loyalty points configurations */}
        <div className="mt-4">
          <h2 className="text-2xl font-bold mb-4">Loyalty Points Configurations</h2>
          {loyaltyConfigurations.map((config) => (
            <div key={config.id} className="mb-4 border rounded-lg p-4">
              <p>Points per Amount Spent: {config.pointsPerAmount}</p>
              <p>Amount From ($): {config.amountFrom}</p>
              <p>Amount To ($): {config.amountTo}</p>
              <p>Points Worth ($): {config.pointsWorth}</p>
              <p>Expiration Date: {config.expirationDate}</p>
              <button
                onClick={() => handleDelete(config.id)}
                className="bg-red-500 text-white py-1 px-2 rounded-lg mt-2 hover:bg-red-600 focus:outline-none focus:ring focus:ring-red-400"
              >
                Delete
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default LoyaltyPointsConfigurator;