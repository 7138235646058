import React, { useState, useEffect } from "react";
import { RxCross1 } from "react-icons/rx";
import { IoBagHandleOutline } from "react-icons/io5";
import { HiOutlineMinus, HiPlus } from "react-icons/hi";
import styles from "../../styles/styles";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addTocart, removeFromCart } from "../../redux/actions/cart";
import { toast } from "react-toastify";
import useShop from "../../hooks/useShop";



const Cart = ({ setOpenCart }) => {
  const { cart } = useSelector((state) => state.cart);
  console.log('cartoz', cart)
  const dispatch = useDispatch();

  // Define state to store selected range for each item in the cart
  const [selectedRange, setSelectedRange] = useState({});




  const removeFromCartHandler = (data) => {
    dispatch(removeFromCart(data));
  };

  const totalPrice = cart.reduce((acc, item) => {
    console.log('itemz', item, selectedRange);
    if (!Object.isExtensible(item)) {
      item = { ...item }; // Create a new extensible object with the same properties
    }

    item.type = 'product';

    if (item.qty > item.stock) {
      return;
    }

    let itemTotal = 0;

    if (item.type !== 'sample') {
      const priceInput = item?.priceInput || {}; // Ensure priceInput is defined, default to an empty object
      const selectedRangeValue = selectedRange[item._id];

      const selectedPriceInput = priceInput[selectedRangeValue] || {}; // Ensure selected price input is defined
      console.log('gif4', selectedRangeValue);

      if (selectedRangeValue === undefined) {
        return;
      }

      const values = selectedRangeValue?.split('-');
      console.log('values', values[3]);
      const idx = values[3];

      console.log('itemtotals', selectedPriceInput, 'zii', priceInput[idx], 'zii2', selectedRange)
      const price = parseFloat(
        priceInput[idx].discountPrice || priceInput[idx].price || 0
      );
      console.log('itemtotals', price)
      itemTotal = item.qty * price;
      console.log('itemtotal', itemTotal, item.qty, price)
    } else {
      const price = parseFloat(
        item?.samplePriceInput[selectedRange[item._id]]?.discountPrice || item?.samplePriceInput[selectedRange[item._id]]?.price
      );

      itemTotal = item.qty * price;
    }

    // If any item's total is zero, the entire total should be NaN
    if (itemTotal === 0) {
      return NaN;
    }

    return acc + itemTotal;
  }, 0);

  // Check if totalPrice is NaN and handle accordingly
  const displayTotalPrice = isNaN(totalPrice) ? 'NaN' : totalPrice.toFixed(2);

  console.log('Total Price:', displayTotalPrice);


  const quantityChangeHandler = (data) => {
    dispatch(addTocart(data));
    console.log('dispatch', data);
  };
  console.log('hello', cart[0]?.shopId);

  const { shop, loading, error } = useShop(cart[0]?.shopId);
  console.log('hello2', shop);

  return (
    // JSX code
    <div className="fixed top-0 left-0 w-full bg-[#0000004b] h-screen z-10">
      <div className="fixed top-0 right-0 h-full w-[80%] 800px:w-[25%] bg-white flex flex-col overflow-y-scroll justify-between shadow-sm">
        {cart && cart.length === 0 ? (
          <div className="w-full h-screen flex items-center justify-center">
            <div className="flex w-full justify-end pt-5 pr-5 fixed top-3 right-3">
              <RxCross1
                size={25}
                className="cursor-pointer"
                onClick={() => setOpenCart(false)}
              />
            </div>
            <h5>Cart Items is empty!</h5>
          </div>
        ) : (
          <>
            <div>
              <div className="flex w-full justify-end pt-5 pr-5">
                <RxCross1
                  size={25}
                  className="cursor-pointer"
                  onClick={() => setOpenCart(false)}
                />
              </div>
              {/* Item length */}
              <div className={`${styles.noramlFlex} p-4`}>
                <IoBagHandleOutline size={25} />
                <h5 className="pl-2 text-[20px] font-[500]">
                  {cart && cart.length} items
                </h5>
              </div>

              {/* cart Single Items */}
              <br />
              <div className="w-full border-t">
                {/* Pass selectedRange and setSelectedRange to CartSingle */}
                {cart.map((item) => (
                  <CartSingle
                    key={item._id}
                    data={item}
                    selectedRange={selectedRange[item._id]} // Pass selectedRange for the specific item
                    setSelectedRange={(range) => setSelectedRange(prevState => ({ ...prevState, [item._id]: range }))} // Pass setSelectedRange for the specific item
                    quantityChangeHandler={quantityChangeHandler}
                    removeFromCartHandler={removeFromCartHandler}
                    totalCost={totalPrice}
                    useShop={useShop}
                  />
                ))}
              </div>
            </div>
            <div className="px-5 mb-3">
              {/* Render the checkout button only when totalPrice is a number */}
              {!isNaN(totalPrice) ? (
                <Link to="/checkout">
                  <div className={`h-[45px] flex items-center justify-center w-[100%] bg-[#e44343] rounded-[5px]`}>
                    <h1 className="text-[#fff] text-[18px] font-[600]">
                      Checkout Now ({shop?.shop?.currency} {totalPrice})
                    </h1>
                  </div>
                </Link>
              ) : 
              <div className="h-[65px] flex flex-col items-center justify-center w-full bg-white rounded-[5px] shadow-md p-4">
              <h1 className="text-gray-700 text-lg font-semibold">
                Complete the Setup
              </h1>
              <small className="text-red-500 mt-2">
                Change Quantity/Selected Range From Default
              </small>
            </div>}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const CartSingle = ({ data, selectedRange, setSelectedRange, quantityChangeHandler, removeFromCartHandler, totalCost, useShop }) => {


  const values = selectedRange !== undefined ? selectedRange.split('-') : [];
  console.log('valuesttt', values);

  const minCount = values.length > 0 ? parseInt(values[0]) : 0;
  const maxCount = parseInt(values[1]);
  const price = parseInt(values[2]);
  const [value, setValue] = useState(minCount);


  const [showValidationError, setShowValidationError] = useState(false);
  const [showValidationMessage, setShowValidationMessage] = useState('');
  const { shop, loading, error } = useShop(data?.shopId);
  const { products } = useSelector((state) => state.products);
  const [selectedRangeIndex, setSelectedRangeIndex] = useState(data.selectedRangeIndex || 0);
  const [minSampleQty, setMinSampleQty] = useState(0);
  const [maxSampleQty, setMaxSampleQty] = useState(0);
  const [samplePrice, setSamplePrice] = useState(0);
  const [sampleTotalPrice, setSampleTotalPrice] = useState(0);
  const [count, setCount] = useState(minSampleQty);
  const [click, setClick] = useState(false);
  const [select, setSelect] = useState(0);
  const [cartType, setCartType] = useState("product");
  const [showSamplePriceCalculation, setShowSamplePriceCalculation] = useState(false);
  // const [selectedRangeIndex,setSelectedRangeIndex]=useState(0);



  // useEffect(() => {
  //   // Ensure values is an array and is present before proceeding

  //   const values = selectedRange !== undefined ? selectedRange.split('-') : [];
  //   setSelectedRangeIndex(values[3]);
  //   console.log('valuesttt', values);

  //   const minCount = values.length > 0 ? parseInt(values[0]) : 0;
  //   setValue(minCount);

  
  // }, [selectedRange]);

  useEffect(() => {
    const values = selectedRange !== undefined ? selectedRange.split('-') : [];
    setSelectedRangeIndex(values[3]);
    const minCount = values.length > 0 ? parseInt(values[0]) : 0;
    setValue(minCount);
    //increment(0);


  }, [selectedRange]);

  // useEffect(()=>{
  
  //     increment();
      
  // },[value,selectedRangeIndex]);

  useEffect(() => {

    console.log('bigups', data?.selectedRange, 'up', selectedRange)
    // const values = selectedRange.split('-');

    // const minCount = parseInt(values[0], 10);
    // setMinSampleQty(minCount);
    if (data?.selectedRange && data?.selectedRange !== undefined) {
      // setValue(data?.qty)
      setSelectedRange(data?.selectedRange)
      // handleRangeChange(data?.selectedRange);
    }
    if (data?.stock < value) {
      setShowValidationError(true);
    }
  
  }, [data?.selectedRange]);



  const increment = () => {
    console.log('increment');
    if (data.stock < value) {
      setShowValidationError(true);
      setShowValidationMessage("Product stock limited!");
      toast.error("Product stock limited!");
    } else {


      const updatedValue = value + 1;
      if(updatedValue > maxCount){

        setShowValidationError(true);
        setShowValidationMessage("Max Range Error!");
        toast.error("Max Range Error!");
        return;
      }
      setValue(updatedValue);

      const updateCartData = { ...data, qty: updatedValue, currency: shop?.shop?.currency };
      quantityChangeHandler(updateCartData);
      setShowValidationError(false);
    }
  };

  const decrement = () => {

    if (data.stock < value) {
      setShowValidationError(true);
      setShowValidationMessage("Product stock limited");
      toast.error("Product stock limited!");
    } else {
      const updatedValue = value === 1 ? 1 : value - 1;
      if(updatedValue < minCount){
        setShowValidationError(true);
        setShowValidationMessage("Min Range Error!");
        toast.error("Min Range Error!");
        return;
      }
      setValue(updatedValue);
      const updateCartData = { ...data, qty: updatedValue, currency: shop?.shop?.currency };
      quantityChangeHandler(updateCartData);
      setShowValidationError(false);
    };

  }

  const calculatePrice = () => {
    if (!data) return 0;
    // console.log('Madvd', data);
    // console.log('gif', data);
    if (data.type !== "sample") {
      const rangeIndex = selectedRange !== undefined ? selectedRangeIndex : data.selectedRangeIndex;
      if (!Object.isExtensible(data)) {
        data = { ...data }; // Create a new extensible object with the same properties
      }

      data.type = 'product';

      if (!data.selectedRangeIndex) {
        data.selectedRangeIndex = rangeIndex;
        data.selectedRange = selectedRange;
      }

      if (!Array.isArray(data?.priceInput) || rangeIndex < 0 || rangeIndex >= data?.priceInput.length) {
        return 0;
      }
      const priceRange = data?.priceInput[rangeIndex];
      const quantity = Number(value);
      const price = parseFloat(priceRange?.discountPrice || priceRange?.price);


      return isNaN(price) || isNaN(quantity) ? 0 : price * quantity;
    } else {
      const rangeIndex = selectedRange !== undefined ? selectedRangeIndex : data?.selectedRangeIndex;
      if (!Array.isArray(data?.samplePriceInput) || rangeIndex < 0 || rangeIndex >= data?.samplePriceInput.length) {
        return 0;
      }

      const samplePriceRange = data?.samplePriceInput[rangeIndex];
      const sampleQuantity = Number(value);
      const samplePrice = parseFloat(samplePriceRange?.discountPrice || samplePriceRange?.price);
      return isNaN(samplePrice) || isNaN(sampleQuantity) ? 0 : samplePrice * sampleQuantity;
    }
  };


  const handleRangeChange = () => {
    

    if (!Object.isExtensible(data)) {
      data = { ...data }; // Create a new extensible object with the same properties
    }

    data.type = 'product';

    if (!data.selectedRangeIndex) {
      data.selectedRangeIndex = Number(values[3]);
      data.selectedRange = selectedRange;
    }
    if (!selectedRange) {
      setSamplePrice(0);
      setSampleTotalPrice(0);
      return;
    }
   
    // If values are valid numbers
    if (!isNaN(minCount) && !isNaN(maxCount)) {
      setMinSampleQty(minCount);
      // setValue(minCount);
      setCount(minCount);
      setMaxSampleQty(maxCount);
      setSamplePrice(price);
      setSampleTotalPrice(Number(price) * minCount);
      //setSelectedRange(range); // Update selectedRange state
      setSelectedRangeIndex(Number(values[3]));
    } else {
      // Handle invalid range values
      console.error('Invalid range values');
    }
     
 
  };

  const onChange = (e) => {
    const range = e.target.value;

    console.log('range', range);

    setSelectedRange(range);
    handleRangeChange();
     
    const updateCartData = { ...data, qty: minCount, currency: shop?.shop?.currency };
    quantityChangeHandler(updateCartData);

  };
  const renderRangeOptions = () => {

    console.log('priceInput')
    return data?.priceInput?.map((range, index) => (

      <option key={index} value={`${range.min} - ${range.max}-${range.discountPrice ? range.discountPrice : range.price}-${index}`} >
        {/* {`${range.min} - ${range.max}`} - {range.currency}${range.discountPrice ? range.discountPrice : range.price}/piece */}
        {`${range.min} - ${range.max}`} {range.metric} @<small>{shop?.shop?.currency}.</small> {range.discountPrice ? range.discountPrice : range.price}
      </option>
    ));

  };

  const totalPrice = calculatePrice();

  return (
    <div className="border-b p-4">
      <div className="w-full flex items-center">
        <div>
          <div
            className={`bg-[#e44343] border border-[#e4434373] rounded-full w-[25px] h-[25px] flex items-center justify-center cursor-pointer`}
            onClick={increment}
          >
            <HiPlus size={18} color="#fff" />
          </div>
          <span className="pl-[10px]">{value}</span>
          <div
            className="bg-[#a7abb14f] rounded-full w-[25px] h-[25px] flex items-center justify-center cursor-pointer"
            onClick={decrement}
          >
            <HiOutlineMinus size={16} color="#7d879c" />
          </div>
        </div>
        <img
          src={`${data?.images[0]?.url}`}
          alt=""
          className="w-[130px] h-min ml-2 mr-2 rounded-[5px]"
        />

        <div className="pl-[5px]">
          <h3 className="text-2xl font-bold">
            {data?.type === 'sample' ? (
              <>
                <span className="text-gray-900 mb-2">{data.name}</span>
                <br />
                <span className="text-green-600 text-sm mt-3">(Sample Product)</span>
              </>
            ) : (
              <span className="text-gray-900">{data.name}</span>
            )}
          </h3>
          {/* {data.stock} */}
          <select value={selectedRange !== undefined ? selectedRange : ''} onChange={onChange}  className="border border-gray-300 rounded-md px-2 py-1">
            <option value="">Select Range</option>
            {renderRangeOptions()}
           
          </select>

          <h4 className="font-[400] text-[15px] text-[#00000082]">
            {selectedRange !== undefined && data?.priceInput ? "@" + shop?.shop?.currency + ' ' + (data.priceInput[selectedRangeIndex]?.discountPrice || data.priceInput[selectedRangeIndex]?.price) : "@" + shop?.shop?.currency + " 0"}
          </h4>
          <h4 className="font-[600] text-[17px] pt-[3px] text-[#d02222] font-Roboto">
            {shop?.shop?.currency + " " + totalPrice}
          </h4>
          {showValidationError && (
            <small className="text-red-300">{showValidationMessage}</small>
          )}
          <RxCross1
            className="cursor-pointer"
            onClick={() => removeFromCartHandler(data)}
          />
        </div>

      </div>
    </div>
  );
};


export default Cart;
