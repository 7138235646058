import axios from "axios";
import React, { useEffect, useState } from "react";
import { server } from "../../server";

const CountDown = ({ data }) => {
 
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [isTimerRunning, setIsTimerRunning] = useState(true); // Flag for timer state

  useEffect(() => {
    let timerId;

    if (isTimerRunning) {
      timerId = setTimeout(() => {
        const newTimeLeft = calculateTimeLeft();
        if (newTimeLeft.days === 0 &&
          newTimeLeft.hours === 0 &&
          newTimeLeft.minutes === 0 &&
          newTimeLeft.seconds === 0) {
          setIsTimerRunning(false); // Stop timer and send request
          axios.delete(`${server}/event/delete-shop-event/${data?._id}`);
        } else {
          setTimeLeft(newTimeLeft);
        }
      }, 1000);
    }

    return () => clearTimeout(timerId); // Cleanup timer on unmount
  }, [data, isTimerRunning]);

  function calculateTimeLeft() {
    const difference = +new Date(data?.Finish_Date) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }

  const timerComponents = Object.keys(timeLeft).map((interval) => {
    if (!timeLeft[interval]) {
      return null;
    }

    return (
      <span className="text-[25px] text-[#475ad2]">
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  return (
    <div>
      {timerComponents.length ? (
        timerComponents
      ) : (
        <span className="text-[red] text-[25px]">Time's Up</span>
      )}
    </div>
  );
};

export default CountDown;
