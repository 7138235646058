import { React, useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import logo from "../../Assests/images/logoo.png";
import 'react-toastify/dist/ReactToastify.css';


const ShopLogin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [shopId, setShopId] = useState(null);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post(`${server}/shop/check-shop`, {
        email,
        password,
      });

      setShopId(res.data.shopId);
      await axios.post(`${server}/notification/send-otp`, {
        email,
        type: "shop",
      });

      toast.success("OTP sent to your email");
      setOtpSent(true);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();

    try {
      await axios.post(`${server}/notification/verify-otp`, { email, otp, type: 'shop' });

      
      // Step 4: Re-authenticate the user (final login step)
      const loginResponse = await handleSubmit();
      if (loginResponse) {
        toast.success("Login Success!");
        navigate("/dashboard");
        window.location.reload(true);
      }
    
      //window.location.reload(true);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  const handleSubmit = async () => {
    try {
        const res= await axios.post(`${server}/shop/complete-shop-login`, {
        shopId,
        password,
        otp,
      }, { withCredentials: true });

      return res.data; // Return the full response or a specific part if needed
    } catch (err) {
      toast.error(err.response?.data?.message || "Login failed");
      return null;
    }
  };



  return (
    <div className="min-h-screen bg-gradient-to-r from-orange-400 to-white-500 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <a href="/">
          <img
            className="mx-auto w-full max-w-xs h-auto"
            src={logo}
            alt="Logo"
          />

        </a>
        <h2 className="mt-6 text-center text-3xl font-extrabold text-white">
          {otpSent ? "Verify OTP" : "Login to your account"}
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={otpSent ? handleVerifyOtp : handleLogin}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  name="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
            {otpSent && (
              <div>
                <label
                  htmlFor="otp"
                  className="block text-sm font-medium text-gray-700"
                >
                  OTP
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="otp"
                    autoComplete="otp"
                    required
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>
            )}
            {!otpSent && (
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1 relative">
                  <input
                    type="password"
                    name="password"
                    autoComplete="current-password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>
            )}
            <div>
              <button
                type="submit"
                className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
              >
                {otpSent ? "Verify OTP" : "Submit"}
              </button>
            </div>
            <div className="text-center">
              <p className="text-sm">

                <a
                  href="/shop-forgot-password"
                  className="font-medium text-blue-600 hover:text-blue-500"
                >
                  Forgot your password?{" "}
                </a>
              </p>
            </div>
            <div className="text-center">
              <p className="text-sm">
                Don't have an account?{" "}
                <a
                  href="/shop-create"
                  className="font-medium text-blue-600 hover:text-blue-500"
                >
                  Sign Up
                </a>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};


export default ShopLogin;
