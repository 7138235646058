import React,{useState} from "react";
import { useNavigate } from "react-router-dom";
import { brandingData, categoriesData } from "../../../static/data";
import styles from "../../../styles/styles";

const Categories = () => {
  const navigate = useNavigate();
  const [showAll, setShowAll] = useState(false);

  const displayedCategories = showAll ? categoriesData : categoriesData.slice(0, 10);

  const handleShowMore = () => setShowAll(true);
  const handleShowLess = () => setShowAll(false);

  return (
    <>
      <div className={`${styles.section} hidden sm:block`}>
        <div className="branding my-12 flex justify-between w-full shadow-sm bg-white p-5 rounded-md">
          {brandingData &&
            brandingData.map((i, index) => (
              <div className="flex items-start" key={index}>
                {i.icon}
                <div className="px-3">
                  <h3 className="font-bold text-sm md:text-base">{i.title}</h3>
                  <p className="text-xs md:text-sm">{i.Description}</p>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className={`${styles.section} bg-white p-6 rounded-lg mb-12`} id="categories">
      <div className={`${styles.heading}`}>
          <h1>Categories</h1>
        </div>
        <div className="grid grid-cols-1 gap-[5px] md:grid-cols-2 md:gap-[10px] lg:grid-cols-4 lg:gap-[20px] xl:grid-cols-5 xl:gap-[30px]">
          {displayedCategories &&
            displayedCategories.map((i) => {
              const handleSubmit = (i) => {
                navigate(`/products?category=${i.title}`);
              };
              return (
                <div
                  className="w-full h-[100px] flex items-center justify-between cursor-pointer overflow-hidden"
                  key={i.id}
                  onClick={() => handleSubmit(i)}
                >
                  <h5 className="text-[18px] leading-[1.3]">{i.title}</h5>
                  <img src={i.image_Url} className="w-[120px] object-cover" alt="" />
                </div>
              );
            })}
        </div>
        {categoriesData.length > 10 && (
          <div className="flex justify-center mt-4">
            {showAll ? (
              <button
                onClick={handleShowLess}
                className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600"
              >
                Show Less
              </button>
            ) : (
              <button
                onClick={handleShowMore}
                className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600"
              >
                Show More
              </button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Categories;
