import React from 'react'
import DashboardHeader from '../../components/Shop/Layout/DashboardHeader'
import DashboardSideBar from '../../components/Shop/Layout/DashboardSideBar'
import PerformanceChart from "../../components/Shop/PerfomanceChart";

const ShopPerformance = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <DashboardHeader />
      <div className="flex flex-1">
        <div className="w-[80px] md:w-[330px]">
          <DashboardSideBar active={13} />
        </div>
        <div className="flex-1 p-4">
          <PerformanceChart />
        </div>
      </div>
    </div>
  );
};

export default ShopPerformance;