import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";

const TermsAndConditionsPage = () => {
  return (
    <div>
      <Helmet>
        <title>Terms and Conditions - Indazetu</title>
        <meta name="description" content="Read the Terms and Conditions of Use for Indazetu, Kenya's leading B2B marketplace platform. Last updated on 7 December 2021." />
        <meta name="keywords" content="Indazetu, Terms and Conditions, B2B marketplace, Kenya, user agreement" />
      </Helmet>
      <Header />
      <TermsAndConditions />
      <Footer />
    </div>
  );
};
const TermsAndConditions = () => {
  return (
    <div className="bg-gray-100 py-10 px-4 sm:px-6 lg:px-8">

      <div className="max-w-7xl mx-auto bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold mb-6">Indazetu Terms and Conditions of Use</h1>
        <p className="text-gray-600 mb-4">Last Updated On: 25 July 2024</p>

        <div className="space-y-8">
          <section>
            <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
            <p className="text-gray-700">
              Welcome to Indazetu, Kenya's leading B2B marketplace platform! This Agreement outlines the terms and conditions of use for our services. By accessing or using our platform, you agree to comply with these terms.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">2. Registration and Account</h2>
            <p className="text-gray-700">
              You must be eligible to use our services as per applicable law. Upon registration, you agree to provide accurate information and maintain the confidentiality of your account credentials.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">3. Terms and Conditions of Sale</h2>
            <p className="text-gray-700">
              Indazetu acts as a venue for vendors and buyers to negotiate and enter into agreements for the sale of goods and services. Indazetu is not a party to these transactions and does not control or guarantee the quality, safety, or legality of items listed.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">4. Returns and Refunds</h2>
            <p className="text-gray-700">
              Returns and refunds are handled directly by the vendors. Indazetu is not responsible for facilitating or mediating disputes related to returns or refunds.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">5. Payments</h2>
            <p className="text-gray-700">
              We accept payments on behalf of vendors and remit the collected amounts directly to them. We are not responsible for any discrepancies or disputes arising from payments.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">6. Store Credit</h2>
            <p className="text-gray-700">
              Any store credit issued by Indazetu is subject to specific terms and conditions that will be communicated at the time of issuance.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">7. Promotions</h2>
            <p className="text-gray-700">
              Promotions and discounts offered on the platform are subject to terms and conditions, which will be specified at the time of the promotion.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">8. Rules About Your Content</h2>
            <p className="text-gray-700">
              You are responsible for all content you upload or post on the platform. Content must not be illegal, offensive, or infringe on any third-party rights.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">9. Our Rights to Use Your Content</h2>
            <p className="text-gray-700">
              By posting content on Indazetu, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, and distribute your content in connection with the platform's operation.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">10. Use of Website and Mobile Applications</h2>
            <p className="text-gray-700">
              You agree to use the website and mobile applications only for lawful purposes and in a manner that does not infringe the rights of, or restrict or inhibit the use of the site by any third party.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">11. Copyright and Trademarks</h2>
            <p className="text-gray-700">
              All intellectual property rights on the platform are owned by or licensed to Indazetu. You may not use any content from the platform without our prior written permission.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">12. Data Privacy</h2>
            <p className="text-gray-700">
              Your personal information will be handled in accordance with our Privacy Policy.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">13. Due Diligence and Audit Rights</h2>
            <p className="text-gray-700">
              Indazetu reserves the right to conduct due diligence checks and audits as required to ensure compliance with these terms.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">14. Indazetu’s Role as a Marketplace</h2>
            <p className="text-gray-700">
              Indazetu is merely a platform for connecting buyers and sellers. We do not take part in the actual transactions and are not responsible for any aspect of the business conducted between users.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">15. Limitations and Exclusions of Liability</h2>
            <p className="text-gray-700">
              Indazetu is not liable for any indirect, incidental, or consequential damages arising from the use of our platform.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">16. Indemnification</h2>
            <p className="text-gray-700">
              You agree to indemnify and hold Indazetu harmless from any claims, losses, damages, or expenses arising from your use of the platform or violation of these terms.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">17. Breaches of These General Terms and Conditions</h2>
            <p className="text-gray-700">
              Indazetu reserves the right to take appropriate action, including suspension or termination of your account, if you breach these terms.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">18. Entire Agreement</h2>
            <p className="text-gray-700">
              This Agreement constitutes the entire agreement between you and Indazetu regarding the use of our services.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">19. Hierarchy</h2>
            <p className="text-gray-700">
              In the event of any conflict between these terms and any additional terms applicable to a specific service, the additional terms shall prevail.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">20. Variation</h2>
            <p className="text-gray-700">
              Indazetu may modify these terms from time to time. The latest version will be available on our website, and your continued use of the platform constitutes acceptance of any changes.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">21. No Waiver</h2>
            <p className="text-gray-700">
              Failure to enforce any right or provision of these terms does not constitute a waiver of such right or provision.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">22. Severability</h2>
            <p className="text-gray-700">
              If any provision of these terms is found to be invalid or unenforceable, the remaining provisions will continue to be valid and enforceable.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">23. Assignment</h2>
            <p className="text-gray-700">
              You may not assign or transfer your rights or obligations under these terms without Indazetu's prior written consent.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">24. Third Party Rights</h2>
            <p className="text-gray-700">
              These terms do not confer any rights on any third party.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">25. Law and Jurisdiction</h2>
            <p className="text-gray-700">
              These terms are governed by the laws of Kenya. Any disputes will be resolved in the courts of Kenya.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">26. Our Company Details and Notices</h2>
            <p className="text-gray-700">
              Indazetu DreamForgers Ltd.<br />
              Email: [Company Email]<br />
              Phone: [Company Phone Number]<br />
              Address: [Company Address]
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionsPage;
