import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { BsCartPlus } from "react-icons/bs";
import styles from "../../styles/styles";
import { AiOutlineHeart } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { removeFromWishlist } from "../../redux/actions/wishlist";
import { addTocart } from "../../redux/actions/cart";
import { toast } from "react-toastify";
import useShop from "../../hooks/useShop";



const Wishlist = ({ setOpenWishlist }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);



  // const addToCartHandler = (data) => {

  //   const isItemExists = cart && cart.find((i) => i._id === data._id);
  //   // const isItemExists = cart && cart.find((i) => i._id === id);
  //   if (isItemExists) {
  //     toast.error("Item already in cart!");
  //   } else {
  //     if (data.stock < 1) {
  //       toast.error("Product stock limited!");
  //     } else if (cart.length > 0 && cart[0]?.currency !== cart[0].currency) {
  //       toast.error("Cant Add Different Currencies to Cart");
  //     }
  //     else {

  //       const cartData = { ...data, qty: 1, currency: cart?.currency };
  //       dispatch(addTocart(cartData));
  //       setOpenWishlist(false);
  //       toast.success("Item added to cart successfully!");
  //     }
  //   }
  // }

  // Define state to store selected range for each item in the wishlist
  const [selectedRange, setSelectedRange] = useState({});

  // Function to handle range change
  const handleRangeChange = (id) => (e) => {
    const range = e.target.value;
    setSelectedRange((prevRanges) => ({
      ...prevRanges,
      [id]: range,
    }));
  };

  // Function to render quantity range options
  const renderRangeOptions = (data) => {
    return data?.priceInput?.map((range, index) => (
      <option key={index} value={index}>
        {`${range.min} - ${range.max}`} {range.metric} @<small>{data?.currency}.</small>{range.discountPrice ? range.discountPrice : range.price}
      </option>
    ));
  };

  // Return part where CartSingle component is used
  return (
    <div className="fixed top-0 left-0 w-full bg-[#0000004b] h-screen z-10">
      <div className="fixed top-0 right-0 h-full w-[80%] overflow-y-scroll 800px:w-[25%] bg-white flex flex-col justify-between shadow-sm">
        {wishlist && wishlist.length === 0 ? (
          <div className="w-full h-screen flex items-center justify-center">
            <div className="flex w-full justify-end pt-5 pr-5 fixed top-3 right-3">
              <RxCross1
                size={25}
                className="cursor-pointer"
                onClick={() => setOpenWishlist(false)}
              />
            </div>
            <h5>Wishlist Items is empty!</h5>
          </div>
        ) : (
          <>
            <div>
              <div className="flex w-full justify-end pt-5 pr-5">
                <RxCross1
                  size={25}
                  className="cursor-pointer"
                  onClick={() => setOpenWishlist(false)}
                />
              </div>
              {/* Item length */}
              <div className="flex p-4">
                <AiOutlineHeart size={25} />
                <h5 className="pl-2 text-[20px] font-[500]">
                  {wishlist && wishlist.length} items
                </h5>
              </div>

              {/* Cart Single Items */}
              <div className="w-full border-t">
                {wishlist &&
                  wishlist.map((i, index) => (
                    <CartSingle
                      key={index}
                      data={i}
                      selectedRange={selectedRange}
                      handleRangeChange={handleRangeChange}
                      renderRangeOptions={renderRangeOptions}
                      cart={cart}
                    //removeFromWishlistHandler={removeFromWishlistHandler}
                    //addToCartHandler={addToCartHandler}
                    />
                  ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
const CartSingle = ({ key, data, selectedRange, handleRangeChange, renderRangeOptions, cart }) => {

  const { shop, loading, error } = useShop(data.shopId);
  const dispatch = useDispatch();
  const [cartType, setCartType] = useState("product");
  const [selectedRangeIndex, setSelectedRangeIndex] = useState(data.selectedRangeIndex || 0);
 
  const removeFromWishlistHandler = (data) => {
    dispatch(removeFromWishlist(data));
  };

  const addToCartHandler = (data) => {
    let newData = { ...data };
    const isItemExists = cart && cart.find((i) => i._id === data._id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      newData.type = cartType;
      newData.selectedRange = selectedRange;
      newData.selectedRangeIndex = selectedRangeIndex;
      console.log("already", newData);
      if (newData.stock < 1) {
        toast.error("Product stock limited!");
      } else if (cart.length > 0 && shop?.shop?.currency !== cart[0]?.currency) {
        toast.error("Cant Add Different Currencies to Cart");
      } else {
        const cartData = { ...newData, qty: 1, currency: shop?.shop?.currency };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };
  return (
    <div className="border-b p-4">
      <div className="w-full 800px:flex items-center">
        <RxCross1
          className="cursor-pointer 800px:mb-['unset'] 800px:ml-['unset'] mb-2 ml-2"
          onClick={() => removeFromWishlistHandler(data)}
        />
        <img
          src={`${data?.images[0]?.url}`}
          alt=""
          className="w-[130px] h-min ml-2 mr-2 rounded-[5px]"
        />
        <div className="pl-[5px]">
          <h1>{data?.name}</h1>
          <select
            value={selectedRange[data._id] || ''}
            onChange={handleRangeChange(data._id)}
            className="border border-gray-300 rounded-md px-4 py-2"
          >
            <option value="">Select Range</option>
            {renderRangeOptions(data)}
          </select>
          <h4 className="font-[400] text-[15px] text-[#00000082]">
            {selectedRange[data._id] !== undefined && data?.priceInput[selectedRange[data._id]] ? "@ " + data.currency + '. ' + (data?.priceInput[selectedRange[data._id]]?.discountPrice || data?.priceInput[selectedRange[data._id]]?.price) : null}
          </h4>
          <h4 className="font-[600] text-[17px] pt-[3px] text-[#d02222] font-Roboto">
            {/* Add additional text or elements here if needed */}
          </h4>
          <div
            className="flex items-center space-x-2 bg-orange-400 cursor-pointer hover:bg-gray-300 p-2 text-white !rounded !h-9"
            onClick={() => addToCartHandler(data)}
          >
            <BsCartPlus size={20} title="Add to cart" />
            <span className="text-sm font-bold">Add To Cart</span>
          </div>
        </div>
      </div>
    </div>
  );
};


export default Wishlist;
