import React, { useState } from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import SuspendAndTerminate from "../components/Profile/SuspendAndTerminate";


const SuspendAndTerminatePage = () => {
  return (
    <div>
      <Header activeHeading={5} />
      <SuspendAndTerminate />
      <Footer />
    </div>
  );
};



export default SuspendAndTerminatePage;
