// MessageList.js
import React, { useEffect, useRef, useCallback } from 'react';
import { formatDistanceToNow } from "date-fns";
import { useState } from 'react';
import { deleteDoc, updateDoc, doc } from 'firebase/firestore';
import EditForm from './EditMessage';
import db from '../../firebase';
import MessageItem from './MessageItem';
import useLoadMoreConversation from '../../hooks/loadMoreConversations';

const MessageList = ({ messages = {}, hasMore, participants }) => {
  const [editingMessage, setEditingMessage] = useState(null);
  const [confirmDeleteMessage, setConfirmDeleteMessage] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [sortedMessages, setSortedMessages] = useState([]);
  const [currentConversationId, setCurrentConversationId] = useState(null);
  const [conversations, loadMoreConversations] = useLoadMoreConversation(participants, messages.messages?.length - 1 || 0);

  const messagesEndRef = useRef(null);

  useEffect(() => {
    // Sort messages whenever the messages prop changes
    if (messages.messages) {
      const sorted = messages.messages.slice().sort((a, b) => a?.createdAt?.toMillis() - b?.createdAt?.toMillis());
      setSortedMessages(sorted);
    }
  }, [messages]);

  const handleEdit = (message) => {
    setEditingMessage(message);
  };

  const handleDelete = async (message) => {
    try {
      if (window.confirm('Are you sure you want to delete this message?')) {
        await deleteDoc(doc(db, 'messages', message.id));
        console.log('Message deleted successfully:', message.id);
      }
    } catch (error) {
      console.error('Error deleting message:', error);
    }
  };

  const handleSubmitEdit = async (editedMessage) => {
    try {
      await updateDoc(doc(db, 'messages', editedMessage.id), {
        text: editedMessage.text,
      });
      console.log('Message edited successfully:', editedMessage.id);
      setEditingMessage(null);
    } catch (error) {
      console.error('Error editing message:', error);
    }
  };

  const handleCancelEdit = () => {
    setEditingMessage(null);
  };

  const handleLoadMoreClick = () => {
    setButtonClicked(true);
    loadMoreConversations();
  };

  useEffect(() => {
    if (buttonClicked && conversations.participants === participants) {
      // Filter out duplicates from the loaded conversations
      const uniqueMessages = conversations.reduce((acc, conversation) => {
        const newMessages = conversation.messages.filter((message) => (
          !sortedMessages.some((existingMessage) => existingMessage.id === message.id)
        ));
        return [...acc, ...newMessages];
      }, []);

      // Update sortedMessages with the new messages
      setSortedMessages((prevSortedMessages) => [...prevSortedMessages, ...uniqueMessages]);

      // Reset buttonClicked after updating sortedMessages
      setButtonClicked(false);
    }
  }, [buttonClicked, conversations, participants, sortedMessages]);

  return (
    <div className="flex flex-col gap-4">
      {(sortedMessages || []).map((message, index) => (
        <div key={message.id} className="flex flex-col items-start">
          {editingMessage === message ? (
            <EditForm
              message={message}
              onSubmit={handleSubmitEdit}
              onCancel={handleCancelEdit}
            />
          ) : (
            <MessageItem
              key={message.id}
              message={message}
              isLast={index === sortedMessages.length - 1}
              handleEdit={handleEdit}
              setConfirmDeleteMessage={setConfirmDeleteMessage}
            />
          )}
          {confirmDeleteMessage === message && (
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="bg-white rounded-lg p-6 max-w-md w-full">
                <p className="text-lg font-semibold mb-4">Are you sure you want to delete this message?</p>
                <div className="flex justify-end">
                  <button className="mr-2 px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300" onClick={() => setConfirmDeleteMessage(null)}>Cancel</button>
                  <button className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600" onClick={() => { handleDelete(message); setConfirmDeleteMessage(null); }}>Delete</button>
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
      <div ref={messagesEndRef} />
      {/* {hasMore && (
        <div className="flex justify-center mt-4">
          <button onClick={handleLoadMoreClick} className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
            Load More
          </button>
        </div>
      )} */}
    </div>
  );
};

export default MessageList;
