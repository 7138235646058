
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { server } from '../../server';
import { useSelector } from 'react-redux';
import { FaTrashAlt, FaPauseCircle } from 'react-icons/fa';


const ShopSuspendAndTerminate = () => {
  const [reason, setReason] = useState('');
  const [password, setPassword] = useState('');
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [showSuspendPrompt, setShowSuspendPrompt] = useState(false);
  const navigate = useNavigate();
  const { seller } = useSelector((state) => state.seller);

  const handleDelete = async () => {
    try {
      // Reauthenticate seller
      await axios.post(`${server}/shop/reauthenticate`, { sellerId: seller._id, password: password });

      // Send delete request
      await axios.delete(`${server}/shop/delete`, {
        data: {
          reason: reason,
          sellerId: seller._id,
        },
      });

      alert('Account deleted successfully');
    
      navigate('/'); // Redirect to home page after deletion
      window.location.reload();

    } catch (error) {
      alert(error.response.data.message);
    }
  };

  const handleSuspend = async () => {
    try {
      // Reauthenticate seller
      await axios.post(`${server}/shop/reauthenticate`, { sellerId: seller._id, password: password });

      // Send suspend request
      await axios.put(`${server}/shop/suspend`, {
        reason: reason,
        sellerId: seller._id,
      });

      alert('Account suspended successfully');
      navigate('/'); // Redirect to home page after suspension
      window.location.reload();
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10 mb-10 p-6 bg-white rounded-md shadow-md">
      <h2 className="text-2xl font-semibold text-gray-800 text-center">Manage Account</h2>
      <p className="text-center text-red-600 mt-4">Account deactivation means to delete your account:</p>
      <p className="text-center text-gray-600 mt-2">You will not be able to log in to your profile anymore and all your account history will be deleted without the possibility to restore.</p>

      <div className="mt-6">
        <label htmlFor="reason" className="block text-sm font-medium text-gray-700">Why do you leave?</label>
        <select
          id="reason"
          name="reason"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          className="block w-full mt-1 bg-gray-100 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        >
          <option value="">Select a reason</option>
          <option value="Not satisfied with the service">Not satisfied with the service</option>
          <option value="Privacy concerns">Privacy concerns</option>
          <option value="Found a better alternative">Found a better alternative</option>
          <option value="Other">Other</option>
        </select>
      </div>

      <div className="mt-6">
        <label htmlFor="password" className="block text-sm font-medium text-gray-700">Confirm your password</label>
        <input
          type="password"
          id="password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="block w-full mt-1 bg-gray-100 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        />
      </div>

      <div className="mt-6 text-center">

        <button
          onClick={() => setShowSuspendPrompt(true)}
          className="px-4 py-2 ml-4 bg-yellow-600 text-white rounded-md shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring focus:ring-yellow-200"
        >
          <FaPauseCircle className="inline-block mr-2" />
          Suspend my account
        </button>

        <button
          onClick={() => setShowDeletePrompt(true)}
          className="px-4 mt-4 py-2 bg-red-600 text-white rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring focus:ring-red-200"
        >
          <FaTrashAlt className="inline-block mr-2" />
          Delete my account forever
        </button>



      </div>

      {showDeletePrompt && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-md shadow-md">
            <h3 className="text-lg font-semibold text-red-600">Are you sure?</h3>
            <p className="mt-2 text-gray-600">This action is irreversible. All your account data will be permanently deleted.</p>
            <div className="mt-4 flex justify-end">
              <button
                onClick={() => setShowDeletePrompt(false)}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md shadow-sm hover:bg-gray-400 focus:outline-none focus:ring focus:ring-gray-200"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 ml-4 bg-red-600 text-white rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring focus:ring-red-200"
              >
                Confirm Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {showSuspendPrompt && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-md shadow-md">
            <h3 className="text-lg font-semibold text-yellow-600">Are you sure?</h3>
            <p className="mt-2 text-gray-600">Your account will be suspended. You can reactivate it by contacting support.</p>
            <div className="mt-4 flex justify-end">
              <button
                onClick={() => setShowSuspendPrompt(false)}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md shadow-sm hover:bg-gray-400 focus:outline-none focus:ring focus:ring-gray-200"
              >
                Cancel
              </button>
              <button
                onClick={handleSuspend}
                className="px-4 py-2 ml-4 bg-yellow-600 text-white rounded-md shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring focus:ring-yellow-200"
              >
                Confirm Suspend
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShopSuspendAndTerminate;

