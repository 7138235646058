import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { server } from '../../server';

const ShopAdminImageList = ({shop}) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(`${server}/shop/images/${shop}`);
        
        setImages(response.data.data);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, []);

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Image List</h2>
      <div className="grid grid-cols-3 gap-4">
        {images.map((image) => (
          <img key={image.image._id} src={image.image.url} alt={image.image.public_id} className="w-full" />
        ))}
      </div>
    </div>
  );
};

export default ShopAdminImageList;
