import React, { useState } from "react";
import {
  AiOutlineArrowRight,
  AiOutlineArrowLeft,
  AiOutlineCamera,
  AiOutlineDelete,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { server } from "../../server";
import styles from "../../styles/styles";
import { DataGrid } from "@material-ui/data-grid";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { MdTrackChanges } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import {
  deleteUserAddress,
  loadUser,
  updatUserAddress,
  updateUserInformation,
} from "../../redux/actions/user";

import { useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { getAllOrdersOfUser } from "../../redux/actions/order";
import { kenyanCounties, kenyanCities } from "../Shop/kenyanCounties";
import PlacesAutocomplete from "react-places-autocomplete";
import Select from 'react-select';
import Loader from "../Layout/Loader";

const ProfileContent = ({ active }) => {
  const { user, error, successMessage } = useSelector((state) => state.user);
  const [name, setName] = useState(user && user.name);
  const [email, setEmail] = useState(user && user.email);
  const [phoneNumber, setPhoneNumber] = useState(user && user.phoneNumber);
  const [password, setPassword] = useState("");

  const [avatar, setAvatar] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: "clearErrors" });
    }
    if (successMessage) {
      toast.success(successMessage);
      dispatch({ type: "clearMessages" });
    }
  }, [error, successMessage]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateUserInformation(name, email, phoneNumber, password));
  };

  const handleImage = async (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatar(reader.result);
        axios
          .put(
            `${server}/user/update-avatar`,
            { avatar: reader.result },
            {
              withCredentials: true,
            }
          )
          .then((response) => {
            dispatch(loadUser());
            toast.success("avatar updated successfully!");
          })
          .catch((error) => {
            toast.error(error);
          });
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  return (
    <div className="w-full">
      {/* profile */}
      {active === 1 && (
        <>
          <div className="flex justify-center w-full">
            <div className="relative">
              <img
                src={`${user?.avatar?.url}`}
                className="w-[150px] h-[150px] rounded-full object-cover border-[3px] border-[#3ad132]"
                alt=""
              />
              <div className="w-[30px] h-[30px] bg-[#E3E9EE] rounded-full flex items-center justify-center cursor-pointer absolute bottom-[5px] right-[5px]">
                <input
                  type="file"
                  id="image"
                  className="hidden"
                  onChange={handleImage}
                />
                <label htmlFor="image">
                  <AiOutlineCamera />
                </label>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="w-full px-5">
            <form onSubmit={handleSubmit} aria-required={true}>
              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">Full Name</label>
                  <input
                    type="text"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">Email Address</label>
                  <input
                    type="text"
                    className={`${styles.input} !w-[95%] mb-1 800px:mb-0`}
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">Phone Number</label>
                  <input
                    type="number"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                
                </div>

                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">Enter your password</label>
                  <input
                    type="password"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
              <input
                className={`w-[250px] h-[40px] border border-[#3a24db] text-center text-[#3a24db] rounded-[3px] mt-8 cursor-pointer`}
                required
                value="Update"
                type="submit"
              />
            </form>
          </div>
        </>
      )}

      {/* order */}
      {active === 2 && (
        <div>
          <AllOrders />
        </div>
      )}

      {/* Refund */}
      {active === 3 && (
        <div>
          <AllRefundOrders />
        </div>
      )}

      {/* Track order */}
      {active === 5 && (
        <div>
          <TrackOrder />
        </div>
      )}

      {/* Change Password */}
      {active === 6 && (
        <div>
          <ChangePassword />
        </div>
      )}

      {/*  user Address */}
      {active === 7 && (
        <div>
          <Address />
        </div>
      )}
      {/*  user Address */}
      {active === 8 && (
        <div>
          <FollowList />
        </div>
      )}
    </div>
  );
};

const AllOrders = () => {
  const { user } = useSelector((state) => state.user);
  const { orders } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, []);

  const columns = [
    { field: "id", headerName: "Order ID", minWidth: 150, flex: 0.7 },

    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      flex: 0.7,
      cellClassName: (params) => {
        return params.getValue(params.id, "status") === "Delivered"
          ? "greenColor"
          : "redColor";
      },
    },
    {
      field: "itemsQty",
      headerName: "Items Qty",
      type: "number",
      minWidth: 130,
      flex: 0.7,
    },

    {
      field: "total",
      headerName: "Total",
      type: "number",
      minWidth: 130,
      flex: 0.8,
    },

    {
      field: " ",
      flex: 1,
      minWidth: 150,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/user/order/${params.id}`}>
              <Button>
                <AiOutlineArrowRight size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
  ];

  const row = [];

  orders &&
    orders.forEach((item) => {
      row.push({
        id: item._id,
        itemsQty: item.cart.length,
        total: "US$ " + item.totalPrice,
        status: item.status,
      });
    });

  return (
    <div className="pl-8 pt-1">
      <DataGrid
        rows={row}
        columns={columns}
        pageSize={10}
        disableSelectionOnClick
        autoHeight
      />
    </div>
  );
};

const AllRefundOrders = () => {
  const { user } = useSelector((state) => state.user);
  const { orders } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, []);

  const eligibleOrders =
    orders && orders.filter((item) => item.status === "Processing refund");

  const columns = [
    { field: "id", headerName: "Order ID", minWidth: 150, flex: 0.7 },

    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      flex: 0.7,
      cellClassName: (params) => {
        return params.getValue(params.id, "status") === "Delivered"
          ? "greenColor"
          : "redColor";
      },
    },
    {
      field: "itemsQty",
      headerName: "Items Qty",
      type: "number",
      minWidth: 130,
      flex: 0.7,
    },

    {
      field: "total",
      headerName: "Total",
      type: "number",
      minWidth: 130,
      flex: 0.8,
    },

    {
      field: " ",
      flex: 1,
      minWidth: 150,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/user/order/${params.id}`}>
              <Button>
                <AiOutlineArrowRight size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
  ];

  const row = [];

  eligibleOrders &&
    eligibleOrders.forEach((item) => {
      row.push({
        id: item._id,
        itemsQty: item.cart.length,
        total: "US$ " + item.totalPrice,
        status: item.status,
      });
    });

  return (
    <div className="pl-8 pt-1">
      <DataGrid
        rows={row}
        columns={columns}
        pageSize={10}
        autoHeight
        disableSelectionOnClick
      />
    </div>
  );
};

const TrackOrder = () => {
  const { user } = useSelector((state) => state.user);
  const { orders } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, []);

  const columns = [
    { field: "id", headerName: "Order ID", minWidth: 150, flex: 0.7 },

    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      flex: 0.7,
      cellClassName: (params) => {
        return params.getValue(params.id, "status") === "Delivered"
          ? "greenColor"
          : "redColor";
      },
    },
    {
      field: "itemsQty",
      headerName: "Items Qty",
      type: "number",
      minWidth: 130,
      flex: 0.7,
    },

    {
      field: "total",
      headerName: "Total",
      type: "number",
      minWidth: 130,
      flex: 0.8,
    },

    {
      field: " ",
      flex: 1,
      minWidth: 150,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/user/track/order/${params.id}`}>
              <Button>
                <MdTrackChanges size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
  ];

  const row = [];

  orders &&
    orders.forEach((item) => {
      row.push({
        id: item._id,
        itemsQty: item.cart.length,
        total: "US$ " + item.totalPrice,
        status: item.status,
      });
    });

  return (
    <div className="pl-8 pt-1">
      <DataGrid
        rows={row}
        columns={columns}
        pageSize={10}
        disableSelectionOnClick
        autoHeight
      />
    </div>
  );
};

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const passwordChangeHandler = async (e) => {
    e.preventDefault();

    await axios
      .put(
        `${server}/user/update-user-password`,
        { oldPassword, newPassword, confirmPassword },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success(res.data.success);
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };
  return (
    <div className="w-full px-5">
      <h1 className="block text-[25px] text-center font-[600] text-[#000000ba] pb-2">
        Change Password
      </h1>
      <div className="w-full">
        <form
          aria-required
          onSubmit={passwordChangeHandler}
          className="flex flex-col items-center"
        >
          <div className=" w-[100%] 800px:w-[50%] mt-5">
            <label className="block pb-2">Enter your old password</label>
            <input
              type="password"
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              required
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </div>
          <div className=" w-[100%] 800px:w-[50%] mt-2">
            <label className="block pb-2">Enter your new password</label>
            <input
              type="password"
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              required
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className=" w-[100%] 800px:w-[50%] mt-2">
            <label className="block pb-2">Enter your confirm password</label>
            <input
              type="password"
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              required
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <input
              className={`w-[95%] h-[40px] border border-[#3a24db] text-center text-[#3a24db] rounded-[3px] mt-8 cursor-pointer`}
              required
              value="Update"
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

const FollowList = () => {
  const { user } = useSelector((state) => state.user);
  const [followList, setFollowList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${server}/shop/followed-shops/${user._id}`);

        const shopIds = response.data.followedShops;

        const shopPromises = shopIds.map(id =>
          axios.get(`${server}/shop/get-shop-info/${id}`)
        );

        const shopResponses = await Promise.all(shopPromises);


        const shops = shopResponses.map(res => res.data.shop);
        console.log('zimma4', shops);

        setFollowList(shops);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching follow info:', error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [user]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="container mx-auto p-4">
      <div className="flex items-center mb-4">
        <AiOutlineArrowLeft className="text-blue-500 text-2xl mr-2 cursor-pointer" />
        <h2 className="text-2xl font-bold">Followed Shops</h2>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {followList.length > 0 ? (
          followList.map((shop) => (
            <Link to={`/shop/${shop._id}`} key={shop._id} className="border p-4 rounded shadow-lg flex flex-col items-center hover:shadow-xl transition-shadow duration-200">
              <img src={shop.avatar.url} alt={shop.name} className="w-32 h-32 object-cover rounded-full mb-4" />
              <h3 className="text-xl font-semibold">{shop.name}</h3>
              <p className="text-gray-600">{shop.email}</p>
              <p className="text-gray-600 text-sm">{shop.address}</p>
            </Link>
          ))
        ) : (
          <p>No followed shops found</p>
        )}
      </div>
    </div>
  );
}
const Address = () => {
  const [open, setOpen] = useState(false);
  const [selectedCounty, setSelectedCounty] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [primaryPhoneNumber, setPrimaryPhoneNumber] = useState("");
  const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState("");
  const [addressType, setAddressType] = useState("");

  const [additionalInformation, setAdditionalInformation] = useState('');


  const handleSelect = async (address) => {
    setAddress(address);
    // Additional logic to handle coordinates if needed
  };

  const addNewAddress = () => {
    // Additional logic if needed for adding multiple addresses
  };
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const addressTypeData = [
    {
      name: "Other",
    },
    {
      name: "Home",
    },
    {
      name: "Office",
    },
  ];
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('b4', selectedCounty,
      selectedCity,
      address,
      primaryPhoneNumber,
      secondaryPhoneNumber,
      addressType,
      additionalInformation)
    if (
      addressType === "" ||
      address === "" ||
      selectedCounty === "" ||
      selectedCity === "" ||
      primaryPhoneNumber === ""
      // additionalInformation === "" 
    ) {
      toast.error("Please fill all the required fields!");
    } else {
      dispatch(
        updatUserAddress(
          selectedCounty,
          selectedCity,
          address,
          primaryPhoneNumber,
          secondaryPhoneNumber,
          addressType,
          additionalInformation // Include additional info in the dispatch call
        )
      );
      setOpen(false);
      setSelectedCounty("");
      setSelectedCity("");
      setAddress("");
      setPrimaryPhoneNumber("");
      setSecondaryPhoneNumber("");
      setAddressType("");
      setAdditionalInformation(""); // Clear additional info after submission
    }
  };

  const handleDelete = (item) => {
    const id = item._id;
    dispatch(deleteUserAddress(id));
  };


  return (
    <div className="w-full px-5">
      {open && (
        <div className="fixed inset-0 bg-[#0000004b] flex items-center justify-center">
          <div className="w-[90%] md:w-[60%] lg:w-[35%] h-[80vh] bg-white rounded shadow relative overflow-y-scroll p-5">
            <div className="w-full flex justify-end p-3">
              <RxCross1
                size={30}
                className="cursor-pointer"
                onClick={() => setOpen(false)}
              />
            </div>
            <h1 className="text-center text-[25px] font-Poppins mb-4">
              Add New Address
            </h1>
            <form aria-required onSubmit={handleSubmit} className="w-full space-y-4">
              <div>
                <label className="block pb-2">County</label>
                <Select
                  options={kenyanCounties.map((county) => ({
                    value: county,
                    label: county,
                  }))}
                  value={{ value: selectedCounty, label: selectedCounty }}
                  onChange={(selectedOption) => {
                    setSelectedCounty(selectedOption.value);
                    setSelectedCity('');
                  }}
                  placeholder="Select your county"
                  className="w-full"
                />
              </div>
              <div>
                <label className="block pb-2">City</label>
                <Select
                  options={
                    selectedCounty
                      ? kenyanCities[selectedCounty].map((city) => ({
                        value: city,
                        label: city,
                      }))
                      : []
                  }
                  value={{ value: selectedCity, label: selectedCity }}
                  onChange={(selectedOption) => setSelectedCity(selectedOption.value)}
                  placeholder="Select your city"
                  className="w-full"
                />
              </div>
              <div>
                <label className="block pb-2">Set Your Address</label>
                <PlacesAutocomplete
                  value={address}
                  onChange={(address) => setAddress(address)}
                  onSelect={(address) => handleSelect(address)}
                  searchOptions={{
                    componentRestrictions: {
                      country: 'ke', // Limit to Kenya
                    },
                  }}
                  className="flex-grow"
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div className="flex-grow">
                      <input
                        {...getInputProps({
                          placeholder: 'Enter your address',
                          className: 'input w-full mr-2',
                        })}
                      />
                      <div>
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const style = {
                            backgroundColor: suggestion.active ? '#fafafa' : '#ffffff',
                            cursor: 'pointer',
                            padding: '0.5rem',
                          };
                          return (
                            <div {...getSuggestionItemProps(suggestion, { style })}>
                              {suggestion.description}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
              <div>
                <label className="block pb-2">Additional Information</label>
                <textarea
                  id="additionalInformation"
                  value={additionalInformation}
                  onChange={(e) => setAdditionalInformation(e.target.value)}
                  className="input resize-none h-20 w-full"
                  placeholder="Enter any additional information..."
                />
              </div>
              <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0">
                <div className="flex flex-col">
                  <label className="block pb-2">Primary Phone Number</label>
                  <div className="flex items-center">
                    <span className="mr-2">+254</span>
                    <input
                      type="tel"
                      className="input"
                      pattern="[0-9]{10}"
                      required
                      value={primaryPhoneNumber}
                      onChange={(e) => setPrimaryPhoneNumber(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex flex-col">
                  <label className="block pb-2">Secondary Phone Number</label>
                  <div className="flex items-center">
                    <span className="mr-2">+254</span>
                    <input
                      type="tel"
                      className="input"
                      pattern="[0-9]{10}"
                      required
                      value={secondaryPhoneNumber}
                      onChange={(e) => setSecondaryPhoneNumber(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div>
                <label className="block pb-2">Address Type</label>
                <select
                  value={addressType}
                  onChange={(e) => setAddressType(e.target.value)}
                  className="w-full border h-[40px] rounded-[5px]"
                >
                  <option value="" className="block border pb-2">
                    Choose your Address Type
                  </option>
                  {addressTypeData.map((item) => (
                    <option
                      key={item.name}
                      value={item.name}
                      className="block pb-2"
                    >
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <input
                  type="submit"
                  className="input mt-5 bg-blue-500 text-white py-2 px-4 rounded-md cursor-pointer hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 w-full"
                  required
                  readOnly
                />

              </div>

            </form>
          </div>
        </div>
      )}
      <div>
        <h1 className="text-[25px] font-[600] text-[#000000ba] pb-2 mb-2">
          My Addresses
        </h1>
        <br />
        <div className="mt-3">
          <div
            className={`${styles.button} bg-blue-500 text-white py-2 px-4 rounded-md cursor-pointer`}
            onClick={() => setOpen(true)}
          >
            Add New
          </div>
          <div className="button !rounded-md" onClick={() => setOpen(true)}>
            {user &&
              user.addresses.map((item, index) => (
                <div
                  className="w-full bg-white rounded-lg shadow-md flex flex-col md:flex-row items-center justify-between mb-5 p-4"
                  key={index}
                >
                  <div className="flex flex-col justify-center">
                    <h5 className="font-semibold text-lg">{item.addressType}</h5>
                    <p className="text-sm text-gray-500">{item.address}</p>
                  </div>
                  <div className="flex flex-col justify-center md:border-l md:border-gray-200 md:pl-4 mt-2 md:mt-0">
                    <p className="text-sm text-gray-500">Phone: {user.phoneNumber}</p>
                    <p className="text-sm text-gray-500">Primary: {item.primaryPhoneNumber}</p>
                    <p className="text-sm text-gray-500">Secondary: {item.secondaryPhoneNumber}</p>
                  </div>
                  <div className="flex items-center mt-2 md:mt-0">
                    <AiOutlineDelete
                      size={20}
                      className="cursor-pointer text-red-500 hover:text-red-700"
                      onClick={() => handleDelete(item)}
                    />
                  </div>
                </div>
              ))}
            {user && user.addresses.length === 0 && (
              <h5 className="text-center pt-8 text-[18px] text-gray-600">
                You do not have any saved addresses!
              </h5>
            )}
          </div>
        </div>
        <br />
      </div>
    </div>
  );
};

export default ProfileContent;
