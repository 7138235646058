import React from 'react';
import { Helmet } from 'react-helmet';

const SuspendPage = () => {
    return (
        <div className="max-w-md mx-auto mt-10 mb-10 p-6 bg-white rounded-md shadow-md">
            <Helmet>
                <title>Suspension Page - Indazetu</title>
                <meta name="description" content="Account Suspended." />
            </Helmet>
            <h2 className="text-2xl font-semibold text-gray-800 text-center">Account Suspended</h2>
            <p className="text-center text-gray-600 mt-4">Your account has been suspended. Please contact support for more information.</p>
            <div className="mt-6 text-center px-6 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-500">
                <p className=' mb-2'>Email: <a  className="text-blue-600 hover:underline" href="mailto:privacy@indazetu.com"> support@indazetu.com</a></p>
                <p >Phone: +254 703 160 688</p>
            </div>
        </div>
    );
};

export default SuspendPage;
