import React, { useState } from 'react';
import { FaPlus, FaTrash } from 'react-icons/fa';
import Select from 'react-select'


const PackagingInput = ({ onPackagingChange,currency }) => {

  const [packagingEntries, setPackagingEntries] = useState([{ type: '', size: '', price: '', min: '', max: '',metric:'' }]);


  const options = [
    { value: 'kilogram', label: 'Kg' },
    { value: 'litre', label: 'Litres' },
    { value: 'piece', label: 'Pieces' },
    { value: 'gram', label: 'Grams' },
    { value: 'millilitre', label: 'Millilitres' },
    { value: 'ton', label: 'Tons' },
    { value: 'cubic_meter', label: 'Cubic Meters' },
    { value: 'cubic_centimeter', label: 'Cubic Centimeters' },
    { value: 'cubic_feet', label: 'Cubic Feet' },
    { value: 'pound', label: 'Pounds' },
    { value: 'ounce', label: 'Ounces' },
    { value: 'gallon', label: 'Gallons' },
    { value: 'quart', label: 'Quarts' },
    { value: 'barrel', label: 'Barrels' },
    { value: 'bundle', label: 'Bundles' },
    { value: 'dozen', label: 'Dozens' },
    { value: 'box', label: 'Boxes' },
    { value: 'pallet', label: 'Pallets' },
  ];
  

  const handleChange = (index, field, value) => {
    console.log('gaza',value);
    const newEntries = [...packagingEntries];
    newEntries[index][field] = value;
    setPackagingEntries(newEntries);
    //onPackagingChange(newEntries);
    const lastRange = newEntries[newEntries.length - 1];
    const allFieldsPopulated = Object.values(lastRange).every(val => val !== '');
    if (allFieldsPopulated) {
      onPackagingChange(newEntries);
    }
   
  };
  const handleRangeChange = (selectedOption, index) => {
    const newEntries = [...packagingEntries];
    newEntries[index].metric = selectedOption.value;
    console.log('gaza2',selectedOption.value);
    setPackagingEntries(newEntries);
  };




  const addEntry = () => {
    setPackagingEntries([...packagingEntries, { type: '', size: '', price: '', min: '', max: '',metric:'' }]);
  };

  const removeEntry = (index) => {
    const newEntries = [...packagingEntries];
    newEntries.splice(index, 1);
    setPackagingEntries(newEntries);
  };

  return (
    <div>
      {packagingEntries.map((entry, index) => (
        <div key={index} className="flex flex-col md:flex-row items-center mb-2">
          <div className="flex flex-col md:flex-row items-center mb-2 md:mb-0">
            <Select
              options={options}
              value={options.find(option => option.value === entry.metric)}
              onChange={(selectedOption) => handleRangeChange(selectedOption, index)}
              className="mb-2 md:mb-0 md:mr-2 w-full md:w-auto"
            />
            <input
              type="text"
              value={entry.type}
              onChange={(e) => handleChange(index, 'type', e.target.value)}
              placeholder="e.g Wooden Box"
              className="mb-2 md:mb-0 md:mr-2 px-3 py-1 border border-gray-300 rounded w-full md:w-36"
            />
            <input
              type="number"
              value={entry.min}
              onChange={(e) => handleChange(index, 'min', e.target.value)}
              placeholder="Min Qty"
              className="mb-2 md:mb-0 md:mr-2 px-3 py-1 border border-gray-300 rounded w-full md:w-24"
            />
            <div className="bg-gray-600 w-6 h-10 flex justify-center items-center text-white mb-2 md:mb-0">-</div>
            <input
              type="number"
              value={entry.max}
              onChange={(e) => handleChange(index, 'max', e.target.value)}
              placeholder="Max Qty"
              className="mb-2 md:mb-0 md:ml-2 px-3 py-1 border border-gray-300 rounded w-full md:w-24"
            />
            <input
              type="text"
              value={entry.size}
              onChange={(e) => handleChange(index, 'size', e.target.value)}
              placeholder="Size"
              className="mb-2 md:mb-0 md:mr-2 px-3 py-1 border border-gray-300 rounded w-full md:w-32"
            />
            <span className="flex justify-center items-center text-green-600 font-bold mb-2 md:mb-0">@ {currency}</span>
            <input
              type="number"
              value={entry.price}
              onChange={(e) => handleChange(index, 'price', e.target.value)}
              placeholder="Price"
              className="mb-2 md:mb-0 md:ml-1 px-3 py-1 border border-gray-300 rounded w-full md:w-32"
            />
          </div>
          <div className="flex items-center mb-2 md:mb-0">
            {index !== 0 && (
              <button
                type="button"
                onClick={() => removeEntry(index)}
                className="ml-1 px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600"
              >
                <FaTrash />
              </button>
            )}
            <button
              type="button"
              onClick={addEntry}
              className="ml-1 px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              <FaPlus />
            </button>
          </div>
        </div>
      ))}
    </div>
   );
};

export default PackagingInput;
