import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import CartData from "./CartData";
import ShippingInfo from "./ShippingInfo";

const Checkout = () => {
  const { user } = useSelector((state) => state.user);
  const { cart } = useSelector((state) => state.cart);
  const [county, setCounty] = useState("");
  const [primaryPhoneNumber, setPrimaryPhoneNumber] = useState("");
  const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState("");
  const [city, setCity] = useState("");
  const [userInfo, setUserInfo] = useState(false);
  const [address, setAddress] = useState("");
  const [addressType, setAddressType] = useState("");
  const [additionalInformation, setAdditionalInformation] = useState("");
  const [shop, setShop] = useState();
  const [couponCode, setCouponCode] = useState([]);
  const [couponCodeData, setCouponCodeData] = useState(null);
  const [discountPrice, setDiscountPrice] = useState({});
  const [settings, setSettings] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectedShippingCosts, setSelectedShippingCosts] = useState({});
  const [shippingProducts, setShippingProducts] = useState([]);
  const [option, setOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const [shippingCost, setShippingCost] = useState(null);
  const [couponCodeKey, setCouponCodeKey] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState('');
  const [selectedProductPrice, setSelectedProductPrice] = useState([])
  const [couponCodes, setCouponCodes] = useState({});
  const [deliveryType, setDeliveryType] = useState({});

  const [packageTotals, setPackageTotals] = useState([]);
  //const [cumulativePackagingCost, setCumulativePackagingCost] = useState(0);
  const [selectionMatches, setSelectionMatches] = useState(null);
  const [addressError, setAddressError] = useState(null);
  const [addressTypeError, setAddressTypeError] = useState(null);
  const [countyError, setCountyError] = useState(null);
  const [cityError, setCityError] = useState(null);
  const [couponDiscount, setCouponDiscount] = useState({});
  const [cumulativeCouponDiscount, setCumulativeCouponDiscount] = useState(0);
  const [shops, setShops] = useState(null);
  const [selectedPackaging, setSelectedPackaging] = useState({});
  const [selectedPackagingTotal, setSelectedPackagingTotal] = useState({});
  const currency = shops ? shops[0].currency : null;
  //let packageTotal;
  const navigate = useNavigate();

  useEffect(() => {
    // Calculate the cumulative discount whenever couponDiscount changes
    const totalDiscount = Object.values(couponDiscount).reduce((acc, discount) => acc + Number(discount), 0);
    setCumulativeCouponDiscount(totalDiscount);
  }, [couponDiscount]);

  const handleDiscountChange = (productId, discount) => {
    setCouponDiscount(prev => ({ ...prev, [productId]: discount }));
  };

  const handlePriceSelection = (price, productId) => {
    console.log('overload', productId);

    // Copy the current state
    const newPrices = [...selectedPrice];
    newPrices[0] = price; // Update the value for the specific shop

    // Extract the JSON string part
    const jsonString = newPrices[0].split('-')[3];

    // Verify the extracted string
    console.log('Extracted JSON string:', jsonString);

    try {
      // Parse the JSON string
      const jsonObject = JSON.parse(jsonString);
      console.log('Parsed JSON object:', jsonObject);

      // Ensure jsonObject is an array of objects
      if (Array.isArray(jsonObject) && jsonObject.length > 0) {
        // Extract the min and price values from the first object in the array
        const min = jsonObject[0].min;
        const productPrice = jsonObject[0].price;
        const discountPrice = jsonObject[0].discountPrice; // Avoid conflict with outer scope "price"

        // Update the state
        setSelectedPrice(discountPrice ? discountPrice : productPrice); // This might need to be reviewed as it conflicts with the parameter 'price'
        setSelectedProductPrice({ ...selectedProductPrice, [productId]:discountPrice ? discountPrice : productPrice });

        console.log('ProdPrice:', productPrice);
        console.log('Min:', min);
        console.log('Price:', productPrice);
      } else {
        console.error('Parsed JSON is not an array or is empty:', jsonObject);
      }
    } catch (error) {
      console.error('Error parsing JSON:', error);
    }
  };


  // const handleDeliveryType = (value , index) => {
  //   const newType = [...deliveryType]; // Copy the current state
  //   newType[index] = value; // Update the value for the specific shop

  //   setDeliveryType(newType);
  // }
  const handleDeliveryType = (type, productId) => {
    setDeliveryType(prevState => ({
      ...prevState,
      [productId]: type
    }));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const getShippingCost = (selectedOption) => {
    console.log('selectedop23', selectedOption);
    console.log('selectedop', settings);
    //console.log('selectedop2', cart[0].qty, cart[0]._id);
    /// shop //products //source destination
    if (!selectedOption || !settings.length) return 'N/A';

    let matchingData = null;

    // Flatten the settings array
    const flattenedSettings = settings.flat();

    console.log("Accumulator", flattenedSettings);
    const combinedSettings = Array.from(
      new Set(
        flattenedSettings.reduce((acc, curr) => {
          console.log("Accumulator", acc, flattenedSettings);
          // Check if the current setting already exists in the accumulator based on _id and updatedAt
          const exists = acc.some(
            (setting) =>
              setting._id === curr._id && setting.updatedAt === curr.updatedAt
          );
          console.log("exists", exists);
          // If the current setting does not exist, add it to the accumulator
          if (!exists) {
            // Check if shippingInputs is not empty before accessing its first element
            if (
              curr.shippingInputs &&
              curr.shippingInputs.length > 0 &&
              curr.shippingInputs[0].allProducts
            ) {
              // Filter out products that are not in the cart
              const filteredProducts = curr.shippingInputs[0].allProducts.filter(
                (product) =>
                  cart.some((cartProduct) => cartProduct._id === product.value)
              );
              // If there are filtered products, add the current setting with the filtered products to the accumulator
              console.log("ganji2", filteredProducts);
              if (filteredProducts.length > 0) {
                acc.push({
                  ...curr,
                  shippingInputs: [
                    {
                      ...curr.shippingInputs[0],
                      allProducts: filteredProducts,
                    },
                  ],
                });
              }
            }
          }

          return acc;
        }, [])
      )
    );

    console.log('selectedop4', combinedSettings);

    combinedSettings.forEach((shippingData) => {
      console.log('shipdata', shippingData);
      const source = shippingData?.shippingInputs[0]?.source;
      const destination = shippingData?.shippingInputs[0]?.destination;

      console.log('suke', selectedOption);
      console.log('suke2', selectedOption?.split(' - ')[1]);

      if (source === selectedOption?.split(' - ')[0] && destination === selectedOption?.split(' - ')[1]) {
        matchingData = shippingData?.shippingInputs[0];
      }
    });

    console.log('selectedop3', matchingData);
    return matchingData || 'N/A';
  };

  // const handleSelectChange = (event) => {
  //   console.log('agreements', event.target.value);
  //   setOption(event.target.value);
  //   handleShippingCost(event.target.value);
  // };

  // const handleShippingCost = (option) => {
  //   console.log('optionex', option);
  //   const selectedShippingCost = getShippingCost(option);
  //   //matchingData?.shippingCost?.[0]?.price
  //   setSelectedShippingCosts(selectedShippingCost)
  //   console.log('optionex2', selectedShippingCost);
  //   setShippingCost(prevState => ({ ...prevState, cost: Number(selectedShippingCost[0]?.price) }));
  // }
  // Function to handle select change
  const handleSelectChange = (event, productId) => {
    const { value } = event.target;
    console.log('values', value);
    setSelectedOptions({ ...selectedOptions, [productId]: value });
    // Optionally, update shipping cost based on the selected destination
    updateShippingCost(productId, value);
  };
  // Function to update shipping cost based on selected destination
  const updateShippingCost = (productId, selectedOption) => {
    // Get shipping cost based on selected option
    console.log('costs', productId, selectedOption);
    const shippingCost = getShippingCost(selectedOption);
    console.log('shippingCost', shippingCost);
    setSelectedShippingCosts({ ...selectedShippingCosts, [productId]: shippingCost });
  };

  const calculatePackaging = (packagingOptions, productQuantity) => {
    console.log('calculate', packagingOptions, productQuantity);
    let totalCost = 0;
    let packagingQuantities = {};

    // Iterate through each packaging option
    for (const option of packagingOptions) {
      // Ensure min and max values are defined and are numbers
      const min = parseInt(option.min, 10) || 0;
      const max = parseInt(option.max, 10) || 1;
      const price = parseFloat(option.price) || 0;

      // Calculate quantity needed based on product quantity and packaging minimum
      const quantityNeeded = Math.max(0, Math.ceil((productQuantity - min) / max));

      // Calculate total cost for this packaging option
      const cost = quantityNeeded * price;

      // Update total cost
      totalCost += cost;

      // Update packaging quantities object
      packagingQuantities[option.type] = {
        quantity: quantityNeeded,
        cost: cost
      };
    }

    console.log('Packaging Quantities:', packagingQuantities);
    console.log('Total Cost:', totalCost);

    return { packagingQuantities, totalCost };
  };


  useEffect(() => {


    const FetchData = async () => {
      try {
        // Check if cart is empty or invalid
        if (!cart || !Array.isArray(cart) || cart.length === 0) {
          console.warn('Cart is empty or invalid. Skipping settings fetch.');
          return;
        }


        console.log('fetched', cart)
        const shopIds = [];
        cart.map((cartItem) => (
          shopIds.push(cartItem.shopId)
        ));

        console.log('stepup', shopIds);

        const response = await axios.post(
          `${server}/shop/get-shops`,
          shopIds,
          {
            headers: {
              "Content-Type": "application/json"
            },
          }
        );
        const shopsResponse = response.data.shops;
        const fetchedShops = shopsResponse;

        // setShops(response.data.shops);
        setLoading(false);
        console.log('shops', response.data.shops);
        // Assuming shop details don't depend on settings, set the state
        //setShop(fetchedShops);
        // Extract shopId and cartIds efficiently using map and destructuring
        const cartData = cart.map((cartItem) => ({
          shopId: cartItem.shopId,
          cartId: cartItem._id,
        }));

        try {
          setLoading(true);
          console.log('shops 2', cartData);
          const response = await axios.post(`${server}/shop/cart-shipping-setting`, { cartData });
          const data = response.data;
          console.log('shops 3', data);
          if (data.success) {
            const setting = data.data;
            setSettings(setting);
            const uniqueSourceDests = getUniqueSourceDestinations(setting);
            const selectOption = createSelectOptions(uniqueSourceDests, setting);
            setSelectedOptions(selectOption);
          } else {
            console.warn('Shipping settings not found for any product.');
          }

          setLoading(false);
        } catch (error) {
          console.error('Error fetching settings:', error);
          setLoading(false);
          // Handle error gracefully for the overall operation
        }
        // Calculate total cost per shop and store them in an array
        const calculateShopTotals = () => {
          const shopDetails = cart.reduce((acc, cartItem) => {
            const existingShop = acc.find((shop) => shop._id === cartItem.shopId);
            const itemTotal = cartItem.qty * (cartItem.priceInput[0]?.discountPrice || cartItem.priceInput[0]?.price);

            if (existingShop) {
              existingShop.total += itemTotal;
              existingShop.products.push(cartItem);
            } else {
              const shopData = fetchedShops.find((fetchedShop) => fetchedShop._id === cartItem.shopId);
              if (shopData) {
                acc.push({
                  ...shopData,
                  total: itemTotal,
                  products: [cartItem],
                });
              }
            }

            return acc;
          }, []);

          console.log('Shop Details:', shopDetails);
          setShops(shopDetails); // Ensure to update the state with the calculated shop details
        };


        calculateShopTotals();
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error gracefully, display error message to the user, retry fetch, etc.
      }
    };

    FetchData(); // Call the function to fetch data on component mount
  }, [cart]); // Dependency on cart to refetch settings and calculate totals
  //console.log('select', selectedOptions[0].key);
  console.log('calculateShopTotals', selectedOptions);

  const getUniqueSourceDestinations = React.useMemo(() => {
    // Function body for getUniqueSourceDestinations
    //let currentSource = { lat: -1.2892786, lng: 36.7869311 }; // Set the current source here or get it from somewhere else
    return (data) => {
      console.log('tuniqu', data);
      const sourceDestinations = new Set();
      data?.forEach((shippingData) => {
        console.log('tunique2', shippingData);
        [shippingData]?.forEach((shippingItem) => {
          console.log('tunique3', shippingItem);
          const { source, destination, deliveryType, shippingCost, estimatedTime } = shippingItem.shippingInputs[0]; // Assuming there's only one input
          console.log('denver', shippingCost);
          // console.log('denver', source.coordinates, currentSource);
          // Check if source is equal to currentSource
          if (JSON.stringify(source.coordinates)) {
            // Combine source and destination for uniqueness
            const combined = `${source.location} - ${destination.location} - ${deliveryType} - ${JSON.stringify(shippingCost)} - ${estimatedTime}`;
            sourceDestinations.add(combined); // Add unique combinations to the Set
          }
        });
      });

      return Array.from(sourceDestinations).sort(); // Convert Set to array and sort alphabetically
    };
  }, []); // Empty dependency array, function doesn't rely on changing values

  const createSelectOptions = (sourceDestinations, settings) => {
    console.log('source0', settings)

    console.log('source', sourceDestinations)
    return sourceDestinations?.map((combined) => {
      const [combinedSource, combinedDestination] = combined.split('-');
      let matchingData = null;
      let productId = null;
      console.log('source2', settings)
      settings?.forEach((shippingData) => {
        console.log('ships1', shippingData);
        const source = shippingData?.shippingInputs[0]?.source;

        const destination = shippingData?.shippingInputs[0]?.destination;
        console.log('ships', source, destination);
        productId = shippingData?._id;
        console.log('ships2', source.location, combinedSource.trim())
        if (JSON.stringify(source.location) === JSON.stringify(combinedSource.trim()) && JSON.stringify(destination.location) === JSON.stringify(combinedDestination.trim())) {
          matchingData = shippingData?.shippingInputs[0];
        }
      });
      console.log('ships matching', matchingData)

      // If matchingData is still null, it means there's no shipping information for this combination
      if (!matchingData) {
        // Display free shipping option
        return (
          <option key={combined} value={combined}>
            Free Shipping
          </option>
        );
      }

      // Extract estimatedTime and shippingCost if available
      const estimatedTime = matchingData ? matchingData.estimatedTime : 'N/A';
      const shippingCost = matchingData ? matchingData.shippingCost[0].price : 'N/A';


      console.log('nyavu', combined, '123')

      return (
        <option key={combined} value={combined}>
          {combined} - Estimated Time: {estimatedTime}, Shipping Cost: {shippingCost},product:{matchingData.allProducts}
        </option>
      );
    });

  };



  const handleCouponCodeChange = (e, shopId, productId) => {
    const value = e.target.value;
    console.log('Coupon Code Change:', value, shopId, productId);
    setCouponCodes((prevState) => ({
      ...prevState,
      [`${shopId}-${productId}`]: value, // Use a combination of shopId and productId as the key
    }));
  };
  const paymentSubmit = () => {
    console.log('shippingAddress', primaryPhoneNumber, secondaryPhoneNumber, city)
    if (address === "" || county === "" || city === "" || primaryPhoneNumber === "" || secondaryPhoneNumber === "") {
      toast.error("Please check your customer address info!")
    } else {

      const shippingAddress = {
        address,
        addressType,
        county,
        city,
        primaryPhoneNumber,
        secondaryPhoneNumber,
        additionalInformation
      };

      const orderData = {
        cart,
        totalPrice,
        subTotalPrice,
        shipping,
        discountPrice,
        shippingAddress,
        user,
        cumulativeCouponDiscount,
        cumulativePackagingCost,
        totalShippingCost,
        currency
      }

      // update local storage with the updated orders array
      localStorage.setItem("latestOrder", JSON.stringify(orderData));
      navigate("/payment");
    }
  };

  const subTotalPrice = cart.reduce(
    (acc, item) => (item.type === "sample" ? (acc + item.qty * item?.samplePriceInput[0]?.discountPrice) : (acc + item.qty * item?.priceInput[0]?.discountPrice)),
    0
  );


  // const handleShippingCost = (option, shopIndex) => {
  //   console.log('optionex', option);
  //   const shippingCost = getShippingCost(option);
  //   console.log('optionex2', shippingCost);
  //   const selectedShippingCost = shippingCost.shippingCost;
  //   console.log('optionex3', selectedShippingCost);
  //   setSelecteShippingCosts((prevCosts) => {
  //     const newCosts = [...prevCosts];
  //     newCosts[shopIndex] = selectedShippingCost;
  //     return newCosts;
  //   });
  //   console.log('optionex5', selectedShippingCost);
  //   setShippingCost((prevState) => ({
  //     ...prevState,
  //     cost: Number(selectedShippingCost[0]?.price),
  //     products: shippingCost.allProducts
  //   }));
  // };

  // this is shipping cost variable
  // const shipping = subTotalPrice * 0.1;

  const shipping = shippingCost ? shippingCost.cost : 0 || 0;
  console.log('shipping', selectedShippingCosts);


  const handleSubmit = async (e, shopId, productId) => {
    e.preventDefault();
    const couponCodeKey = `${shopId}-${productId}`;
    const couponCode = couponCodes[couponCodeKey];

    console.log('Submit:', couponCodes, productId);
    console.log('Coupon Code:', couponCode);

    if (!couponCode) {
      toast.error("Please enter a coupon code");
      return;
    }

    try {
      const res = await axios.get(`${server}/coupon/get-coupon-value/${couponCode}`);
      const { couponCode: fetchedCoupon } = res.data;

      console.log('Fetched Coupon:', fetchedCoupon);

      if (fetchedCoupon) {
        if (shopId !== fetchedCoupon.shopId) {
          toast.error("Coupon code is not valid for this shop");
        } else if (productId !== fetchedCoupon.selectedProduct) {
          toast.error("Coupon code is not valid for this product");
        } else {
          const eligiblePrice = cart.reduce((acc, item) => acc + item.qty * item?.priceInput[0]?.discountPrice, 0);
          const discountAmount = (eligiblePrice * fetchedCoupon.value) / 100;

          console.log('Deficit', couponCodeKey, 'Amount', discountAmount);

          // Update the discountPrice state with the discount amount for the specific couponCodeKey
          setDiscountPrice((prevState) => ({
            ...prevState,
            [couponCodeKey]: discountAmount, // Set the discount for the specific couponCodeKey
          }));
          setCouponCodeData(fetchedCoupon);
          toast.success("Coupon code applied successfully");
        }
      } else {
        toast.error("Coupon code doesn't exist!");
      }
    } catch (error) {
      console.error('Error fetching coupon code:', error);
      toast.error("An error occurred while applying the coupon code");
    }

    setCouponCodes((prevState) => ({
      ...prevState,
      [couponCodeKey]: '', // Clear the input field for the specific coupon code
    }));
  };
  const calculateTotalShippingCost = (costs) => {
    return Object.values(costs).reduce((total, cost) => total + parseFloat(cost), 0);
  };
  const totalShippingCost = calculateTotalShippingCost(selectedProductPrice);


  const cumulativePackagingCost = Object.values(selectedPackagingTotal).reduce((acc, curr) => acc + curr, 0);

  const discountPercentage = couponCodeData ? discountPrice : 0;
  console.log('zero', selectedPrice, 'cums', packageTotals)
  const totalPrice = couponCodeData
    ? (Number(subTotalPrice) + Number(totalShippingCost) + Number(cumulativePackagingCost) - Number(cumulativeCouponDiscount))
    : (Number(subTotalPrice) + Number(totalShippingCost) + Number(cumulativePackagingCost)).toFixed(2);


  console.log('selected', selectedOptions)
  return (
    <div className="w-full flex flex-col items-center py-8">
      <div className="w-[90%] max-w-screen-xl">

        <ShippingInfo
          user={user}
          county={county}
          setCounty={setCounty}
          setPrimaryPhoneNumber={setPrimaryPhoneNumber}
          primaryPhoneNumber={primaryPhoneNumber}
          secondaryPhoneNumber={secondaryPhoneNumber}
          setSecondaryPhoneNumber={setSecondaryPhoneNumber}
          city={city}
          setCity={setCity}
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          address={address}
          setAddress={setAddress}
          setAddressType={setAddressType}
          addressType={addressType}
          additionalInformation={additionalInformation}
          setAdditionalInformation={setAdditionalInformation}
          cityError={cityError}
          countyError={countyError}
          addressError={addressError}
          addressTypeError={addressTypeError}
        />
        <div className="mt-8">
          <CartData
            handleSubmit={handleSubmit}
            totalPrice={totalPrice}
            shipping={shipping}
            selectedOptions={selectedOptions}
            selectedShippingCosts={selectedShippingCosts}
            shippingCost={shippingCost}
            handleSelectChange={handleSelectChange}
            option={option}
            shops={shops}
            selectionMatches={selectionMatches}
            county={county}
            setCounty={setCounty}
            city={city}
            setCity={setCity}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            address={address}
            couponCodeKey={couponCodeKey}
            setAddress={setAddress}
            // packageTotal={packageTotal}
            packageTotals={packageTotals}
            setPackageTotals={setPackageTotals}
            subTotalPrice={subTotalPrice}
            setShippingProducts={setShippingProducts}
            couponCodes={couponCodes}
            setCouponCode={setCouponCode}
            discountPrice={discountPrice}
            discountPercentage={discountPercentage}
            selectedPrice={selectedPrice}
            selectedProductPrice={selectedProductPrice}
            handlePriceSelection={handlePriceSelection}
            handleDeliveryType={handleDeliveryType}
            deliveryType={deliveryType}
            shippingProducts={shippingProducts}
            handleCouponCodeChange={handleCouponCodeChange}
            calculatePackaging={calculatePackaging}
            addressType={addressType}
            setAddressType={setAddressType}
            setAddressTypeError={setAddressTypeError}
            addressTypeError={addressTypeError}
            addressError={addressError}
            setAddressError={setAddressError}
            setCityError={setCityError}
            setCountyError={setCountyError}
            couponDiscount={couponDiscount}
            setCouponDiscount={setCouponDiscount}
            handleDiscountChange={handleDiscountChange}
            setSelectedPackaging={setSelectedPackaging}
            selectedPackaging={selectedPackaging}
            setSelectedPackagingTotal={setSelectedPackagingTotal}
          />
        </div>
     {/* refa   {JSON.stringify(selectedPackagingTotal)} */}
        <div className="w-1/2 mt-4 max-w-screen-xl mx-auto bg-white  justify-center p-4">
          <div className="flex justify-between mb-3">
            <h5 className="text-base font-medium text-gray-600">SubTotal:</h5>
            <small className="text-lg font-bolder">{currency}. {subTotalPrice}</small>
          </div>
          <div className="flex justify-between mb-3">
            <h5 className="text-base font-medium text-gray-600">Shipping:</h5>
            <small className="text-lg font-bolder">{currency}. {totalShippingCost}</small>
          </div>
          <div className="flex justify-between mb-3">
            <h5 className="text-base font-medium text-gray-600">Packaging:</h5>
            <div>
              <h5 className="text-lg font-semibold">{currency}. {cumulativePackagingCost}</h5>
            </div>
          </div>
          <div className="flex justify-between mb-3">
            <h5 className="text-base font-medium text-gray-600">Discount:</h5>
            <div>
              {/* <h5 className="text-lg font-semibold"> {couponDiscount}</h5> */}
              <h5 className="text-lg font-semibold">- {currency}. {cumulativeCouponDiscount}</h5>
            </div>
          </div>
          <div className="flex justify-between mb-3">
            <h1 className="text-base font-bold font-large text-gray-600">Total:</h1>
            <h3 className="text-lg font-bold">{currency}. {totalPrice}</h3>
          </div>
          <div
            className="w-full bg-blue-500 text-white font-semibold py-2 rounded-md text-center cursor-pointer"
            onClick={paymentSubmit}
          >
            Go to Payment
          </div>
        </div>
      </div>

    </div>

  );
}



export default Checkout;
