import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { server } from '../../server';
import { Link, useParams, useNavigate } from "react-router-dom";
import moment from 'moment';
import Editor from '../../pages/Shop/Editor';
import TagInput from './TagInput';
import { useSelector } from "react-redux";
import { Helmet } from 'react-helmet';

const SingleBlog = () => {
  const [post, setPost] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false); // State variable for editor visibility
  const [updatedContent, setUpdatedContent] = useState(post?.postContent || '');
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const { isSeller } = useSelector((state) => state.seller);
  const [tags, setTags] = useState([]);
  const [title, setTitle] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPost = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${server}/shop/single-post/${id}`);
        console.log("bility5", response.data.data);
        setPost(response.data.data[0]);
        setTitle(response.data.data[0].postTitle); // Initialize title state with postTitle
        setTags(response.data.data[0].postTag);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching post:", error);
        setIsLoading(false);
      }
    };

    fetchPost();
  }, [id]);

  // const handleEdit = () => {
  //   navigate.push(`/edit-post/${id}`);
  // };

  // Function to handle edit button click
  const handleEdit = () => {
    setIsEditing(!isEditing); // Toggle editor visibility
  };


  // Function to handle cancel button click
  const handleCancel = () => {
    setIsEditing(false); // Hide the editor without saving changes
  };
  const handleDelete = async () => {
    try {
      await axios.delete(`${server}/shop/delete-post/${id}`);
      // Redirect to a different page or handle deletion success
      // For example, you can redirect the user to the blog list page
      navigate(-1);
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };
  const handleContentChange = (content) => {
    setUpdatedContent(content);
  };

  const handleSave = async () => {
    try {

      const updatedPost = {
        ...post,
        postContent: updatedContent,
        postTitle: title,
        postTag: tags, // Assuming the property name is postTag for tags
      };


      await axios.put(`${server}/shop/update-post/${id}`, updatedPost);
      setIsEditing(false);
      // Update local state or trigger a refetch of the post data
    } catch (error) {
      console.error('Error updating post:', error);
    }
  };

  return (
    <>
      <Helmet>
        <title>{post?.postTitle} - Indazetu Blog</title>
        <meta name="description" content={`Read ${post?.postTitle} on Indazetu. Published on ${moment(post?.createdAt).format('MMMM Do, YYYY')}.`} />
        <meta name="keywords" content={`${post?.postTag.join(', ')}, Indazetu blog, ${post?.postTitle}`} />
        <meta property="og:title" content={`${post?.postTitle} - Indazetu Blog`} />
        <meta property="og:description" content={`Read ${post?.postTitle} on Indazetu. Published on ${moment(post?.createdAt).format('MMMM Do, YYYY')}.`} />
        <meta property="og:image" content={post?.imageUrl || 'default-image-url.jpg'} />
        <meta property="og:url" content={`https://indazetu.com/blog/${post?._id}`} />
        <meta property="og:type" content="article" />
        <link rel="canonical" href={`https://indazetu.com/blog/${post?._id}`} />
      </Helmet>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div className="container mx-auto py-8">
          <header className="mb-8">
            <h1 className="text-4xl font-bold mb-4">{post?.postTitle}</h1>
            <p className="text-gray-600">{moment(post?.createdAt).format('MMMM Do, YYYY')}</p>
          </header>
          <article className="prose lg:prose-xl">
            <p className="text-gray-800" dangerouslySetInnerHTML={{ __html: post?.postContent }}></p>
          </article>
          <footer className="mt-8">
            <p className="text-gray-600">Tags: {post?.postTag.map((tag, index) => (
              <span key={index} className="font-medium">{tag}, </span>
            ))}</p>
            {isAuthenticated && isSeller && (
              <>
                <button onClick={handleEdit} className="bg-blue-500 text-white py-2 px-4 rounded-full mr-2">Edit</button>
                <button onClick={handleDelete} className="bg-red-500 text-white py-2 px-4 rounded-full">Delete</button>
              </>
            )}
            {isEditing && isAuthenticated && isSeller && (
              <div className="mt-4">
                <label htmlFor="postTitle" className="block font-medium mb-1">
                  Post Title:
                </label>
                <input
                  type="text"
                  id="postTitle"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="w-full border border-gray-300 rounded-md px-3 py-2 mb-3 focus:outline-none focus:ring focus:border-blue-500"
                />

                <Editor content={post?.postContent} onContentChange={handleContentChange} />
                <br />
                <br />
                <br />
                <label htmlFor="tags" className="block font-medium mt-4 mb-1 mt-3">
                  Tags:
                </label>
                <TagInput
                  id="tags"
                  label="Tags"
                  tags={tags}
                  setTags={setTags}
                  className="w-full"
                />

                <br />
                <div className="flex mt-8">
                  <button onClick={handleSave} className="bg-green-500 text-white py-2 px-4 rounded-full mr-2">Save</button>
                  <button onClick={handleCancel} className="bg-red-500 text-white py-2 px-4 rounded-full">Cancel</button>
                </div>
              </div>
            )}
          </footer>
        </div>
      )}
    </>
  );
};

export default SingleBlog;

