import React from 'react';

const TextSuggestionsHelper = ({ suggestions, onSuggestionClick }) => {
  return (
    // <div className="space-y-2">
    //   {suggestions.map((suggestion, index) => (
    //     <button
    //       key={index}
    //       type="button"
    //       className="block w-full text-left px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 bg-gray-100 hover:bg-gray-200"
    //       onClick={() => onSuggestionClick(suggestion)}
    //     >
    //       {suggestion}
    //     </button>
    //   ))}
    // </div>


<div className="mt-2">
      <h4 className="text-sm font-medium text-gray-700">Suggestions:</h4>
      <div className="flex flex-wrap gap-2 mt-1">
        {suggestions.map((suggestion, index) => (
          <button
            key={index}
            type="button"
            onClick={() => onSuggestionClick(suggestion)}
            className="text-xs px-3 py-1 border rounded-md bg-gray-200 hover:bg-gray-300 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 bg-gray-100 hover:bg-gray-200"
          >
            {suggestion}
          </button>
        ))}
      </div>
    </div>
  );
};

export default TextSuggestionsHelper;
