import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const EstimatedTimeInput = ({ value = '', onChange }) => {
  const [days, setDays] = useState('');
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');

  useEffect(() => {
    if (value) {
      const [daysPart = '', hoursPart = '', minutesPart = ''] = value.split(' ');
      setDays(daysPart.replace('d', '') || '');
      setHours(hoursPart.replace('h', '') || '');
      setMinutes(minutesPart.replace('m', '') || '');
    } else {
      setDays('');
      setHours('');
      setMinutes('');
    }
  }, [value]);

  const handleTimeChange = () => {
    const newEstimatedTime = `${days ? `${days}d ` : ''}${hours ? `${hours}h ` : ''}${minutes ? `${minutes}m` : ''}`.trim();
    onChange(newEstimatedTime);
  };

  return (
    <div className="space-y-6 bg-orange-100 p-2">
      <label className="block text-gray-700">Estimated Time for Delivery</label>
      <div className="mb-4 flex flex-col sm:flex-row">
        <div className="flex-1 sm:mr-2">
          <label className="block text-gray-700">Days</label>
          <input
            type="number"
            value={days}
            onChange={(e) => {
              setDays(e.target.value);
              handleTimeChange();
            }}
            placeholder="Days"
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:ring focus:ring-blue-500 focus:outline-none"
          />
        </div>
        <div className="flex-1 sm:mr-2">
          <label className="block text-gray-700">Hours</label>
          <input
            type="number"
            value={hours}
            onChange={(e) => {
              setHours(e.target.value);
              handleTimeChange();
            }}
            placeholder="Hours"
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:ring focus:ring-blue-500 focus:outline-none"
          />
        </div>
        <div className="flex-1">
          <label className="block text-gray-700">Minutes</label>
          <input
            type="number"
            value={minutes}
            onChange={(e) => {
              setMinutes(e.target.value);
              handleTimeChange();
            }}
            placeholder="Minutes"
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:ring focus:ring-blue-500 focus:outline-none"
          />
        </div>
      </div>

      <div className="mb-4">
        <input
          type="text"
          value={value}
          readOnly
          name="estimatedTime"
          className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:ring focus:ring-blue-500 focus:outline-none"
        />
      </div>
    </div>
  );
};

EstimatedTimeInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default EstimatedTimeInput;
