import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Signup from "../components/Signup/Signup";
import { Helmet } from'react-helmet';

const SignupPage = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.user);

  useEffect(() => {
    if (isAuthenticated === true) {
      navigate("/");
    }
  }, [])
  return (
    <div> 
      <Helmet>
      <title>Sign Up - Indazetu</title>
      <meta name="description" content="Create your Indazetu account to start shopping, selling, and exploring our marketplace. Join us today and become a part of our growing community!" />
      <meta name="keywords" content="sign up, create account, Indazetu signup, join Indazetu, new user registration, online marketplace signup" />
      <meta property="og:title" content="Sign Up - Indazetu" />
      <meta property="og:description" content="Create your Indazetu account to start shopping, selling, and exploring our marketplace. Join us today and become a part of our growing community!" />
      <meta property="og:image" content="https://indazetu.com/static/media/logoo-removebg-preview.041ee5fc1e771ab093a6.png" />
      <meta property="og:url" content="https://indazetu.com/sign-up" />
      <meta property="og:type" content="website" />
      <link rel="canonical" href="https://indazetu.com/sign-up" />
    </Helmet>

      <Signup />
    </div>
  )
}

export default SignupPage