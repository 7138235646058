import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";


const PrivacyPolicyPage = () => {
    return (
      <div>
        <Helmet>
          <title>Terms and Conditions - Indazetu</title>
          <meta name="description" content="Read the Terms and Conditions of Use for Indazetu, Kenya's leading B2B marketplace platform. Last updated on 7 December 2021." />
          <meta name="keywords" content="Indazetu, Terms and Conditions, B2B marketplace, Kenya, user agreement" />
        </Helmet>
        <Header/>
        <PrivacyPolicy />
        <Footer />
      </div>
    );
  };

const PrivacyPolicy = () => {
  return (
    <div className="bg-gray-100 py-10 px-4 sm:px-6 lg:px-8">

      <Helmet>
        <title>Privacy Policy - indazetu</title>
        <meta name="description" content="Privacy Policy of indazetu, Kenya's leading B2B marketplace platform. Learn how we collect, use, and protect your personal information." />
        <meta name="keywords" content="privacy policy, data protection, indazetu, Kenya, personal information" />
      </Helmet>
      <div className="max-w-7xl mx-auto bg-white p-8 rounded-lg shadow-lg">
      <header className="mb-8 text-center">
        <h1 className="text-4xl font-bold mb-4">Privacy Policy</h1>
        <p className="text-gray-600">Effective Date: July 25, 2024</p>
      </header>
      <article className="prose lg:prose-xl mx-auto">
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">Table of Contents</h2>
          <ul className="list-disc list-inside mb-6">
            <li><a href="#introduction" className="text-blue-600 hover:underline">Introduction</a></li>
            <li><a href="#about-us" className="text-blue-600 hover:underline">About Us</a></li>
            <li><a href="#information-collection" className="text-blue-600 hover:underline">What Information Do We Collect?</a></li>
            <li><a href="#cookies" className="text-blue-600 hover:underline">Cookies and Similar Tracking Technologies</a></li>
            <li><a href="#use-of-information" className="text-blue-600 hover:underline">How Do We Use Your Information?</a></li>
            <li><a href="#legal-basis" className="text-blue-600 hover:underline">Legal Basis for Processing Your Information</a></li>
            <li><a href="#sharing-information" className="text-blue-600 hover:underline">Sharing Your Information</a></li>
            <li><a href="#international-transfers" className="text-blue-600 hover:underline">International Transfers</a></li>
            <li><a href="#data-retention" className="text-blue-600 hover:underline">Data Retention</a></li>
            <li><a href="#data-security" className="text-blue-600 hover:underline">Data Security</a></li>
            <li><a href="#your-rights" className="text-blue-600 hover:underline">Your Rights Regarding Your Information</a></li>
            <li><a href="#contact-us" className="text-blue-600 hover:underline">Contact Us</a></li>
            <li><a href="#changes" className="text-blue-600 hover:underline">Changes to This Privacy Policy</a></li>
          </ul>
        </section>

        <section id="introduction" className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">1. Introduction</h2>
          <p>Welcome to indazetu, Kenya's leading B2B marketplace platform! This Privacy Policy explains how we collect, use, and protect your personal information when you use our website, mobile applications, or services. It also outlines your rights under Kenyan and international data privacy laws.</p>
        </section>

        <section id="about-us" className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">2. About Us</h2>
          <p>indazetu is a Kenyan company that connects businesses (buyers and sellers) to facilitate trade within the country and internationally. We offer additional services like logistics for shipments and secure payment solutions. We are a subsidiary of [Parent Company Name].</p>
        </section>

        <section id="information-collection" className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">3. What Information Do We Collect?</h2>
          <p>We collect information to provide you with a smooth and secure experience on our platform. Here's what we collect:</p>
          <ul className="list-disc list-inside">
            <li><strong>Information You Provide:</strong> This includes your name, contact details (company name, email, phone number), demographic information relevant to your business, payment information for transactions, survey responses, competition entries, and marketing preferences.</li>
            <li><strong>Automatically Collected Information:</strong> We collect data about your device (type, operating system), IP address, browsing activity on our platform (pages visited, search history), purchase history for order tracking, and social media profile data (if you connect your social media accounts).</li>
            <li><strong>Third-Party Information:</strong> We may obtain information from trusted partners like carriers (for deliveries), payment providers (for secure transactions), and advertising services (to personalize your experience). We only receive data that these partners are authorized to share.</li>
          </ul>
        </section>

        <section id="cookies" className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">4. Cookies and Similar Tracking Technologies</h2>
          <p>We use cookies and similar technologies (like web beacons and pixels) to remember your preferences, understand how you use our platform, and improve your overall experience. You can choose to disable cookies in your browser settings. For detailed information on how we use cookies, please refer to our separate Cookie Notice.</p>
        </section>

        <section id="use-of-information" className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">5. How Do We Use Your Information?</h2>
          <p>We use your information for several legitimate purposes to improve your experience and operate our business effectively:</p>
          <ul className="list-disc list-inside">
            <li><strong>Account Management:</strong> To register you for an account, manage your profile, and process transactions.</li>
            <li><strong>Order Fulfillment:</strong> To process your orders, connect you with sellers, and facilitate secure payments.</li>
            <li><strong>Service Improvement:</strong> To analyze platform usage, identify areas for improvement, and personalize your experience with relevant features and recommendations.</li>
            <li><strong>Marketing and Communication:</strong> To send you relevant marketing updates, offers, and industry news (only with your consent).</li>
            <li><strong>Legal Compliance:</strong> To comply with Kenyan laws and regulations, including tax regulations and fraud prevention.</li>
            <li><strong>Security:</strong> To detect and prevent fraudulent activity and protect the security of our platform.</li>
          </ul>
        </section>

        <section id="legal-basis" className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">6. Legal Basis for Processing Your Information</h2>
          <p>We process your information only when we have a legal basis to do so. Here's how:</p>
          <ul className="list-disc list-inside">
            <li><strong>Consent:</strong> We rely on your consent for marketing communications and personalized recommendations. You can withdraw your consent anytime.</li>
            <li><strong>Legitimate Interests:</strong> We use your information for service improvement, understanding customer behavior, and providing a secure platform. These uses benefit both you and our business.</li>
            <li><strong>Contractual Necessity:</strong> To fulfill your orders and provide the services you request, we need to process your information.</li>
            <li><strong>Legal Compliance:</strong> In certain cases, we may need to share your information to comply with Kenyan laws and regulations.</li>
          </ul>
        </section>

        <section id="sharing-information" className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">7. Sharing Your Information</h2>
          <p>We share your information only with trusted partners who help us operate the platform and provide you with the best service:</p>
          <ul className="list-disc list-inside">
            <li><strong>Third-Party Service Providers:</strong> We work with companies that assist with order fulfillment, payment processing, marketing automation, and customer support. These partners are contractually obligated to protect your information.</li>
            <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or asset sale, your information may be transferred to the new owner. We will notify you in advance of any such transfer.</li>
            <li><strong>Legal Authorities:</strong> We may disclose your information if required by law, such as for fraud investigations or court orders. We will only disclose the information necessary to comply with the legal requirement.</li>
          </ul>
        </section>

        <section id="international-transfers" className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">8. International Transfers</h2>
          <p>We may transfer your information to servers located outside Kenya for processing and storage. However, we ensure that your information is protected according to Kenyan data protection laws and applicable international standards.</p>
        </section>

        <section id="data-retention" className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">9. Data Retention</h2>
          <p>We retain your information only for as long as necessary to fulfill the purposes described in this Privacy Policy and comply with legal obligations. We follow a data retention schedule that considers factors like your account activity, transaction history, and legal requirements. After the retention period, your information will be securely deleted or anonymized.</p>
        </section>

        <section id="data-security" className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">10. Data Security</h2>
          <p>We take data security very seriously and implement robust security measures to protect your information from unauthorized access, disclosure, alteration, or destruction. These measures include:</p>
          <ul className="list-disc list-inside">
            <li>Secure data storage with encryption</li>
            <li>Regular security audits and penetration testing</li>
            <li>Access controls and authorization protocols</li>
            <li>Employee training on data privacy and security</li>
          </ul>
        </section>

        <section id="your-rights" className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">11. Your Rights Regarding Your Information</h2>
          <p>Under Kenyan data protection laws and international best practices, you have certain rights regarding your personal information. You can:</p>
          <ul className="list-disc list-inside">
            <li><strong>Access your information:</strong> You have the right to request a copy of the information we hold about you.</li>
            <li><strong>Correct your information:</strong> You can request to correct any inaccurate or incomplete information in your records.</li>
            <li><strong>Delete your information:</strong> You have the right to request the deletion of your information in certain circumstances.</li>
            <li><strong>Restrict processing:</strong> You can request to restrict the processing of your information in certain situations.</li>
            <li><strong>Object to processing:</strong> You have the right to object to the processing of your information for marketing purposes.</li>
            <li><strong>Data portability:</strong> You have the right to request to receive your information in a portable format and transfer it to another service provider.</li>
          </ul>
          <p>To exercise your rights, please contact our Data Privacy Officer using the information provided in the Contact Us section below.</p>
        </section>

        <section id="contact-us" className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">12. Contact Us</h2>
          <p>If you have any questions or concerns about this Privacy Policy or your information, please don't hesitate to contact us:</p>
          <p>Email: <a href="mailto:privacy@indazetu.com" className="text-blue-600 hover:underline">privacy@indazetu.com</a></p>
          <p>Phone: [Phone Number (Optional)]</p>
          <p>Postal Address: [Company Address]</p>
          <p>We are committed to transparency and will respond to your inquiries promptly.</p>
        </section>

        <section id="changes" className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">13. Changes to This Privacy Policy</h2>
          <p>We may update this Privacy Policy from time to time to reflect changes in our practices or comply with legal requirements. We will notify you of any significant changes by posting the updated Privacy Policy on our website.</p>
          <p>By using our platform, you acknowledge that you have read and understood this Privacy Policy.</p>
        </section>
      </article>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
