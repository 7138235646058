import React, { useState, useEffect } from 'react';
import { getDistance } from 'geolib'; // Assuming you have a library like geolib for distance calculation
import Select from 'react-select';
import { server } from '../../server';
import axios from 'axios';
import { toast } from 'react-toastify';


const NearestPickupLocation = ({ address, selectedLocations, selectedProducts, productId, handlePriceSelection, currency, setPickupDetails }) => {
  const [nearestPickupLocation, setNearestPickupLocation] = useState(null);
  const [options, setOptions] = useState([]);
  const [defaultOption, setDefaultOption] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [pickupStations, setPickupStations] = useState([]);
  const [selectedStation, setSelectedStation] = useState(null);
  const [loading, setLoading] = useState(true);


  const getDistance = (point1, point2) => {
    const rad = Math.PI / 180;
    const dLat = (point2.latitude - point1.latitude) * rad;
    const dLng = (point2.longitude - point1.longitude) * rad;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLng / 2) +
      Math.cos(point1.latitude * rad) * Math.cos(point2.latitude * rad) *
      Math.sin(dLng / 2) * Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const R = 6371e3; // Radius of Earth in meters
    return R * c; // Distance in meters
  };

  const fetchStations = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${server}/pickupstation/get-all-pickupstations`);
      setPickupStations(response.data.pickupStations); // Ensure to correctly extract the array
    } catch (error) {
      console.error('Error fetching pickup stations:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchStations();
  }, []);

  useEffect(() => {
    const getAddressCoordinates = async (address) => {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}}&key=AIzaSyBTGyc7Q60B-O3Ew_rLpUEc8qrqY-wLw3c`
        );
        const data = await response.json();
        const { lat, lng } = data.results[0].geometry.location;
        return { latitude: lat, longitude: lng };
      } catch (error) {
        console.error('Error retrieving address coordinates:', error);
        return null;
      }
    };

    const findNearestPickupLocation = async () => {
      const clientCoordinates = await getAddressCoordinates(address);
      if (!clientCoordinates) return;

      let minDistance = Infinity;
      let nearestLocation = null;

      const filteredLocations = selectedLocations.filter((group) =>
        group.label.some((selectedProduct) => selectedProduct.value === productId)
      );

      for (const location of filteredLocations) {
        const [source, destination, deliveryType] = location.value.split('-').map(loc => loc.trim());

        const sourceCoordinates = await getAddressCoordinates(source);
        const destinationCoordinates = await getAddressCoordinates(destination);

        if (!sourceCoordinates || !destinationCoordinates) continue;

        const distanceToSource = getDistance(clientCoordinates, sourceCoordinates);
        const distanceToDestination = getDistance(clientCoordinates, destinationCoordinates);

        const totalDistance = distanceToSource + distanceToDestination;

        if (totalDistance < minDistance) {
          minDistance = totalDistance;
          nearestLocation = { source, destination, distance: totalDistance, deliveryType };
        }
      }

      if (!nearestLocation) {
        // Find the first regular option if no nearest location found
        for (const location of filteredLocations) {
          const [source, destination, deliveryType] = location.value.split('-').map(loc => loc.trim());
          if (deliveryType === 'regular') {
            nearestLocation = { source, destination, distance: 0, deliveryType }; // Default to 0 distance
            break;
          }
        }
      }
      setNearestPickupLocation(nearestLocation);
      if (nearestLocation) {
        setDefaultOption({
          value: `${nearestLocation.source}-${nearestLocation.destination}-${nearestLocation.deliveryType || 'Regular'}`,
          label: `${nearestLocation.destination}-${nearestLocation.deliveryType || 'Regular'}`,
        });
      }
    };

    findNearestPickupLocation(address);
  }, [address, selectedLocations, productId]);

  useEffect(() => {
    console.log('selectedProducts', selectedProducts);
    const options = selectedProducts
      .filter((group) =>
        group.label.some((selectedProduct) => selectedProduct.value === productId)
      )
      .map((groups) => {
        const details = JSON.parse(groups.value.split('-')[3]);
        return {
          value: groups.value,
          label: `${groups.value.split('-')[1]}-${groups.value.split('-')[2]}`,
          priceDetails: details
        };
      });
    setOptions(options);
  }, [selectedProducts, productId]);

  useEffect(() => {
    const defaultValue = options.find(option => {
      const [source, destination, deliveryType] = option.value.split(' - ').map(s => s.trim());
      return destination === nearestPickupLocation?.destination && deliveryType === nearestPickupLocation?.deliveryType;
    });
    if (defaultValue) {
      setSelectedOption(defaultValue);
      handlePriceSelection(defaultValue.value, productId);
      handleSelectChange(defaultValue)
    }
  }, [options, nearestPickupLocation]);

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    handlePriceSelection(selectedOption.value, productId);

    const [source, destination, deliveryType] = selectedOption.value.split('-').map(loc => loc.trim());
    const station = pickupStations.find(station => station.address.location === destination);
    setSelectedStation(station);
  };

  function getPricingDetails(optionValue) {
    try {
      const parts = optionValue.split(" - ");
      const pricingString = parts[3];
      const pricingObject = JSON.parse(pricingString);
      setPickupDetails( selectedStation)
      return pricingObject[0];

    } catch (error) {
      console.error("Error parsing pricing information:", error);
      return null;
    }
  }

  const pricingDetails = selectedOption && getPricingDetails(selectedOption.value);
  const Loader = () => (
    <div className="flex justify-center items-center h-full w-full">
      <div className="w-12 h-12 border-4 border-blue-500 border-t-transparent border-solid rounded-full animate-spin"></div>
    </div>
  );

  return (
    <div className="p-4 bg-white rounded-lg shadow-md flex">
      {loading || !nearestPickupLocation ? (
        <Loader />
      ) : (
        <>
          <div className="flex-1">
            {nearestPickupLocation ? (
              <div>
                <div className="mb-1 flex justify-between">
                  <span className="text-gray-500 text-sm font-medium">Source:</span>
                  <span className="text-gray-700 text-xs font-semibold">{nearestPickupLocation.source}</span>
                </div>
                <div className="mb-1 flex justify-between">
                  <span className="text-gray-500 text-sm font-medium">Destination:</span>
                  <span className="text-gray-700 text-xs font-semibold">{nearestPickupLocation.destination}</span>
                </div>
                <div className="mb-1 flex justify-between">
                  <span className="text-gray-500 text-sm font-medium">Distance:</span>
                  <span className="text-gray-700 text-xs font-semibold">{nearestPickupLocation.distance.toFixed(2)} meters</span>
                </div>
                <p className="text-blue-800 text-md font-bold mb-3">Nearest Pickup Location:</p>
                <Select
                  options={options}
                  placeholder="Select your location"
                  value={selectedOption}
                  isSearchable
                  onChange={handleSelectChange}
                  className="w-full"
                  styles={{
                    control: (base) => ({
                      ...base,
                      borderColor: '#E5E7EB',
                      boxShadow: 'none',
                      '&:hover': {
                        borderColor: '#D1D5DB',
                      },
                    }),
                  }}
                />
                {selectedOption && (
                  <div className="mt-4">
                    {/* <p className="text-gray-600">
                  <strong>Price:</strong> {pricingDetails ? (pricingDetails.discountPrice ? pricingDetails.discountPrice : pricingDetails.price) : 'N/A'} {pricingDetails ? pricingDetails.metric : ''}
                </p> */}
                    <p className="text-gray-600">
                      <strong>Price:</strong> {currency}. {pricingDetails ? pricingDetails.price : 'N/A'}
                    </p>
                    <p className="text-orange-800">
                      <strong>Discount Price:</strong> {currency}. {pricingDetails ? pricingDetails.discountPrice : 'N/A'}
                    </p>
                    <p className="text-gray-600">
                      <strong>Min:</strong> {pricingDetails ? pricingDetails.min : 'N/A'} ({pricingDetails ? pricingDetails.metric : ''})
                    </p>
                    <p className="text-gray-600">
                      <strong>Max:</strong> {pricingDetails ? pricingDetails.max : 'N/A'} ({pricingDetails ? pricingDetails.metric : ''})
                    </p>
                  </div>
                )}
              </div>
            ) : (
              <div className="text-gray-500 text-center">No pickup locations found</div>
            )}
          </div>
        </>
      )}
    {selectedStation && (
  <div className="flex-1 ml-4 p-4 bg-gray-100 rounded-lg shadow-inner">
    <h3 className="text-lg font-bold text-orange-800 mb-2">{selectedStation.stationName}</h3>
    <p className="text-gray-600 text-sm">
      <strong className="text-blue-800 text-sm">Address :</strong> 
            {selectedStation.address.location}
      <a 
        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(selectedStation.address.location)}`} 
        target="_blank" 
        rel="noopener noreferrer" 
        className="text-blue-600 hover:underline"
      > ~ Map View
      </a>
    
    </p>
    <p className="text-gray-600 text-sm"><strong className="text-blue-800 text-sm">Working Hours:</strong> {selectedStation.workingHours}</p>
    <p className="text-gray-600 text-sm"><strong className="text-blue-800 text-sm">Days:</strong> {selectedStation.days}</p>
    <p className="text-gray-600 text-sm"><strong className="text-blue-800 text-sm">Phone:</strong> {selectedStation.phone}</p>
    <p className="text-gray-600 text-sm"><strong className="text-blue-800 text-sm">Payment Option:</strong> {selectedStation.paymentOption}</p>
    <p className="text-gray-600 text-sm"><strong className="text-blue-800 text-sm">Description:</strong> {selectedStation.description}</p>
    <p className="text-gray-600 text-sm"><strong className="text-blue-800 text-sm">County:</strong> {selectedStation.county}</p>
    <p className="text-gray-600 text-sm"><strong className="text-blue-800 text-sm">City:</strong> {selectedStation.city}</p>
  </div>
)}

    </div>
  );
};


export default NearestPickupLocation;
