import { React, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import logo from "../../Assests/images/logoo.png";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [tempUserId, setTempUserId] = useState(null); // Store the temporary user ID

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // Step 1: Check if the user exists and password is correct
      const userCheckResponse = await handleUserCheck();
      if (userCheckResponse.success) {
        setTempUserId(userCheckResponse.userId); // Save the temporary user ID
        // Step 2: Send OTP to user's email
        await axios.post(`${server}/notification/send-otp`, { email, type: 'user' });
        toast.success("OTP sent to your email");
        setOtpSent(true);
      }
    } catch (err) {
      toast.error(err.response?.data?.message || "An error occurred during login");
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    try {
      // Step 3: Verify the OTP
      await axios.post(`${server}/notification/verify-otp`, { email, otp, type: 'user' });

      // Step 4: Re-authenticate the user (final login step)
      const loginResponse = await handleSubmit();
      if (loginResponse) {
        toast.success("Login Successful!");
        navigate("/");
      }
    } catch (err) {
      toast.error(err.response?.data?.message || "OTP verification failed");
    }
  };

  const handleUserCheck = async () => {
    try {
      const res = await axios.post(`${server}/user/check-user`, { email, password });
      return res.data; // Assuming the response contains a success flag and userId
    } catch (err) {

      toast.error(err.response?.data?.message || "Incorrect Credentials");
      
      return false;
    }
  };

  const handleSubmit = async () => {
    try {
      const res = await axios.post(`${server}/user/complete-login`, { userId: tempUserId, password }, { withCredentials: true });
      return res.data; // Return the full response or a specific part if needed
    } catch (err) {
      toast.error(err.response?.data?.message || "Login failed");
      return null;
    }
  };


  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-400 to-white-500 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <a href="/">
          <img className="mx-auto h-23 w-auto" src={logo}  width={120}
                height={35} alt="Logo" />
        </a>
        <h2 className="mt-6 text-center text-3xl font-extrabold text-white">
          {otpSent ? "Verify OTP" : "Login to your account"}
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={otpSent ? handleVerifyOtp : handleLogin}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  name="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
            {otpSent && (
              <div>
                <label
                  htmlFor="otp"
                  className="block text-sm font-medium text-gray-700"
                >
                  OTP
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="otp"
                    autoComplete="otp"
                    required
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>
            )}
            {!otpSent && (
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1 relative">
                  <input
                    type="password"
                    name="password"
                    autoComplete="current-password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>
            )}
            <div>
              <button
                type="submit"
                className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
              >
                {otpSent ? "Verify OTP" : "Submit"}
              </button>
            </div>
            <div className="text-center">
              <p className="text-sm">
                <a
                  href="/forgot-password"
                  className="font-medium text-blue-600 hover:text-blue-500"
                >
                  Forgot your password?{" "}
                </a>
              </p>
            </div>
            <div className="text-center">
              <p className="text-sm">
                Don't have an account?{" "}
                <a
                  href="/sign-up"
                  className="font-medium text-blue-600 hover:text-blue-500"
                >
                  Sign Up
                </a>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
