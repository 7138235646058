
import React, { useState } from 'react';

const getBestPackagingOption = (options, quantity) => {
  let bestOption = null;
  let bestTotalPrice = Infinity;

  options.forEach(option => {
    if (quantity >= option.min && quantity <= option.max) {
      const totalPrice = option.price * Math.ceil(quantity / option.max);
      if (totalPrice < bestTotalPrice) {
        bestTotalPrice = totalPrice;
        bestOption = option;
      }
    }
  });

  return { bestOption, bestTotalPrice };
};

const PackagingModal = ({ packagingOptions, onClose, setSelectedPackaging,setSelectedPackagingTotal, currency, productId, productQty }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredOptions = packagingOptions.filter(pack =>
    pack.type.toLowerCase().includes(searchTerm.toLowerCase()) &&
    productQty >= pack.min && productQty <= pack.max
  );
  const { bestOption, bestTotalPrice } = getBestPackagingOption(filteredOptions, productQty);
  
  const handleSelect = (pack,bestTotalPrice) => {
    setSelectedPackaging(prevSelected => ({
      ...prevSelected,
      [productId]: pack,
    }));
    setSelectedPackagingTotal(prevSelected => ({
      ...prevSelected,
      [productId]: bestTotalPrice,
    }));
    onClose();
  };

  

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
      <div className="bg-white rounded-lg overflow-hidden shadow-xl max-w-lg w-full">
        <div className="p-4 border-b">
          <input
            type="text"
            placeholder="Search packaging"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-blue-500"
          />
        </div>
        <div className="max-h-96 overflow-y-auto">
          {filteredOptions.length > 0 ? (
            filteredOptions.map((pack, index) => (
              <div
                key={index}
                onClick={() => handleSelect(pack,bestTotalPrice)}
                className={`flex justify-between items-center p-4 border-b cursor-pointer hover:bg-gray-100 ${bestOption?.type === pack.type && bestOption?.size === pack.size ? 'bg-green-100' : ''}`}
              >
                <div className="text-center w-full">
                  <h4 className="font-semibold">{pack.type}</h4>
                  <p className="text-sm text-gray-600">{currency}. {pack.price}</p>
                  <p className="text-sm text-gray-600">Size: {pack.size}</p>
                  <p className="text-sm text-gray-600">Min: {pack.min} - Max: {pack.max} <b className="text-md text-green-500">({pack.metric})</b></p>
                </div>
                <svg className="h-6 w-6 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
              </div>
            ))
          ) : (
            <div className="p-4 text-gray-600 text-center">No packaging options found.</div>
          )}
        </div>
        {bestOption && (
          <div className="p-4 text-center">
            <h4 className="font-semibold">Best Price Option</h4>
            <p className="text-sm text-gray-600">{bestOption.type} - {currency} {bestOption.price} per {bestOption.metric}</p>
            <p className="text-sm text-gray-600">Total Price: {currency} {bestTotalPrice}</p>
          </div>
        )}
        <div className="p-4 border-t text-right">
          <button
            onClick={onClose}
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 focus:outline-none focus:ring focus:ring-red-300"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}


export default PackagingModal;
