
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import{ MultiSelect} from 'react-multi-select-component';

const MultiSelector = ({ options, onSelectionChange, settings, deliveryType, source, destination }) => {
  const [selected, setSelected] = useState([]);
  const [selectedProductsByType, setSelectedProductsByType] = useState({});

  useEffect(() => {
    console.log('Initial settings:', settings);
    console.log('Initial deliveryType:', deliveryType);
    console.log('Initial source:', source);
    console.log('Initial destination:', destination);
  }, [settings, deliveryType, source, destination]);

  const handleChange = (selectedOptions) => {
    setSelected(selectedOptions);
    const updatedSelectedProducts = selectedOptions.reduce((acc, option) => {
      acc[option.deliveryType] = option.value;
      return acc;
    }, {});

    setSelectedProductsByType((prevSelectedProducts) => ({
      ...prevSelectedProducts,
      ...updatedSelectedProducts,
    }));

    onSelectionChange(selectedOptions);
  };
  const transformedOptions = options.map((option) => {
    const isSelected = Object.values(selectedProductsByType).includes(option._id);
    const isDisabled = settings.some((setting) => {
      return (
        setting.shippingInputs[0].allProducts.some((product) => product.value === option._id) &&
        setting.shippingInputs[0].deliveryType === deliveryType &&
        setting.shippingInputs[0].source.location === source.location &&
        destination?.location && // Ensure destination is not null or undefined
        setting.shippingInputs[0].destination.location === destination.location
      );
    });
  
    return {
      label: option.name,
      value: option._id,
      disabled: isSelected || isDisabled,
    };
  });
  
  

  return (
    <div style={{ maxWidth: '450px' }}>
      <MultiSelect
        options={transformedOptions}
        value={selected}
        onChange={handleChange}
        labelledBy="Select"
        disableSearch
        overrideStrings={{ selectSomeItems: 'Select Products' }}
        style={{ maxWidth: '100%' }}
      />
    </div>
  );
};

MultiSelector.propTypes = {
  options: PropTypes.array.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  settings: PropTypes.array.isRequired,
  deliveryType: PropTypes.string.isRequired,
  source: PropTypes.object.isRequired,
  destination: PropTypes.object.isRequired,
};

export default MultiSelector;
