import { useState, useEffect } from "react";
import { FaImage, FaVideo, FaTimes } from "react-icons/fa";
import { server } from "../../server";
import axios from "axios";

const GalleryTabs = ({ shop }) => {
  const [active, setActive] = useState("image");
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [videos, setVideos] = useState([]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchMedia = async () => {
      try {
        const imageResponse = await axios.get(`${server}/shop/images/${shop}`);
        setImages(imageResponse.data.data);

        const videoResponse = await axios.get(`${server}/shop/videos/${shop}`);
        setVideos(videoResponse.data.data);
      } catch (error) {
        console.error('Error fetching media:', error);
      }
    };

    fetchMedia();
  }, [shop]);

  const openImageModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShowImageModal(true);
  };

  const closeImageModal = () => {
    setShowImageModal(false);
    setSelectedImage(null);
  };

  return (
    <div>
      <div className="flex items-center mb-4">
        <div
          className={`flex items-center mr-5 cursor-pointer ${active === "image" ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-700"
            } rounded-full py-1 px-3`}
          onClick={() => setActive("image")}
        >
          <span className="mr-1">Images</span>
          <FaImage className="text-xl" />
        </div>
        <div
          className={`flex items-center cursor-pointer ${active === "video" ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-700"
            } rounded-full py-1 px-3`}
          onClick={() => setActive("video")}
        >
          <span className="mr-1">Videos</span>
          <FaVideo className="text-xl" />
        </div>
      </div>

      {active === "image" ? (
  <div className="grid grid-cols-4 gap-4">
    {images.length > 0 ? (
      images.map((image, index) => (
        <img
          key={index}
          src={image.image.url}
          alt={image.image.public_id}
          className="rounded-lg cursor-pointer"
          onClick={() => openImageModal(image.image.url)}
        />
      ))
    ) : (
      <p className="text-gray-500 text-center col-span-4">No images available</p>
    )}
  </div>
) : (
  <div className="grid grid-cols-4 gap-4">
    {videos.length > 0 ? (
      videos.map((video) => (
        <div key={video._id} className="relative aspect-w-16 aspect-h-9">
          <iframe
            title="video preview"
            src={video.video}
            frameBorder="0"
            allowFullScreen
            className="w-full h-full"
          ></iframe>
        </div>
      ))
    ) : (
      <p className="text-gray-500 text-center col-span-4">No videos available</p>
    )}
  </div>
)}

      {showImageModal && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white rounded-lg p-4">
            <img src={selectedImage} alt="Selected Imge" className="w-full h-auto rounded-lg" />
            <FaTimes className="absolute top-3 right-3 text-gray-500 cursor-pointer" onClick={closeImageModal} />
          </div>
        </div>
      )}
    </div>
  );
};

export default GalleryTabs;
