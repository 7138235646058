/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { server } from "../../server";
import Loader from "../Layout/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getAllProductsShop } from "../../redux/actions/product";
import MapContainer from "./GoogleMap"; // Import the MapContainer component
import { FaFacebook, FaXTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa6';
import { MdVerified } from "react-icons/md";
import { RiChatFollowUpLine } from "react-icons/ri";
import ShareOnSocial from "react-share-on-social";
import { FaArrowLeft, FaWhatsapp, FaEnvelope, FaGlobe } from "react-icons/fa";
import WhatsAppChat from "./whatsappChat";


const ShopInfo = ({ isOwner }) => {
  const [data, setData] = useState({});
  const { products } = useSelector((state) => state.products);
  const [isLoading, setIsLoading] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const fetchFollowStatus = async () => {
    try {
      console.log('Fetching', user);
      if (user) {
        const response = await axios.get(`${server}/shop/${id}/follow-status/${user._id}`);

        console.log('Setting2', id, response.data.isFollowing);
        setIsFollowing(response.data.isFollowing);
      }
    } catch (error) {
      console.error('Error fetching follow status:', error);
    }
  };

  const handleFollowToggle = async () => {
    try {
      if (user && id) {

        const response = await axios.post(`${server}/shop/${id}/toggle-follow`, { userId: user._id });
        console.log('Setting', id, response.data.following.isFollowing);
        setIsFollowing(response.data.following.isFollowing);
      } else {
        navigate('/login');
      }
    } catch (error) {
      console.error('Error toggling follow status:', error);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const shopInfoResponse = await axios.get(`${server}/shop/get-shop-info/${id}`);
        setData(shopInfoResponse.data.shop);
        setIsLoading(false);
        dispatch(getAllProductsShop(id));
        if (user) {
          await fetchFollowStatus();
        }
      } catch (error) {
        console.error('Error fetching shop info:', error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch, id, user]);

  // Render Loader while loading
  if (isLoading) {
    return <Loader />;
  }



  const logoutHandler = async () => {
    axios.get(`${server}/shop/logout`, {
      withCredentials: true,
    });
    window.location.reload();
  };

  const totalReviewsLength =
    products &&
    products.reduce((acc, product) => acc + product.reviews.length, 0);

  const totalRatings =
    products &&
    products.reduce(
      (acc, product) =>
        acc +
        product.reviews.reduce(
          (sum, review) => sum + review.rating,
          0
        ),
      0
    );

  const averageRating = (totalRatings / totalReviewsLength) || 0;


  const handleBackClick = () => {
    navigate(-1); // Navigate to the previous page
  };


  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="w-full">
          <div className="w-full py-5 flex flex-col items-center justify-center">
            {/* Back Button */}
            <button
              onClick={handleBackClick}
              className="flex items-center mb-4 text-blue-500 hover:text-blue-700 transition-colors duration-300"
            >
              <FaArrowLeft className="mr-2" />
              <span>Back</span>
            </button>

            <img
              src={`${data.avatar?.url}`}
              alt=""
              className="w-[150px] h-[150px] object-cover rounded-full mb-4"
            />

            <div className="flex items-center justify-between">
              {/* Verified Icon */}
              {data.verified ? (
                <button className="flex items-center bg-transparent border-blue-500 border-solid border rounded-md py-1 px-3 mb-2 ml-5 hover:bg-green-500 hover:text-white transition-colors duration-300" style={{ borderColor: '#0080FF' }}>
                  <MdVerified className="text-blue-500 mr-1" style={{ fontSize: '1.5rem' }} />
                  <span className="text-gray-600 text-sm font-semibold">Verified</span>
                </button>
              ) : null}
              {user &&
                <button
                  className={`flex items-center bg-transparent border-blue-500 border-solid border rounded-md py-1 px-3 mb-2 ml-5 hover:bg-orange-100 hover:text-white transition-colors duration-300 ${isFollowing ? 'bg-green-500 text-white' : ''}`}
                  style={{ borderColor: '#ffa500' }}
                  onClick={handleFollowToggle}
                >
                  <RiChatFollowUpLine className="text-yellow-500" style={{ fontSize: '1.5rem' }} />
                  <span className="text-gray-600 text-sm font-semibold"> {isFollowing ? ' Following' : ' Follow'}</span>
                </button>
              }
              {/* </div> */}
            </div>
            {/* Follow Us Icons */}
            <div className="flex items-center mt-2">
              <ShareOnSocial
                textToShare="Indazetu Manufacturers"
                link="https://favicon.io/"
                linkTitle="Indazetu"
                linkMetaDesc="By understanding these potential causes and applying the appropriate solutions"
                linkFavicon="/favicon.ico"
                noReferer
              >
                <button className="bg-transparent-500 hover:bg-blue-200 text-blue font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center space-x-2">
                  <span>Share</span>
                  <div className="flex space-x-1">
                    <FaFacebook className="text-blue-500 cursor-pointer hover:text-blue-600" />
                    <FaXTwitter className="text-blue-400 cursor-pointer hover:text-blue-500" />
                    <FaInstagram className="text-pink-500 cursor-pointer hover:text-pink-600" />
                    <FaLinkedin className="text-blue-300 cursor-pointer hover:text-blue-400" />
                    <FaWhatsapp className="text-green-400 cursor-pointer hover:text-green-500" />
                  </div>
                </button>
              </ShareOnSocial>
            </div>
            
            <WhatsAppChat whatsAppNumber={data?.whatsAppNumber}/>
          </div>

          <div className="border-t border-gray-200 mt-5 py-1 px-3">
            <h3 className="text-center text-xl font-semibold">{data.name}</h3>
            <p className="text-center text-sm text-gray-600">{data?.description}</p>
          </div>
          <div className="border-t border-gray-200 mt-5 py-1 px-3">
            <div className="flex items-center justify-center space-x-2">
              <FaEnvelope className="text-gray-600" />
              <a href={`mailto:${data?.email}`} className="text-blue-500 hover:underline">
                {data?.email}
              </a>
            </div>
            <div className="flex items-center justify-center space-x-2 mt-1">
              <FaGlobe className="text-gray-600" />
              <a href={`${data.website}`} target="_blank" rel="noopener noreferrer" className="text-sm text-gray-600 hover:underline">
                {data?.website}
              </a>
            </div>
          </div>
          <div className="border-t border-gray-200 mt-5 py-1 px-3">
            <h5 className="font-semibold">Address</h5>
            <p className="text-gray-600">{data?.address}</p>
            {/* Google Maps Integration */}
            {/* {JSON.stringify(data.location)} */}
            <div className="mt-3 h-64">
              <MapContainer address={data?.location} />
            </div>
          </div>
          <div className="border-t border-gray-200 mt-5 py-1 px-3">
            <h5 className="font-semibold">Phone Number</h5>
            <p className="text-gray-600">+{data?.phoneNumber}</p>
          </div>
        
          {/* Add Opening and Closing Hours */}
          <div className="border-t border-gray-200 mt-5 py-1 px-3">
            <h5 className="font-semibold">Opening Hours</h5>
            <p className="text-gray-600">{data?.workingHours?.openingTime}-{data?.workingHours?.closingTime} ({data?.workingHours?.days})</p>
          </div>

          <div className="border-t border-gray-200 mt-5 py-1 px-3">
            <h5 className="font-semibold">Total Products</h5>
            <p className="text-gray-600">{products && products.length}</p>
          </div>
          <div className="border-t border-gray-200 mt-5 py-1 px-3">
            <h5 className="font-semibold">Shop Ratings</h5>
            <p className="text-gray-600">{averageRating.toFixed(1)}/5</p>
          </div>
          <div className="border-t border-gray-200 mt-5 py-1 px-3">
            <h5 className="font-semibold">Joined On</h5>
            <p className="text-gray-600">{data?.createdAt?.slice(0, 10)}</p>
          </div>
          {isOwner && (
            <div className="py-3 px-4">
              <Link to="/settings">
                <div className="button">Edit Shop</div>
              </Link>
              <div
                className="button"
                onClick={logoutHandler}
              >
                Log Out
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ShopInfo;
