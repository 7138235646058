import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ShopCreate from "../components/Shop/ShopCreate";
import { Helmet } from 'react-helmet';

const ShopCreatePage = () => {
  const navigate = useNavigate();
  const { isSeller, seller } = useSelector((state) => state.seller);

  useEffect(() => {
    if(isSeller === true){
      navigate(`/shop/${seller._id}`);
    }
  }, [isSeller, seller, navigate]);

  return (
    <div>
      <Helmet>
        <title>Open Your Shop - Indazetu</title>
        <meta name="description" content="Start your own online shop on Indazetu and reach a wide audience. Join us and become a seller today!" />
        <meta name="keywords" content="open a shop, start online shop, become a seller, sell online, Indazetu shop, ecommerce platform" />
        <meta property="og:title" content="Open Your Shop - Indazetu" />
        <meta property="og:description" content="Start your own online shop on Indazetu and reach a wide audience. Join us and become a seller today!" />
        <meta property="og:image" content="https://indazetu.com/static/media/logoo-removebg-preview.041ee5fc1e771ab093a6.png" />
        <meta property="og:url" content="https://indazetu.com/shop-create" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://indazetu.com/shop-create" />
      </Helmet>
      <ShopCreate />
    </div>
  )
}

export default ShopCreatePage