import { useState, useEffect } from 'react';
import axios from 'axios'; // You can use axios or fetch API for making HTTP requests
import{ server }from '../server';

const useShop = (shopID) => {
  const [shop, setShop] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchShop = async () => {
      try {
        const response = await axios.get(`${server}/shop/get-shop-info/${shopID}`); 
        setShop(response.data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    if (shopID) {
      fetchShop();
    }
  }, [shopID]);

  return { shop, loading, error };
};

export default useShop;
