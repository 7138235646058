// Import React and any other necessary dependencies
import { useEffect, useState } from 'react';
import NearestPickupLocation from './NearestPickUpLocation'; // Make sure to import your components
import PackagingModal from './PackagingModal'
import { FaBox, FaTimes } from 'react-icons/fa';

const ModalContent = ({
  product,
  index,
  packagingQuantities,
  totalCost,
  deliveryType,
  nearestPickupLocation,
  handleDeliveryType,
  selectedProducts,
  address,
  city,
  county,
  handlePriceSelection,
  selectedProductPrice,
  discountPrice,
  totalProductCost,
  setNearestPickupLocation,
  handleSubmit,
  handleCouponCodeChange,
  packageTotal,
  shopItem,
  shop,
  couponDiscount,
  setCouponDiscount,
  handleDiscountChange,
  selectedPackaging,
  setSelectedPackaging,
  setSelectedPackagingTotal
}) => {

  const [isModalOpen, setIsModalOpen] = useState(false);


  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const [pickupDetails, setPickupDetails] = useState({});
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);

  // const handleDeliveryType = (type, key) => {
  //   setDeliveryType((prev) => ({ ...prev, [key]: type }));
  // };

  const openLocationModal = () => {
    setIsLocationModalOpen(true);
  };

  const closeLocationModal = () => {
    setIsLocationModalOpen(false);
  };


  useEffect(() => {
    const discount = discountPrice[`${shopItem._id}-${product._id}`]
      ? discountPrice[`${shopItem._id}-${product._id}`].toFixed(2)
      : '0.00';
    handleDiscountChange(product._id, discount);
  }, []);


  return (
    <>
      <div className="text-center mb-8">
        <hr className="my-2 border-gray-300" />

        <div className="flex justify-center items-center mt-4 mb-6" key={`${product._id}-${index}`}>
          <label className="inline-flex items-center mr-6">
            <input
              type="radio"
              id={`location-${product._id}-${index}`}
              name={`location-${product._id}-${index}`}
              value="location"
              checked={deliveryType[`${product._id}-${index}`] === 'location'}
              onChange={() => handleDeliveryType('location', `${product._id}-${index}`)}
              className="mr-2"
            />
            <span className="text-sm font-medium text-gray-600 cursor-pointer">Pickup Station</span>
          </label>
          <label className="inline-flex items-center">
            <input
              type="radio"
              id={`doorDelivery-${product._id}-${index}`}
              name={`doorDelivery-${product._id}-${index}`}
              value="doorDelivery"
              checked={deliveryType[`${product._id}-${index}`] === 'doorDelivery'}
              onChange={() => handleDeliveryType('doorDelivery', `${product._id}-${index}`)}
              className="mr-2"
            />
            <span className="text-sm font-medium text-gray-600 cursor-pointer">Door Delivery</span>
          </label>
        </div>
        {/* {JSON.stringify(selectedProducts)} */}
        {deliveryType[`${product._id}-${index}`] === 'location' && (
          <>
            {pickupDetails ? (
              <>
              <strong className='text-orange-800'>{pickupDetails.stationName}</strong>
              <button onClick={openLocationModal} className="text-blue-500 underline">Select Nearest Pickup Location</button>
              </>
            ) : selectedProducts.some(group =>
              group.label.some(selectedProduct => selectedProduct.value === product._id)
            ) ? (
              <button onClick={openLocationModal} className="text-blue-500 underline">Select Nearest Pickup Location</button>
            ) : (
              <h3>This product is not selected for pickups.</h3>
            )}
          </>
        )}

        {deliveryType[`${product._id}-${index}`] === 'doorDelivery' && (
          <div className="w-full mb-4">
            <h3 className="text-sm font-medium text-gray-600">Your Address:</h3>
            <input
              type="text"
              className="w-full border h-10 rounded-md px-3 focus:outline-none"
              value={address}
              readOnly
            />
          </div>
        )}


        {isLocationModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white p-4 rounded-lg shadow-lg w-11/12 md:w-1/2 lg:w-1/3 relative">
              <button onClick={closeLocationModal} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
                <FaTimes />
              </button>
              <NearestPickupLocation
                address={address}
                selectedLocations={selectedProducts}
                nearestPickupLocation={null}
                productId={product._id}
                product={product}
                deliveryType={null}
                selectedProducts={selectedProducts}
                city={city}
                index={index}
                county={county}
                currency={shopItem?.currency}
                handlePriceSelection={handlePriceSelection}
                setPickupDetails={setPickupDetails}
              />
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-center items-center border-b pb-1 mb-1">
        <div key={product.id} className="overflow-x-auto w-full">
          {product.packaging.length === 0 ? (
            <p className="text-center text-gray-500 uppercase">Free Packaging</p>
          ) : (
            <div className="flex flex-col items-center pb-1 mb-1">
              <div className="w-full flex items-center justify-center">
                <button
                  onClick={handleOpenModal}
                  className="block w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-10 rounded shadow leading-tight focus:outline-none focus:shadow-outline flex items-center justify-center space-x-2"
                >
                  <FaBox className="text-blue-500" />
                  <span>{selectedPackaging[product._id] ? `${selectedPackaging[product._id].type} @ ${shopItem?.currency} ${selectedPackaging[product._id].price}` : 'Select packaging'}</span>
                </button>
              </div>
              {isModalOpen && (
                <PackagingModal
                  packagingOptions={product.packaging}
                  onClose={handleCloseModal}
                  setSelectedPackaging={setSelectedPackaging}
                  productId={product._id}
                  productQty={product.qty}
                  currency={shopItem?.currency}
                  setSelectedPackagingTotal={setSelectedPackagingTotal}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div className="text-center mb-8">
        <h5 className="text-lg font-semibold">Payment Details</h5>
        <hr className="my-2 border-gray-300" />

        <div className="flex justify-between mb-1">
          <h3 className="text-base font-medium text-gray-600">Subtotal:</h3>
          <h5 className="text-md font-semibold">{shopItem?.currency} .{totalProductCost.toFixed(2)}</h5>
        </div>
        <div className="flex justify-between mb-1">
          <h3 className="text-base font-medium text-gray-600">Shipping:</h3>
          <h5 className="text-md font-semibold">{shopItem.currency} .{selectedProductPrice[`${product._id}`] || 0}</h5>
        </div>
        <div className="flex justify-between mb-1">
          <h3 className="text-base font-medium text-gray-600">Packaging:</h3>
          {/* <h5 className="text-md font-semibold">{shopItem?.currency} .{totalCost || 0}</h5> */}
          <h5 className="text-md font-semibold">{shopItem?.currency} .{selectedPackaging[product._id]?.price || 0}</h5>
        </div>
        <div className="flex justify-between border-b mb-1">
          <h3 className="text-base font-medium text-gray-600">Discount:</h3>
          <h5 className="text-md font-semibold">- {shopItem?.currency} .{couponDiscount[product._id] || 0}</h5>
        </div>

        <h5 className="text-lg font-semibold text-right">

          {shopItem?.currency}.

          {(
            Number(totalProductCost) +
            Number(selectedProductPrice[`${product._id}`] || 0) +
            Number(selectedPackaging[product._id]?.price || 0) - Number(couponDiscount[`${product._id}`] || 0)
          ).toFixed(2)}
        </h5>

        <form onSubmit={(e) => handleSubmit(e, shopItem._id, product._id)} name={`coupon-code-${shopItem._id}-${product._id}`} className="mt-8">
          <input
            type="text"
            className="w-full h-10 pl-3 border rounded-md focus:outline-none"
            placeholder="Coupon code"
            name={`couponCode-${product._id}-${shopItem._id}`}
            id={`couponCode-${product._id}-${shopItem._id}`}
            onChange={(e) => handleCouponCodeChange(e, shopItem._id, product._id)}
            required
          />
          <button
            type="submit"
            className="w-full h-10 mt-3 text-white bg-red-500 rounded-md hover:bg-red-600 focus:outline-none"
          >
            Apply code
          </button>
        </form>
      </div>
    </>
  );
};
export default ModalContent;
