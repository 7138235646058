import React, { useState } from 'react';


  // Dummy data for Kenyan counties and towns/cities
  export const kenyanCounties = [
    'Baringo', 'Bomet', 'Bungoma', 'Busia', 'Elgeyo-Marakwet', 'Embu', 'Garissa', 'Homa Bay', 'Isiolo', 'Kajiado',
    'Kakamega', 'Kericho', 'Kiambu', 'Kilifi', 'Kirinyaga', 'Kisii', 'Kisumu', 'Kitui', 'Kwale', 'Laikipia',
    'Lamu', 'Machakos', 'Makueni', 'Mandera', 'Marsabit', 'Meru', 'Migori', 'Mombasa', 'Murang\'a', 'Nairobi', 'Nakuru',
    'Nandi', 'Narok', 'Nyamira', 'Nyandarua', 'Nyeri', 'Samburu', 'Siaya', 'Taita-Taveta', 'Tana River', 'Tharaka-Nithi',
    'Trans Nzoia', 'Turkana', 'Uasin Gishu', 'Vihiga', 'Wajir', 'West Pokot'
  ];

  export const kenyanCities = {
    'Baringo': ['Kabarnet', 'Eldama Ravine', 'Mogotio'],
    'Bomet': ['Bomet', 'Longisa'],
    'Bungoma': ['Bungoma', 'Kimilili', 'Webuye'],
    'Busia': ['Busia'],
    'Elgeyo-Marakwet': ['Iten', 'Eldoret', 'Kapsabet'],
    'Embu': ['Embu', 'Runyenjes'],
    'Garissa': ['Garissa', 'Hulugho', 'Ifo'],
    'Homa Bay': ['Homa Bay', 'Homa Bay Town', 'Kendu Bay'],
    'Isiolo': ['Isiolo', 'Moyale'],
    'Kajiado': ['Kajiado', 'Kiserian', 'Ngong'],
    'Kakamega': ['Kakamega', 'Lubao', 'Malava'],
    'Kericho': ['Kericho', 'Kapsoit', 'Litein'],
    'Kiambu': ['Kiambu', 'Githunguri', 'Kikuyu'],
    'Kilifi': ['Kilifi', 'Malindi', 'Mariakani'],
    'Kirinyaga': ['Kerugoya', 'Sagana'],
    'Kisii': ['Kisii', 'Suneka', 'Keroka'],
    'Kisumu': ['Kisumu', 'Ahero', 'Kondele'],
    'Kitui': ['Kitui', 'Mutomo', 'Mwingi'],
    'Kwale': ['Kwale', 'Lungalunga', 'Ukunda'],
    'Laikipia': ['Nanyuki', 'Rumuruti'],
    'Lamu': ['Lamu'],
    'Machakos': ['Machakos', 'Kangundo-Tala', 'Mwala'],
    'Makueni': ['Wote', 'Makindu'],
    'Mandera': ['Mandera', 'El Wak'],
    'Marsabit': ['Marsabit', 'Laisamis'],
    'Meru': ['Meru', 'Maua'],
    'Migori': ['Migori', 'Awendo'],
    'Mombasa': ['Mombasa', 'Likoni', 'Mtwapa'],
    'Murang\'a': ['Murang\'a', 'Maragua'],
    'Nairobi': ['Nairobi'],
    'Nakuru': ['Nakuru', 'Njoro', 'Naivasha'],
    'Nandi': ['Kapsabet', 'Mosoriot'],
    'Narok': ['Narok', 'Narok Town'],
    'Nyamira': ['Nyamira', 'Nyansiongo'],
    'Nyandarua': ['Ol Kalou', 'Njabini'],
    'Nyeri': ['Nyeri', 'Nanyuki'],
    'Samburu': ['Maralal'],
    'Siaya': ['Siaya', 'Ugunja'],
    'Taita-Taveta': ['Voi', 'Taveta'],
    'Tana River': ['Hola', 'Garsen'],
    'Tharaka-Nithi': ['Chuka', 'Kathwana'],
    'Trans Nzoia': ['Kitale', 'Kiminini'],
    'Turkana': ['Lodwar', 'Kakuma'],
    'Uasin Gishu': ['Eldoret', 'Langas'],
    'Vihiga': ['Vihiga', 'Mbale'],
    'Wajir': ['Wajir', 'Habaswein'],
    'West Pokot': ['Kapenguria', 'Lokichar']
  };
  


