import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { server } from '../../server';
import { Link, useParams, useNavigate } from "react-router-dom";
import moment from 'moment';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";

const ShopAdminPostList = ({ shop }) => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 5; // Number of posts per page
  const { seller } = useSelector((state) => state.seller);


  useEffect(() => {
    const fetchPosts = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${server}/shop/posts/${shop}`);
        console.log("Fetched Posts:", response.data.data);
        setPosts(response.data.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching posts:", error);
        setIsLoading(false);
      }
    };

    fetchPosts();
  }, [shop]);

  // Pagination
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentBlogPosts = posts
    .filter(post => post?.postType === 'blog')
    .slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Delete post
  const deletePost = async (postId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this post?");
    if (!confirmDelete) {
      return; // If the user cancels, exit the function
    }

    try {
      console.log('Deleting Post:', postId);
      await axios.delete(`${server}/shop/delete-post/${postId}`);

      // Remove the deleted post from the local state
      setPosts(posts.filter(post => post?._id !== postId));

      // Show success notification
      toast.success("Post deleted successfully!");
    } catch (error) {
      console.error("Error deleting post:", error);

      // Show error notification
      toast.error("Failed to delete the post.");
    }
  };

  return (
    <div className="container mx-auto p-4">
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <>
          {currentBlogPosts.length === 0 ? (
            <p class="text-center">No blog posts available.</p>
          ) : (
            <div className="grid gap-4 lg:grid-cols-3 md:grid-cols-2">
              {currentBlogPosts.map((post) => (
                <div key={post?._id} className="bg-white shadow-md rounded-lg p-4 cursor-pointer">
                  <Link to={`/shop/blog/${post?._id}`}>
                    <h3 className="text-xl font-semibold mb-2">{post?.title}</h3>
                    <p className="text-gray-600 mb-4">{moment(post?.createdAt).format('MMMM Do, YYYY')}</p>
                    <p className="text-gray-800" dangerouslySetInnerHTML={{ __html: post?.postContent }}></p>
                  </Link>
                  <div className="flex items-center space-x-4">
                    {post?.shop === seller?.id && (
                      <>
                        <button onClick={() => deletePost(post?._id)} className="bg-red-500 text-white py-1 px-2 rounded-full hover:bg-red-600">
                          Delete
                        </button>
                      </>
                    )}
                    <Link to={`/shop/blog/${post?._id}`} className="bg-blue-500 text-white py-1 px-2 rounded-full hover:bg-blue-600">
                      View Post
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          )}
          <Pagination postsPerPage={postsPerPage} totalPosts={posts.filter(post => post?.postType === 'blog').length} paginate={paginate} currentPage={currentPage} />
          <ToastContainer />
        </>)}
    </div>
  );
};

// Pagination Component
const Pagination = ({ postsPerPage, totalPosts, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav className="mt-4">
      <ul className="pagination flex justify-center">
        {pageNumbers.map(number => (
          <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
            <button onClick={() => paginate(number)} className="page-link py-2 px-4 mx-1 rounded-full bg-gray-200 hover:bg-gray-300">
              {number}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default ShopAdminPostList;
