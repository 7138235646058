import React from 'react'
import Header from '../components/Layout/SecondaryHeader'
import Footer from '../components/Layout/SecondaryFooter'
import SingleBlog from '../components/Shop/SingleBlog'

const ShopBlogPage = () => {
  return (
    <div>
      <Header />
      <SingleBlog />
      <Footer />
    </div>
  )
}

export default ShopBlogPage