import React, { useState } from "react";
import { AiOutlineGift } from "react-icons/ai";
import { MdOutlineLocalOffer } from "react-icons/md";
import { FiPackage, FiShoppingBag } from "react-icons/fi";
import { BiMessageSquareDetail } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { server } from "../../../server";

const DashboardHeader = () => {
  const { seller } = useSelector((state) => state.seller);
  const message = JSON.parse(localStorage.getItem("allMessage"));
  const unreadMessage = JSON.parse(localStorage.getItem("unreadMessage"));
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Dropdown state
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {

    axios.get(`${server}/shop/logout`, {
      withCredentials: true,
    })
      .then((res) => {
        toast.success(res.data.message);
        window.location.reload();
      })
      .catch((error) => {
        console.log('error', error)
        toast.error(error.response.data.message);
      });

  };




  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen); // Toggle dropdown visibility
  };

  return (
    <div className="w-full h-[80px] bg-white shadow sticky top-0 left-0 z-30 flex items-center justify-between px-4">
      <div className="relative">
        <Link to="/dashboard">
          <img
            src='https://indazetu.com/static/media/logoo.df0812764786930e8165.png'
            alt="logo"
            className="w-30 h-14 md:w-24 md:h-16 lg:w-18 lg:h-12 object-cover cursor-pointer"
            onClick={toggleDropdown}
          />
        </Link>
      </div>
      <div className="flex items-center">
        <div className="flex items-center mr-4">
          <Link to="/dashboard/coupons" className="hidden 800px:block">
            <AiOutlineGift color="#555" size={30} className="mx-5 cursor-pointer" />
          </Link>
          <Link to="/dashboard-events" className="hidden 800px:block">
            <MdOutlineLocalOffer color="#555" size={30} className="mx-5 cursor-pointer" />
          </Link>
          <Link to="/dashboard-products" className="hidden 800px:block">
            <FiShoppingBag color="#555" size={30} className="mx-5 cursor-pointer" />
          </Link>
          <Link to="/dashboard-orders" className="hidden 800px:block">
            <FiPackage color="#555" size={30} className="mx-5 cursor-pointer" />
          </Link>
          <Link to="/dashboard-messages" className="hidden 800px:block relative">
            <BiMessageSquareDetail color="#555" size={30} className="mx-5 cursor-pointer" />
            <span className="absolute right-0 top-0 rounded-full bg-orange-400 w-4 h-4 p-0 m-0 text-white font-mono text-[12px] leading-tight text-center">
              {unreadMessage.length}
            </span>
          </Link>
        </div>

        {/* Seller Avatar and Dropdown */}
        <div className="relative">
          <img
            src={seller?.avatar?.url || "/assets/images/default-avatar.png"}
            alt="Seller Avatar"
            className="w-[50px] h-[50px] rounded-full object-cover cursor-pointer"
            onClick={toggleDropdown}
          />

          {isDropdownOpen && (
            <div className="absolute right-0 mt-2 w-[150px] bg-white border border-gray-200 rounded-lg shadow-lg z-50">
              <Link
                to={`/shop/${seller._id}`}
                className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                onClick={() => setIsDropdownOpen(false)}
              >
                Profile
              </Link>
              <button
                onClick={handleLogout}
                className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
