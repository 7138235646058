import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { server } from '../../server';


const ShopAdminVideoList = ({ shop }) => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(`${server}/shop/videos/${shop}`);
        console.log('bility3', shop, response.data.data);

        setVideos(response.data.data);
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };

    fetchVideos();
  }, []);

  return (
    <div>
    <h2 className="text-2xl font-bold mb-4">Video List</h2>
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
      {videos.map((video) => (
        <div key={video._id} className="relative">
          <iframe
            title="video preview"
            src={video.video}
            frameborder="0"
            allowfullscreen
            className="w-full h-full"
          ></iframe>
        </div>
      ))}
    </div>
  </div>
  );
};

export default ShopAdminVideoList;
