import firebase from 'firebase/compat/app';
import { initializeApp } from "firebase/app";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getStorage } from "firebase/storage";
import { getAnalytics } from 'firebase/analytics';



// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyA7Ewb3skXrx7RBAZjzINLSi8TdwrEDnxc",

//   authDomain: "flyevents-f5f7c.firebaseapp.com",

//   databaseURL: "https://flyevents-f5f7c.firebaseio.com",

//   projectId: "flyevents-f5f7c",

//   storageBucket: "flyevents-f5f7c.appspot.com",

//   messagingSenderId: "755208812806",

//   appId: "1:755208812806:web:475f4aaa490de6d4798687",

//   measurementId: "G-2ZB3X6443C"

// };


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAmHeDvAWu-nBgUT0oaqwGOFY_juw1M-ew",
  authDomain: "indazetuanalytics.firebaseapp.com",
  projectId: "indazetuanalytics",
  storageBucket: "indazetuanalytics.appspot.com",
  messagingSenderId: "184003694712",
  appId: "1:184003694712:web:7b0f0d65f72c92ead3dd8d",
  measurementId: "G-QRHB87ZNXN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();
const storage = getStorage(firebaseApp);
const analytics = getAnalytics(app);

export { auth, provider,firebaseConfig,storage,analytics};
export default db;