// ConversationList.js
import React from 'react';
import ConversationItem from './ConversationItem';
import { FaExpand, FaCompress } from 'react-icons/fa';

const ConversationList = ({ conversations, click, selectedConversation }) => {
  const [isMinimized, setIsMinimized] = React.useState(false);

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  return (
    <div className={`w-auto h-auto bg-white shadow-sm overflow-x-auto sticky top-0 left-0 z-10 p-2 ${isMinimized ? 'md:w-[80px] h-[40px]' : ''}`}>
    <button className="top-4 right-4 text-black py-2 px-4 rounded-lg hover:bg-gray-600" onClick={toggleMinimize}>
      {isMinimized ? <FaExpand /> : <FaCompress />}
    </button>

    {/* Mobile View */}
    <div className="flex overflow-x-auto md:hidden"> {/* Hide on medium and larger screens, flex for row layout, horizontal scroll */}
      <ul className="flex flex-nowrap space-x-2 px-2 py-1"> {/* Added spacing and padding for better look */}
        {selectedConversation && !conversations.some(convo => convo.participants === selectedConversation.participants) && (
          <li className="bg-gray-200 rounded-md p-2 shadow-sm"> {/* Individual conversation styling */}
            <ConversationItem
              key="selected"
              conversation={selectedConversation}
              onClick={click}
              selectedConversation={selectedConversation}
              isMinimized={isMinimized}
            />
          </li>
        )}
        {conversations?.map((conversation, index) => (
          <li key={index} className="bg-gray-200 rounded-md p-2 shadow-sm"> {/* Individual conversation styling */}
            <ConversationItem
              conversation={conversation}
              onClick={click}
              selectedConversation={selectedConversation}
              isMinimized={isMinimized}
            />
          </li>
        ))}
      </ul>
    </div>

    {/* Desktop View */}
    <ul className="md:block hidden"> {/* Show only on medium and larger screens, hidden on mobile */}
      {selectedConversation && !conversations.some(convo => convo.participants === selectedConversation.participants) && (
        <li>
          <ConversationItem
            key="selected"
            conversation={selectedConversation}
            onClick={click}
            selectedConversation={selectedConversation}
            isMinimized={isMinimized}
          />
        </li>
      )}
      {conversations?.map((conversation, index) => (
        <li key={index}>
          <ConversationItem
            conversation={conversation}
            onClick={click}
            selectedConversation={selectedConversation}
            isMinimized={isMinimized}
          />
        </li>
      ))}
    </ul>
  </div>
  );
};

export default ConversationList;
