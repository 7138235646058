import React, { useState } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';


const LocationSearchInput = ({onSelect}) => {
  const [address, setAddress] = useState('');

  const handleChange = (newValue) => {
    setAddress(newValue);
  };

  const handleSelect = async (newValue) => {
    
    try {
      const results = await geocodeByAddress(newValue);
      const latLng = await getLatLng(results[0]);
      const data={geocodeByAddress:results,coordinates:latLng,location:newValue}
      setAddress(newValue);
      onSelect(data); // Call onSelect with the selected address
      console.log('Success', latLng);
    } catch (error) {
      console.error('Error', error);
    }
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <input
            {...getInputProps({
              placeholder: 'Search Places ...',
              className: 'location-search-input',
            })}
          />
          <div className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion) => {
              const className = suggestion.active
                ? 'suggestion-item--active'
                : 'suggestion-item';
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                : { backgroundColor: '#ffffff', cursor: 'pointer' };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default LocationSearchInput;
