import { useEffect, useState, useCallback } from 'react';
import { onSnapshot, query, orderBy, collection, limit, startAfter, getDocs, where } from 'firebase/firestore';
import db from "../firebase";
import { useSelector } from "react-redux";

const useLoadMoreConversation = (participants,lastIndex) => {

  console.log('participants',participants);
  const [conversations, setConversations] = useState([]);
  const { user } = useSelector((state) => state.user); // Assuming you have user state in Redux
  const currentUsers = user;
  const selectedParticipant = participants;

  const loadMoreConversations = async () => {

    console.log('complete',selectedParticipant);
    if (!currentUsers || !selectedParticipant) return;

    try {

      ///sender and recipient in order
      console.log('Loading more conversations',conversations);
      const q = query(
        collection(db, 'messages'),
        orderBy('createdAt', 'desc'),
        where('user._id', '==', selectedParticipant.split('-')[0]), // Assuming user._id is the first element
        where('user.recipient.id', '==', selectedParticipant.split('-')[1]), // Assuming user.recipient.id is the second element
       // startAfter(lastIndex),
        limit(140)
      );


      const snapshot = await getDocs(q);
      const conversationData = [];
      let currentUser_id = currentUsers._id;
      snapshot.forEach((doc) => {
        const messageData = doc.data();
        const senderId = messageData.user?._id;
        const recipientId = messageData.user.recipient?.id;
        const isParticipant = senderId === currentUser_id || recipientId === currentUser_id;
        if (isParticipant) {
          conversationData.push({
            id: doc.id,
            sender: messageData.user,
            messages: [
              {
                id: doc.id,
                text: messageData?.text,
                file: messageData?.file,
                createdAt: messageData?.createdAt,
                read: messageData?.read || false,
              },
            ],
          });
        }
      });

      const groupedConversations = newGroupConversations(conversationData);

      console.log('Grouped conversations', conversationData);

      if (groupedConversations.length > 0) {
        setConversations([...groupedConversations]);
      } else {
        console.error('No more conversations to load');
      }
    } catch (error) {
      console.error('Error loading more conversations:', error);
    }
  };


  return [conversations, loadMoreConversations];
};

const newGroupConversations = (conversationData) => {
  const groupedConversations = {};
  conversationData.forEach((conversation) => {
    const participants = [conversation?.sender?._id, conversation?.sender?.recipient?.id];
    participants.sort(); // Sort participants to ensure consistent key
    const key = participants.join("-");
    if (!groupedConversations[key]) {
      groupedConversations[key] = {
        id: conversation.id,
        sender: conversation.sender,
        participants: key,
        messages: [],
      };
    }
    conversation.messages.forEach((message) => {
      groupedConversations[key].messages.push({
        id: message.id,
        text: message.text,
        file: message.file,
        createdAt: message.createdAt,
        read: message.read
      });
    });
  });
  return Object.values(groupedConversations);
};
export default useLoadMoreConversation;
