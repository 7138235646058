import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import styles from "../styles/styles";


const AboutUsPage = () => {
  return (
    <div>
      <Helmet>
        <title>About Us - Indazetu</title>
        <meta name="description" content="Learn more about Indazetu, our mission, vision, and the services we provide to connect suppliers and buyers globally." />
      </Helmet>
      <Header activeHeading={5} />
      <AboutUs />
      <Footer />
    </div>
  );
};


const AboutUs = () => {
  return (
    <div className="about-us-page p-8 bg-gray-50 text-gray-800">

      <div className="container mx-auto">
        <header className="text-center mb-12">
          <h1 className="text-4xl font-bold text-green-600">About Indazetu</h1>
          <p className="text-lg text-gray-600 mt-4">Connecting Suppliers and Buyers Globally</p>
        </header>

        <section className="mb-12">
          <h2 className="text-3xl font-semibold text-gray-800">Our Mission</h2>
          <p className="mt-4 text-gray-700">
            At Indazetu, our mission is to facilitate seamless business interactions between suppliers and buyers across the globe. We aim to provide a robust platform that supports the negotiation, sale, and supply of goods and services, fostering connections that lead to successful business partnerships.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-3xl font-semibold text-gray-800">Our Vision</h2>
          <p className="mt-4 text-gray-700">
            Our vision is to become the leading global platform for business-to-business interactions, enabling suppliers and buyers to connect, negotiate, and transact with confidence and ease. We strive to create a trusted and dynamic marketplace that empowers businesses to grow and succeed.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-3xl font-semibold text-gray-800">What We Offer</h2>
          <ul className="mt-4 text-gray-700 list-disc list-inside">
            <li className="mb-2">
              <span className="font-bold text-gray-800">Leading Supplier:</span> Priority listing within categories of their choice, increasing visibility of their products.
            </li>
            <li className="mb-2">
              <span className="font-bold text-gray-800">Star Supplier:</span> Add-on service providing priority listing in chosen categories, resulting in increased leads and enquiries.
            </li>
            <li className="mb-2">
              <span className="font-bold text-gray-800">TrustSEAL:</span> Verification seal for businesses, adding credibility and trustworthiness to their profiles.
            </li>
            <li className="mb-2">
              <span className="font-bold text-gray-800">Maximiser:</span> Specialized feature package to maximize return on investment.
            </li>
            <li className="mb-2">
              <span className="font-bold text-gray-800">Mini Dynamic Catalog:</span> Professionally designed catalog with independent control for updates.
            </li>
            <li className="mb-2">
              <span className="font-bold text-gray-800">Verified Users:</span> Verification of primary and secondary contact details to ensure authenticity.
            </li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-3xl font-semibold text-gray-800">Our Commitment</h2>
          <p className="mt-4 text-gray-700">
            We are committed to providing a secure and efficient platform for business interactions. We prioritize the quality, safety, and authenticity of the products and services listed on Indazetu, and we are dedicated to fostering a trustworthy marketplace for all users.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-3xl font-semibold text-gray-800">Join Us</h2>
          <p className="mt-4 text-gray-700">
            Whether you are a supplier looking to expand your reach or a buyer seeking reliable partners, Indazetu is here to support your business goals. Join our platform today and start connecting with businesses around the world.
          </p>
          <div className="mt-6">
            <Link to="/sign-up" className="px-6 py-2 bg-green-600 text-white rounded-md hover:bg-green-700">
              Sign Up
            </Link>
          </div>
        </section>

        <footer className="text-center mt-12">
          <p className="text-gray-600">&copy; {new Date().getFullYear()} Indazetu. All rights reserved.</p>
        </footer>
      </div>
    </div>
  );
};

export default AboutUsPage;
