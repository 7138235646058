import React, { useState, useEffect } from "react";
import { RxCross1 } from "react-icons/rx";
import {  useSelector } from "react-redux";
import { toast } from "react-toastify";
import { IoMdChatboxes } from "react-icons/io";
import DashboardMessages from "../../pages/UserInbox/DashboardMessages";

const Message = ({ setOpenMessage }) => {
  const { message } = useSelector((state) => state.message);


  return (
    <div className="fixed top-0 left-0 w-full bg-[#0000004b] h-screen z-10">
      <div className="fixed top-0 right-0 h-full w-full md:w-[80%] lg:w-[25%] max-w-[800px] bg-white flex flex-col overflow-y-scroll justify-between shadow-sm">
        {message && message.length === 0 ? (
          <div className="w-full h-screen flex items-center justify-center">
            <div className="flex w-full justify-end pt-5 pr-5 fixed top-3 right-3">
              <RxCross1
                size={25}
                className="cursor-pointer"
                onClick={() => setOpenMessage(false)}
              />
            </div>
            <h5>Message Items is empty!</h5>
          </div>
        ) : (
          <>
            <div>
              <div className="flex w-full justify-end pt-5 pr-5">
                <RxCross1
                  size={25}
                  className="cursor-pointer"
                  onClick={() => setOpenMessage(false)}
                />
              </div>
              <div className="flex items-center p-4">
                <IoMdChatboxes size={25} />
                <h5 className="pl-2 text-[20px] font-[500]">
                  {message && message.length} messages
                </h5>
              </div>
              <div className="w-full border-t">
                <DashboardMessages />
              </div>
            </div>
          </>
        )}
      </div>
    </div>

  );
};


export default Message;
