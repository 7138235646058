import React from 'react'

import Footer from '../components/Layout/Footer'
import Header from '../components/Layout/Header'
import ResetPassword from '../components/ForgotPassword/ResetPassword'

const ResetPasswordPage = () => {
  return (
    <div className='w-full min-h-screen bg-[#f6f9fc]'>
       <Header />
     
       <ResetPassword />
       <br />
       <br />
       <Footer />
    </div>
  )
}

export default ResetPasswordPage;