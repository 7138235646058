import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MdMessage } from 'react-icons/md';
import TextSuggestionsHelper from './TextSuggestionsHelper'; // Ensure the path is correct
import useNotification from '../../hooks/useNotification';


const MessageModal = ({ seller, isOpen, onClose, type }) => {
  const [messageText, setMessageText] = useState('');
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const {
    loading,
    error,
    success,
    sendEmailNotification,
    sendSMSNotification,
  } = useNotification();


  // const handleEmailSubmit = (e) => {
  //   e.preventDefault();
  //   sendEmailNotification(sender, subject, content);
  // };

  // const handleSMSSubmit = (e) => {
  //   e.preventDefault();
  //   sendSMSNotification(recipient, message);
  // };

  const handleMessageSubmit = (e) => {
    e.preventDefault(); // Prevent form submission
    if (isAuthenticated) {
      const userId = user?._id;
      const sellerId = seller?._id;
      const subject = "New Message";
      const order=null;
      const message = messageText;

      if (userId && sellerId) {
        console.log('skales', seller);
        sendEmailNotification(seller.email, subject, message,order);
        navigate('/inbox', {
          state: { participants: `${userId}-${sellerId}`, recipient: seller, sender: user, messageText },
        });
        
        
      } else {
        console.error('User ID or Seller ID is missing');
      }
    } else {
      console.error('User is not authenticated');
    }
    onClose();
  };

  const handleSuggestionClick = useCallback((suggestion) => {
    setMessageText(suggestion);
  }, []);

  let suggestions = [];
  if (type === 'productDetail') {
    suggestions = [
      'Is this product currently in stock?',
      'How long will it take for the item to be delivered?',
      'What are the shipping charges for this product?',
      'What payment methods do you accept?',
      'Does this product come with a warranty?',
      'What is your return policy?',
      'Are there any discounts available for bulk purchases?',
      'Can this product be customized?',
      'Can you provide more details about the product specifications?',
      'How can I track my order after it\'s shipped?',
      'Do you offer international shipping?',
      'Do you offer gift wrapping services?',
      'How does this product compare to similar items in your store?',
      'Can you share customer reviews or testimonials for this product?',
      'Do you provide after-sales service for this product?'
    ];
  } else {
    suggestions = [
      'What are your operating hours?',
      'Can I get a discount?',
      'Do you offer delivery services?',
      'What is the return policy?',
      'Are there any ongoing promotions?'
    ];
  }

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg p-6 max-w-lg w-full relative">
            <button onClick={onClose} className="absolute top-0 right-0 p-2">
              <svg
                className="w-6 h-6 text-gray-500 hover:text-gray-700"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <h2 className="text-xl font-semibold mb-4">Send a Message</h2>
            <form onSubmit={handleMessageSubmit} className="space-y-4">
              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows={6}
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder="  Briefly describe what you need from the Supplier..."
                  value={messageText}
                  onChange={(e) => setMessageText(e.target.value)}
                  required
                />
              </div>
              <TextSuggestionsHelper
                suggestions={suggestions}
                onSuggestionClick={handleSuggestionClick}
              />
              <button
                type="submit"
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default MessageModal;
