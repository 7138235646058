import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { server } from "../server";

const ActivationPage = () => {
  const { activation_token } = useParams();
  const [error, setError] = useState(false);

  useEffect(() => {
    if (activation_token) {
      const sendRequest = async () => {
        await axios
          .post(`${server}/user/activation`, {
            activation_token,
          })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            setError(true);
          });
      };
      sendRequest();
    }
  }, []);

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <img src="/static/media/logoo-removebg-preview.041ee5fc1e771ab093a6.png" alt="Logo" style={styles.logo} />
        <p style={styles.message}>
          {error ? 'Your token is expired!' : 'Your account has been created successfully!'}
        </p>
        {!error && (
          <div style={styles.loginSection}>
            <p style={styles.loginMessage}>Welcome :</p>
            <a href="/login" style={styles.loginButton}>
             You can now Log In.
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f0f2f5',
    fontFamily: "'Helvetica Neue', Arial, sans-serif"
  },
  card: {
    textAlign: 'center',
    padding: '40px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
  },
  logo: {
    width: '100px',
    marginBottom: '20px',
  },
  message: {
    fontSize: '18px',
    color: '#333',
  },
  loginSection: {
    marginTop: '20px',
  },
  loginMessage: {
    fontSize: '16px',
    color: '#333',
  },
  loginButton: {
    display: 'inline-block',
    marginTop: '10px',
    padding: '10px 20px',
    fontSize: '16px',
    color: '#fff',
    backgroundColor: '#007BFF',
    borderRadius: '5px',
    textDecoration: 'none',
    transition: 'background-color 0.3s ease',
  },
  loginButtonHover: {
    backgroundColor: '#0056b3',
  },
};


export default ActivationPage;

