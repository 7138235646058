
import React, { useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Select from 'react-select';
import { kenyanCounties, kenyanCities } from '../Shop/kenyanCounties';
import { AiOutlineHome } from 'react-icons/ai';
import { FaAddressBook } from 'react-icons/fa';

const AddressForm = ({
    county,
    setCounty,
    city,
    setCity,
    address,
    setAddress,
    primaryPhoneNumber,
    setPrimaryPhoneNumber,
    secondaryPhoneNumber,
    setSecondaryPhoneNumber,
    additionalInformation,
    setAdditionalInformation,
    addressType,
    setAddressType,
    userInfo,
    setUserInfo,
    user,
    addressTypeError,
    cityError,
    countyError,
    addressError,

}) => {

    const handleSelect = async (address) => {
        setAddress(address);
        console.log('Address selected:', address);
        // Additional logic to handle coordinates if needed
    };

    const addressTypeData = [
        { name: 'Home' },
        { name: 'Work' },
        { name: 'Other' },
    ];
    const [selectedAddressType, setSelectedAddressType] = useState("");

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-4 pb-3">
            <div>
                <label htmlFor="primaryPhoneNumber" className="block text-sm font-medium text-gray-700">Primary Phone Number <span className="text-red-500">*</span></label>
                <div className="flex items-center">
                    <span className="mr-2">+254 </span>
                    <input
                        id="primaryPhoneNumber"
                        type="tel"
                        pattern="[0-9]{10}"
                        value={primaryPhoneNumber}
                        onChange={(e) => setPrimaryPhoneNumber(e.target.value)}
                        placeholder="Phone Number"
                        className="input"
                    />
                </div>

            </div>

            <div>
                <label htmlFor="secondaryPhoneNumber" className="block text-sm font-medium text-gray-700">Additional Phone Number <span className="text-red-500">*</span></label>
                <div className="flex items-center">
                    <span className="mr-2">+254 </span>
                    <input
                        id="secondaryPhoneNumber"
                        type="tel"
                        pattern="[0-9]{10}"
                        value={secondaryPhoneNumber}
                        onChange={(e) => setSecondaryPhoneNumber(e.target.value)}
                        placeholder="Phone Number"
                        className="input"
                    />
                </div>
            </div>

            <div>
                <label htmlFor="county" className="block text-sm font-medium text-gray-700">County <span className="text-red-500">*</span></label>
                <Select
                    options={kenyanCounties.map((county) => ({ value: county, label: county }))}
                    value={{ value: county, label: county }}
                    onChange={(selectedOption) => {
                        setCounty(selectedOption.value);
                        setCity('');
                    }}
                    placeholder="Select your county"
                    className="w-full"
                />
                {countyError && <p className="text-red-500">{countyError}</p>}
            </div>

            <div>
                <label htmlFor="city" className="block text-sm font-medium text-gray-700">City <span className="text-red-500">*</span></label>
                <Select
                    options={county ? kenyanCities[county].map(city => ({ value: city, label: city })) : []}
                    value={{ value: city, label: city }}
                    onChange={(selectedOption) => setCity(selectedOption.value)}
                    placeholder="Select your city"
                    className="w-full"
                />
                {cityError && <p className="text-red-500">{cityError}</p>}
            </div>

            <div>
                <label htmlFor="address" className="block text-sm font-medium text-gray-700">Address <span className="text-red-500">*</span></label>
                <PlacesAutocomplete
                    value={address}
                    onChange={(address) => setAddress(address)}
                    onSelect={(address) => handleSelect(address)}
                    searchOptions={{
                        types: [`address`],
                        componentRestrictions: {
                            country: 'ke',
                            administrativeArea: '',
                            locality: ''
                        }

                    }}
                    className="flex-grow"
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div className="flex-grow">
                            <input
                                {...getInputProps({
                                    placeholder: 'Enter your address',
                                    className: 'input w-full',
                                })}
                            />
                            <div>
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion) => {
                                    const style = {
                                        backgroundColor: suggestion.active ? '#fafafa' : '#ffffff',
                                        cursor: 'pointer',
                                        padding: '0.5rem',
                                    };
                                    return (
                                        <div {...getSuggestionItemProps(suggestion, { style })}>
                                            {suggestion.description}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </PlacesAutocomplete>
                {addressError && <p className="text-red-500">{addressError}</p>}
            </div>

            <div>
                <label htmlFor="additionalInformation" className="block text-sm font-medium text-gray-700">Additional Info</label>
                <textarea
                    id="additionalInformation"
                    value={additionalInformation}
                    onChange={(e) => {
                        const words = e.target.value.split(/\s+/);
                        if (words.length <= 150) {
                            setAdditionalInformation(e.target.value);
                        }
                    }}
                    rows="2"
                    className="input w-full h-20"
                />
                <p className="text-sm text-gray-500">{additionalInformation?.split(/\s+/).length} / 150 words</p>
            </div>

            <div className="w-full pb-2">
                <label className="block pb-2">Address Type <span className="text-red-500">*</span></label>
                <select
                    value={addressType}
                    onChange={(e) => setAddressType(e.target.value)}
                    className="w-full border h-10 rounded-md"
                >
                    <option value="">Choose your Address Type</option>
                    {addressTypeData?.map((item) => (
                        <option key={item?.name} value={item?.name}>
                            {item?.name}
                        </option>
                    ))}
                </select>
                {addressTypeError && <p className="text-red-500">{addressTypeError}</p>}
            </div>
            <div className="w-full pb-2">
                <div className="p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 cursor-pointer" onClick={() => setUserInfo(!userInfo)}>
                    <div className="flex items-center">
                        <FaAddressBook className="text-orange-500 text-2xl mr-3" />
                        <div>
                            <h5 className="text-lg font-semibold text-orange-500">
                                Choose From Saved Address
                            </h5>
                            <p className="text-sm text-gray-500">Select one of your previously saved addresses for faster checkout.</p>
                        </div>
                    </div>
                    {userInfo && (
                        <div className="mt-2 ml-4">
                            {user && user?.addresses?.map((item, index) => (
                                <div key={index} className="flex items-center my-2">
                                    <input
                                        type="radio"
                                        name="savedAddress"
                                        className="mr-3"
                                        value={item?.addressType}
                                        checked={selectedAddressType === item?.addressType}
                                        onChange={() => {
                                            setAddress(item?.address);
                                            setCounty(item?.county);
                                            setCity(item?.city);
                                            setPrimaryPhoneNumber(item?.primaryPhoneNumber);
                                            setSecondaryPhoneNumber(item?.secondaryPhoneNumber);
                                            setAdditionalInformation(item?.additionalInformation);
                                            setAddressType(item?.addressType);
                                            setSelectedAddressType(item?.addressType);
                                        }}
                                    />
                                    <label htmlFor={`address-${index}`} className="block text-sm font-medium text-gray-700">
                                        {item?.addressType}
                                    </label>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>

        </div>
    );
};

export default AddressForm;
