// ChatWindow.js
import React from 'react';
import MessageList from './MessageList';
import MessageForm from './MessageForm';

const ChatWindow = ({ conversations, loadMoreConversations, loading, hasMore, selectedConversation,newConversation }) => {
  console.log('ChatWindow1', hasMore);
  console.log('ChatWindow', selectedConversation);
  console.log('ChatWindow2', conversations);
  //let messages = conversations[0];
  // localStorage.removeItem("selectedParticipant");
  let messages = [];
  conversations.forEach((conversation) => {
    if (conversation.participants === selectedConversation?.participants) {
      messages = conversation;
    }
  });
 
  console.log('semites', messages.messages, 'logo', selectedConversation.participants);

  return (
    <div className='ml-2'>
      {/* Back Button */}

      {messages ? (
        <div>

          <>
            <MessageList messages={messages} hasMore={hasMore} participants={selectedConversation.participants} />
            <MessageForm selectedConversation={selectedConversation} newConversation={newConversation} />
          </>
        </div>
      ) : (
        <p>Please select a user to chat with</p>
      )
      }
    </div >
  );
};


export default ChatWindow;
