// DashboardMessages.js
import ConversationList from './ConversationList';
import ChatWindow from './ChatWindow';
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useConversations from '../../hooks/useConversations';
import { getDocs, collection, updateDoc } from "firebase/firestore";
import db from "../../firebase"; // Assuming you have Firebase initialized and exported db
import { FaArrowLeft } from "react-icons/fa";
import ConversationItem from './ConversationItem';

const DashboardMessages = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state: locationState } = location;
  const { conversations, loadMoreConversations, loading, hasMore } = useConversations();
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [newConversation, setNewConversation] = useState(null);



  const handleBackClick = () => {
    navigate(-1); // Navigate to the previous page
  };

  useEffect(() => {
    if (locationState?.participants) {
      const selected = conversations.find(conversation => conversation.participants === locationState.participants);
      console.log('selection', conversations);
      if (selected) {
        setSelectedConversation(selected);
      } else {
        // If no existing conversation, create a new one with the seller
        const newConversation = {

          participants: locationState.participants,
          recipient: locationState.recipient, // Assuming current user is sender
          sender: locationState.sender,// Placeholder, replace with actual recipient name if available
          messages: locationState.messageText
        };
        setNewConversation(newConversation);
        setSelectedConversation(newConversation);
      }
    }
  }, [locationState, conversations]);

  const handleConversationClick = (conversation) => {
    setSelectedConversation(conversation);
  };

  return (
    <>
      {selectedConversation ? (
        <div className="flex flex-col md:flex-row items-start w-full p-6">
          <button
            onClick={handleBackClick}
            className="flex items-center mb-4 p-2 text-blue-500 hover:text-blue-700 bg-white hover:bg-gray-100 transition-colors duration-300 rounded-full shadow-lg"
          >
            <FaArrowLeft className="mr-4" />
            <span className="font-semibold">Back</span>
          </button>
          <div className="flex flex-col w-full md:flex-row md:w-auto md:ml-4">
            <div className="w-full md:w-1/3 mb-4 md:mb-0">
              <h1 className="text-2xl font-semibold pb-4">Chat Users</h1>
              <ConversationList
                conversations={conversations}
                click={handleConversationClick}
                selectedConversation={selectedConversation}
              />
            </div>
            <div className="w-full md:flex-1">
              <h1 className="text-2xl font-semibold mb-4">
                {selectedConversation
                  ? `Conversation with ${selectedConversation?.sender?.name}`
                  : 'Select a user to chat with'}
              </h1>
              {selectedConversation && (
                <ChatWindow
                  conversations={conversations}
                  loadMoreConversations={loadMoreConversations}
                  loading={loading}
                  hasMore={hasMore}
                  selectedConversation={selectedConversation}
                  newConversation={newConversation}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center w-full h-full p-6">
          <h1 className="text-3xl font-semibold text-gray-600">No messages available</h1>
          <p className="text-gray-500">Please select a user to start a conversation.</p>
        </div>
      )}
    </>
  );
};

export default DashboardMessages; 