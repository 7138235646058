import React, { useEffect, useState } from "react";
import axios from 'axios';
import { server } from '../../server';
import Editor from './Editor';
import { useSelector } from 'react-redux';
import ShopAdminPostList from '../../components/Shop/ShopAdminPostList';
import ShopAdminImageList from '../../components/Shop/ShopAdminImageList';
import ShopAdminVideoList from '../../components/Shop/ShopAdminVideoList';
import ShippingSettingList from '../../components/Shop/ShippingSettingList';
//import HomePagePost from '../../components/Shop/HomePagePost';
import RefundPolicyPost from '../../components/Shop/RefundPolicyPost';
import { toast } from "react-toastify";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import TagInput from "../../components/Shop/TagInput";
import ShippingInputs from "../../components/Shop/ShippingInputs";
import ShopAdminHomePost from "../../components/Shop/ShopAdminHomePost";
import BusinessForm from "../../components/Shop/BusinessForm";


const PostEditor = () => {
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [title, setTitle] = useState("");
  const [postContent, setPostContent] = useState("");
  const [returnPolicy, setReturnPolicy] = useState("");
  const [homeContent, setHomeContent] = useState("");
  const { seller } = useSelector((state) => state.seller);
  const [tags, setTags] = useState([]);
  const [homeTags, setHomeTags] = useState([]);
  const [homeIsEditing, setHomeIsEditing] = useState(false);
  const [returnPolicyIsEditing, setReturnPolicyIsEditing] = useState(false);
  const [shippingInputs, setShippingInputs] = useState([]);
  const [settings, setSettings] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(`${server}/shop/shipping-setting/${seller._id}`);
        setSettings(response.data.data);
      } catch (error) {
        console.error('Error fetching settings:', error);
      }
    };

    fetchSettings();
  }, [seller._id]);

  const validate = (type) => {
    const newErrors = {};

    if (type === 'title' && !title) newErrors.title = "Title is required.";
    if (type === 'postContent' && !postContent) newErrors.postContent = "Post content is required.";
    if (type === 'homeContent' && !homeContent && homeIsEditing) newErrors.homeContent = "Home content is required.";
    if (type === 'returnPolicy' && !returnPolicy && returnPolicyIsEditing) newErrors.returnPolicy = "Return policy is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleShippingInputsChange = (inputs) => {
    setShippingInputs(inputs);
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    setImages([]);

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setImages((old) => [...old, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleVideoLinkAddition = (event) => {
    const videoLinks = event.target.value.split("\n");
    setVideos(videoLinks);
  };

  const handleReturnPolicyChange = (content) => {
    setReturnPolicy(content);
  };

  const handleHomeContentChange = (content) => {
    setHomeContent(content);
  };

  const handleSubmit = async (type) => {
    if (!validate(type)) return;

    try {
      const postData = {
        images,
        shop: seller._id,
        videos,
        postContent,
        postTitle: title,
        postTag: tags,
        homeContent,
        returnPolicy,
        homeTag: homeTags,
        shippingInputs,
        postType: returnPolicy ? 'return-policy' : shippingInputs ? 'shipping-inputs' : homeContent ? 'home-content' : images ? 'images' : videos ? 'videos' : 'blog'
      };

      const response = await axios.post(
        `${server}/shop/shop-info`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "image/*",
          },
        }
      );

      if (returnPolicy) {
        handleReturnPolicyChange(returnPolicy);
        setReturnPolicyIsEditing(true);
      } else if (homeContent) {
        handleHomeContentChange(homeContent);
        setHomeIsEditing(true);
      }
      toast.success("Post Added Successfully");
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <div className="w-full min-h-screen flex flex-col items-center bg-gray-100">
      <div className="flex w-full flex-col justify-center my-5">
        <div className="w-full flex items-center justify-center mb-3">
          <div className="relative w-full h-full max-w-6xl rounded-md shadow-sm bg-white p-6">
            <h2 className="text-2xl font-bold mb-5">Shop Data Settings</h2>
            <Tabs>
              <TabList className="flex space-x-4 overflow-x-auto border-b mr-1"> {/* Added overflow-x-auto */}
                <Tab className="py-2 px-4 cursor-pointer border-b-2 border-transparent hover:border-blue-500">
                  Images
                </Tab>
                <Tab className="py-2 px-4 cursor-pointer border-b-2 border-transparent hover:border-blue-500">
                  Videos
                </Tab>
                <Tab className="py-2 px-4 cursor-pointer border-b-2 border-transparent hover:border-blue-500">
                  Post Content
                </Tab>
                <Tab className="py-2 px-4 cursor-pointer border-b-2 border-transparent hover:border-blue-500">
                  Home Page
                </Tab>
                {/* <Tab className="py-2 px-4 cursor-pointer border-b-2 border-transparent hover:border-blue-500">
                  Shipping Settings
                </Tab> */}
                <Tab className="py-2 px-4 cursor-pointer border-b-2 border-transparent hover:border-blue-500">
                  Refund Policy
                </Tab>
                <Tab className="py-2 px-4 cursor-pointer border-b-2 border-transparent hover:border-blue-500">
                  Verification Documents
                </Tab>
              </TabList>



              <TabPanel>
                <div className="mt-4">
                  <label htmlFor="imageUpload" className="block font-medium mb-1">
                    Upload Images:
                  </label>
                  <input
                    type="file"
                    id="imageUpload"
                    accept="image/*"
                    multiple
                    onChange={handleImageUpload}
                    className="border border-gray-300 rounded-md p-2 w-full"
                  />
                  <button
                    onClick={() => handleSubmit('images')}
                    className="bg-blue-500 text-white mt-4 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:bg-blue-600 w-full"
                  >
                    Submit
                  </button>
                  <div className="flex mt-4 space-x-2 overflow-x-auto">
                    {images.map((image, index) => (
                      <img key={index} src={image} alt={`Img ${index}`} className="h-20 object-cover rounded-md" />
                    ))}
                  </div>
                  <div className="flex mt-2">
                    <ShopAdminImageList shop={seller._id} />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="mt-4">
                  <label htmlFor="videoLinks" className="block font-medium mb-1">
                    Add Video Links (one per line):
                  </label>
                  <textarea
                    id="videoLinks"
                    rows="4"
                    onChange={handleVideoLinkAddition}
                    className="border border-gray-300 rounded-md p-2 w-full"
                  ></textarea>
                  <button
                    onClick={() => handleSubmit('videos')}
                    className="bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:bg-blue-600 w-full mt-4"
                  >
                    Submit
                  </button>
                </div>
                <div className="flex mt-4">
                  <ShopAdminVideoList shop={seller._id} />
                </div>
              </TabPanel>
              <TabPanel>
                <div className="max-w-lg mx-auto mt-4">
                  <label htmlFor="postTitle" className="block font-medium mb-1">
                    Post Title:
                  </label>
                  <input
                    type="text"
                    id="postTitle"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="w-full border border-gray-300 rounded-md px-3 py-2 mb-3 focus:outline-none focus:ring focus:border-blue-500"
                  />
                  {errors.title && <p className="text-red-500 text-sm">{errors.title}</p>}

                  <label htmlFor="postContent" className="block font-medium mb-1">
                    Post Content:
                  </label>
                  <Editor onContentChange={setPostContent} />
                  {errors.postContent && <p className="text-red-500 text-sm">{errors.postContent}</p>}
                  <div className="mb-1 mt-20">
                    <label htmlFor="tags" className="block font-medium">
                      Tags:
                    </label>
                    <TagInput
                      id="tags"
                      label="Tags"
                      tags={tags}
                      setTags={setTags}
                      className="w-full"
                    />
                  </div>
                  <button
                    onClick={() => handleSubmit('postContent')}
                    className="w-full bg-blue-500 text-white mt-4 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:bg-blue-600"
                  >
                    Submit
                  </button>

                  <ShopAdminPostList shop={seller._id} />
                </div>
              </TabPanel>
              <TabPanel>
                <div className="max-w-lg mx-auto mt-4">
                  {!homeIsEditing && (
                    <>
                      <Editor onContentChange={handleHomeContentChange} content={homeContent} />
                      {errors.homeContent && <p className="text-red-500 text-sm">{errors.homeContent}</p>}

                      <div className="mb-1 mt-20">
                        <label htmlFor="tags" className="block font-medium">
                          Tags:
                        </label>
                        <TagInput
                          id="hpTags"
                          label="Tags"
                          tags={homeTags}
                          setTags={setHomeTags}
                          className="w-full"
                        />
                      </div>
                      <button
                        onClick={() => handleSubmit('homeContent')}
                        className="w-full bg-blue-500 text-white mt-4 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:bg-blue-600"
                      >
                        Submit
                      </button>
                    </>
                  )}
                  <ShopAdminHomePost shop={seller._id} setHomeIsEditing={setHomeIsEditing} />
                </div>
              </TabPanel>
              {/* <TabPanel>
                <div>
                  <ShippingInputs
                    shippingInputs={shippingInputs}
                    onShippingInputsChange={handleShippingInputsChange}
                    onSubmit={handleSubmit}
                    settings={settings}
                    shop={seller._id}
                  />
                  <ShippingSettingList shop={seller._id} settings={settings} />
                </div>
              </TabPanel> */}
              <TabPanel>
                <div className="max-w-lg h-full mx-auto mt-4">
                  {!returnPolicyIsEditing && (
                    <>
                      <Editor onContentChange={handleReturnPolicyChange} content={returnPolicy} />
                      {errors.returnPolicy && <p className="text-red-500 text-sm">{errors.returnPolicy}</p>}

                      <button
                        onClick={() => handleSubmit('returnPolicy')}
                        className="w-full mb-10 mt-20 bg-blue-500 text-white mt-4 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:bg-blue-600"
                      >
                        Submit
                      </button>
                    </>
                  )}
                  <RefundPolicyPost shop={seller._id} content={returnPolicy} setReturnPolicyIsEditing={setReturnPolicyIsEditing} />
                </div>
              </TabPanel>
              <TabPanel>
                <div className="w-full h-full mx-auto mt-4">
                  <div className="mt-4">
                    <BusinessForm shop={seller._id} />
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostEditor;
