import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";


const VendorTermsAndConditionsPage = () => {
  return (
    <div>
      <Helmet>
        <title>Vendor Terms and Conditions - INDAZETU</title>
        <meta name="description" content="Read the Vendor Terms and Conditions for INDAZETU, Kenya's premier B2B marketplace platform. Last updated on 14 August 2024." />
        <meta name="keywords" content="INDAZETU, Vendor Terms and Conditions, B2B marketplace, Kenya, vendor agreement" />
      </Helmet>
      <Header />
      <VendorTermsAndConditions />
      <Footer />
    </div>
  );
};

const VendorTermsAndConditions = () => {
  return (
    <div className="bg-gray-100 py-10 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold mb-6">INDAZETU Vendor Terms and Conditions</h1>
        <p className="text-gray-600 mb-4">Last Updated On: 14 August 2024</p>

        <div className="space-y-8">
          <section>
            <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
            <p className="text-gray-700">
              Welcome to INDAZETU Online Marketplace Kenya Limited ("INDAZETU"). These Vendor Terms and Conditions outline the agreement between INDAZETU and our vendors ("you"). By using our platform, you agree to comply with these terms.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">2. Account Registration</h2>
            <p className="text-gray-700">
              You must create an account to sell on our platform. All information provided during registration must be accurate and current. It is your responsibility to update your account details as needed.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">3. Product Listings</h2>
            <p className="text-gray-700">
              Vendors are required to provide clear and accurate descriptions of their products or services. Pricing should be transparent, and any changes must be clearly communicated to buyers.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">4. Compliance and Legal Obligations</h2>
            <p className="text-gray-700">
              Vendors must ensure that their products or services comply with all applicable laws and regulations. You are responsible for collecting and remitting any applicable taxes related to your sales.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">5. Fees</h2>
            <p className="text-gray-700">
              While the basic use of our platform is free, INDAZETU offers premium features for a fee. Please refer to our website for a detailed breakdown of these fees. All fees are non-refundable.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">6. Content and Intellectual Property</h2>
            <p className="text-gray-700">
              You retain ownership of the content you post on INDAZETU. However, by posting content, you grant INDAZETU a license to use, modify, and distribute it as needed for platform operations. INDAZETU’s content, including logos and software, is protected by intellectual property laws.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">7. Limitation of Liability</h2>
            <p className="text-gray-700">
              INDAZETU is not liable for any damages resulting from your use of the platform. This includes indirect, incidental, or consequential damages.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">8. Indemnification</h2>
            <p className="text-gray-700">
              You agree to indemnify and hold INDAZETU harmless from any claims, losses, or damages arising from your use of the platform, your content, or any breach of this agreement.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">9. Termination</h2>
            <p className="text-gray-700">
              Either party may terminate this agreement at any time. If you wish to stop using INDAZETU, you may close your account. We reserve the right to suspend or terminate your account if these terms are violated.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">10. Governing Law</h2>
            <p className="text-gray-700">
              This agreement is governed by the laws of Kenya. Any disputes will be resolved through arbitration under the NCIA Arbitration Rules, with proceedings conducted in English.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">11. Miscellaneous</h2>
            <p className="text-gray-700">
              If any part of this agreement is found to be invalid or unenforceable, the remaining provisions will continue to be effective. INDAZETU may update these terms periodically, and we will notify you of any significant changes.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default VendorTermsAndConditionsPage;


