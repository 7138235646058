import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { FaEdit, FaTrash } from 'react-icons/fa';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { server } from '../../server';
import { kenyanCounties, kenyanCities } from '../Shop/kenyanCounties';
import LocationSearchInput from './LocationSearchInput';
import { toast } from 'react-toastify';


const PickupStation = () => {
  const [stations, setStations] = useState([]);
  const [address, setAddress] = useState({});
  const [form, setForm] = useState({
    stationName: '',
    address: {},
    openingTime: '',
    closingTime: '',
    days: '',
    phone: '',
    paymentOption: '',
    description: '',
    county: '',
    city: ''
  });
  const [editingStation, setEditingStation] = useState(null);

  const [county, setCounty] = useState('');
  const [city, setCity] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchStations();
  }, []);

  const fetchStations = async () => {
    try {
      const response = await axios.get(`${server}/pickupstation/get-all-pickupstations`);
      setStations(response.data.pickupStations);
    } catch (error) {
      console.error('Error fetching pickup stations:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    try {
      const { address, openingTime, closingTime, days, ...rest } = form;
      const workingHours = `${openingTime} - ${closingTime}`;
      const finalForm = { ...rest, address, workingHours, days };
      if (editingStation) {
        await axios.put(`${server}/pickupstation/update-pickupstation/${editingStation._id}`, finalForm)
          .then((res) => {
            toast.success('Update Successful');
          })
          .catch((error) => {
            toast.error(error);
          });
      } else {
        await axios.post(`${server}/pickupstation/create-pickupstation`, finalForm)
        .then((res) => {
          console.log('create',res.data)
          toast.success('Post Successful');
        })
        .catch((error) => {
          toast.error(error);
        });
      }
      fetchStations();
      setForm({
        stationName: '',
        address: {},
        openingTime: '',
        closingTime: '',
        days: '',
        phone: '',
        paymentOption: '',
        description: '',
        county: '',
        city: ''
      });
      setEditingStation(null);
      setErrors({});
    } catch (error) {
      console.error('Error saving pickup station:', error);
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!form.stationName) errors.stationName = 'Station name is required';
    if (!form.address.location) errors.address = 'Address is required';
    if (!form.openingTime) errors.openingTime = 'Opening time is required';
    if (!form.closingTime) errors.closingTime = 'Closing time is required';
    if (!form.days) errors.days = 'Working days are required';
    if (!form.phone) errors.phone = 'Phone number is required';
    if (!form.paymentOption) errors.paymentOption = 'Payment option is required';
    if (!form.county) errors.county = 'County is required';
    if (!form.city) errors.city = 'City is required';
    return errors;
  };

  const handleEdit = (station) => {
    setEditingStation(station);
    setForm({
      stationName: station.stationName,
      address: station.address,
      openingTime: station.workingHours.split(' - ')[0],
      closingTime: station.workingHours.split(' - ')[1],
      days: station.days,
      phone: station.phone,
      paymentOption: station.paymentOption,
      description: station.description,
      county: station.county || '',
      city: station.city || ''
    });
    setCounty(station.county || '');
    setCity(station.city || '');
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${server}/pickupstation/delete-pickupstation/${id}`);
      fetchStations();
    } catch (error) {
      console.error('Error deleting pickup station:', error);
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <form onSubmit={handleFormSubmit} className="space-y-6">

        {/* County and City */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">County</label>
            <Select
              options={kenyanCounties.map((county) => ({ value: county, label: county }))}
              value={{ value: county, label: county }}
              onChange={(selectedOption) => {
                setCounty(selectedOption.value);
                setCity('');
                setForm({ ...form, county: selectedOption.value, city: '' });
              }}
              placeholder="Select your county"
              className="w-full"
            />
            {errors.county && <p className="text-red-500 text-sm">{errors.county}</p>}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">City</label>
            <Select
              options={county ? kenyanCities[county].map(city => ({ value: city, label: city })) : []}
              value={{ value: city, label: city }}
              onChange={(selectedOption) => {
                setCity(selectedOption.value);
                setForm({ ...form, city: selectedOption.value });
              }}
              placeholder="Select your city"
              className="w-full"
            />
            {errors.city && <p className="text-red-500 text-sm">{errors.city}</p>}
          </div>
        </div>

        {/* Station Name and Address */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Station Name</label>
            <input
              type="text"
              name="stationName"
              value={form.stationName}
              onChange={handleInputChange}
              className="w-full border rounded-md px-3 py-2"
              placeholder="Enter station name"
              required
            />
            {errors.stationName && <p className="text-red-500 text-sm">{errors.stationName}</p>}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Address</label>
            <LocationSearchInput
              onSelect={(selected) => {
                setForm({ ...form, address: selected });
                setAddress(selected);
                console.log('Selected address:', selected);
              }}
            />
            {errors.address && <p className="text-red-500 text-sm">{errors.address}</p>}
          </div>
        </div>

        {/* Phone and Working Hours */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Phone</label>
            <input
              type="tel"
              name="phone"
              value={form.phone}
              onChange={handleInputChange}
              className="w-full border rounded-md px-3 py-2"
            />
            {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
          </div>

          <div className="flex flex-col">
            <label className="block text-sm font-medium text-gray-700">Working Hours</label>
            <div className="flex flex-col gap-4">
              <div className="flex items-center">
                <label className="block w-1/2 text-gray-600">Opening Time</label>
                <input
                  type="time"
                  name="openingTime"
                  value={form.openingTime}
                  onChange={handleInputChange}
                  className="w-1/2 border rounded-md px-3 py-2"
                />
                {errors.openingTime && <p className="text-red-500 text-sm">{errors.openingTime}</p>}
              </div>
              <div className="flex items-center">
                <label className="block w-1/2 text-gray-600">Closing Time</label>
                <input
                  type="time"
                  name="closingTime"
                  value={form.closingTime}
                  onChange={handleInputChange}
                  className="w-1/2 border rounded-md px-3 py-2"
                />
                {errors.closingTime && <p className="text-red-500 text-sm">{errors.closingTime}</p>}
              </div>
            </div>
          </div>
        </div>

        {/* Payment Options and Description */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Working Days</label>
            <input
              type="text"
              name="days"
              value={form.days}
              onChange={handleInputChange}
              placeholder="Days (e.g., Monday - Sunday)"
              className="w-full border rounded-md px-3 py-2"
              required
            />
            {errors.days && <p className="text-red-500 text-sm">{errors.days}</p>}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Payment Options</label>
            <Select
              options={[
                { value: 'Pay on Delivery', label: 'Pay on Delivery' },
                { value: 'Pre-pay Now', label: 'Pre-pay Now' },
              ]}
              value={{ value: form.paymentOption, label: form.paymentOption }}
              onChange={(selected) => setForm({ ...form, paymentOption: selected.value })}
              className="w-full"
            />
            {errors.paymentOption && <p className="text-red-500 text-sm">{errors.paymentOption}</p>}
          </div>
        </div>

        {/* Description */}
        <label className="block text-sm font-medium text-gray-700">Description</label>
        <textarea
          name="description"
          value={form.description}
          onChange={handleInputChange}
          className="w-full border rounded-md px-3 py-2"
        />

        {/* Submit Button */}
        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded mt-4 w-full sm:w-auto">
          {editingStation ? 'Update' : 'Add'} Station
        </button>
      </form>

      {/* Station List */}
      <ul className="list-disc pl-5 mt-6">
        {stations.length > 0 ? (
          stations.map((station) => (
            <li key={station._id} className="mb-4 flex flex-col gap-2">
              <div className="p-4 border rounded-md shadow-sm">
                <p><strong>Name:</strong> {station.stationName}</p>
                <p><strong>Address:</strong> {station.address.formatted_address || 'Address not provided'}</p>
                <p><strong>Working Hours:</strong> {station.workingHours}</p>
                <p><strong>Days:</strong> {station.days}</p>
                <p><strong>Phone:</strong> {station.phone}</p>
                <p><strong>Payment Option:</strong> {station.paymentOption}</p>
                <p><strong>Description:</strong> {station.description}</p>
                <p><strong>County:</strong> {station.county}</p>
                <p><strong>City:</strong> {station.city}</p>
              </div>
              <div className="flex gap-2">
                <button onClick={() => handleEdit(station)} className="text-blue-500">
                  <FaEdit />
                </button>
                <button onClick={() => handleDelete(station._id)} className="text-red-500">
                  <FaTrash />
                </button>
              </div>
            </li>
          ))
        ) : (
          <p>No pickup stations available.</p>
        )}
      </ul>
    </div>
  );
};

export default PickupStation;
