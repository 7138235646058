import React, { useState, useEffect } from 'react'
import styles from '../../styles/styles'
import ShopInfo from "../../components/Shop/ShopInfo";
import ShopProfileData from "../../components/Shop/ShopProfileData";
import { FiMenu, FiX } from 'react-icons/fi';


const ShopPreviewPage = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 800);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={`${styles.section} bg-[#f5f5f5]`}>
      {/* Display the parameter value for verification */}
      {/* <p>Parameter Value: {id}</p> */}
      <div className="w-full 800px:flex py-10 justify-between">
        {!isMobile ? (
          <div className="800px:w-[25%] bg-[#fff] rounded-[4px] shadow-sm 800px:overflow-y-scroll 800px:h-[90vh] 800px:sticky top-10 left-0 z-10">
            <ShopInfo isOwner={false} />
          </div>
        ) : (
          <>
            <button
              onClick={toggleSidebar}
              className="fixed top-4 left-4 bg-blue-500 text-white py-2 px-4 rounded-md z-50"
            >
              {isSidebarOpen ? <FiX size={24} /> : <FiMenu size={24} />}
            </button>
            {isSidebarOpen && (
              <div className="fixed top-0 left-0 w-[75%] h-full bg-white shadow-md z-40 overflow-y-scroll">
                <ShopInfo isOwner={false} />
              </div>
            )}
          </>
        )}
        <div className="800px:w-[72%] mt-5 800px:mt-['unset'] rounded-[4px]">
          <ShopProfileData isOwner={false} />
        </div>
      </div>
    </div>
  );
};

export default ShopPreviewPage;
