import React, { useState, useEffect } from 'react';
import { AiTwotoneFilePdf } from 'react-icons/ai';
import Select from 'react-select';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { server } from '../../server';
import { toast } from 'react-toastify';


const idOptions = [
  { value: 'national-id', label: 'National ID' },
  { value: 'passport', label: 'Passport' },
  { value: 'driving-license', label: 'Driving License' },
];

const BusinessForm = ({ shop }) => {
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const [companyDetails, setCompanyDetails] = useState({
    taxClearanceNumber: '',
    taxClearanceCertificate: null,
    taxClearanceCertificateName: ''
  });
  const [isEdit, setIsEdit] = useState(false);

  const handleFileUpload = (e, fieldName) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setCompanyDetails((prevDetails) => ({
          ...prevDetails,
          [fieldName]: reader.result,
          [`${fieldName}Name`]: file.name,
        }));
      };
    }
  };



  const [address, setAddress] = useState({
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    country: 'Kenya',
    postalCode: '',
  });
  const [legalDetails, setLegalDetails] = useState({
    fullName: '',
    idType: '', // Start with an empty string for single select
    taxIdentificationNumber: '',
    taxIdentificationCertificateName: '',
    taxIdentificationCertificate: null,
  });



  const onSubmit = async () => {
    try {

      const data = {
        taxClearanceNumber: companyDetails.taxClearanceNumber,
        taxClearanceCertificate: companyDetails.taxClearanceCertificate,
        addressLine1: address.addressLine1,
        addressLine2: address.addressLine2,
        city: address.city,
        state: address.state,
        country: address.country,
        postalCode: address.postalCode,
        fullName: legalDetails.fullName,
        idType: legalDetails.idType,
        taxIdentificationNumber: legalDetails.taxIdentificationNumber,
        taxIdentificationCertificate: legalDetails?.taxIdentificationCertificateName,
        seller: shop,
      };

      // Dummy endpoint
      const response = await axios.post(`${server}/businessdocs/create-or-update-business-docs`, data);
      console.log('Form submitted:', response.data);
      toast.success(response.data.message);

    } catch (error) {
      console.error('Form submission error:', error);
      toast.error(error.response.data.message);
    }
  };
  const fetchBusinessDocs = async () => {
    try {
      const response = await axios.get(`${server}/businessdocs/get-business-docs/${shop}`); // Adjust endpoint as needed
      const data = response.data;
      if (!data) {
        return;
      }
      console.log('qwert', data);
      if (data) {
        setCompanyDetails({
          taxClearanceNumber: data.taxClearanceNumber,
          taxClearanceCertificate: data.taxClearanceCertificate?.url || null,
          taxClearanceCertificateName: data.taxClearanceCertificateName || '',
        });

        setAddress({
          addressLine1: data.address.addressLine1,
          addressLine2: data.address.addressLine2,
          city: data.address.city,
          state: data.address.state,
          country: data.address.country,
          postalCode: data.address.postalCode,
        });

        setLegalDetails({
          fullName: data.legalRepresentative.fullName,
          idType: data.legalRepresentative.idType,
          taxIdentificationNumber: data.legalRepresentative.taxIdentificationNumber,
          taxIdentificationCertificateName: data.legalRepresentative.taxIdentificationCertificate?.url || '',
          taxIdentificationCertificate: null,
        });

        // Set form values for react-hook-form
        setValue('taxClearanceNumber', data.taxClearanceNumber);
        setValue('addressLine1', data.address.addressLine1);
        setValue('addressLine2', data.address.addressLine2);
        setValue('city', data.address.city);
        setValue('state', data.address.state);
        setValue('postalCode', data.address.postalCode);
        setValue('fullName', data.legalRepresentative.fullName);
        setValue('idType', data.legalRepresentative.idType);
        setValue('taxIdentificationNumber', data.legalRepresentative.taxIdentificationNumber);
        setIsEdit(true);
      }
    } catch (error) {
      console.error('Error fetching business docs:', error);
      toast.error(error);
    }
  };

  useEffect(() => {
    fetchBusinessDocs();
  }, []);

 
  const taxIdCertfileName = legalDetails?.taxIdentificationCertificateName?.split('/').pop();
  const taxClearanceCertFileName = companyDetails?.taxClearanceCertificate?.split('/').pop();

  return (
  
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-5xl">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          {isEdit ? 'Edit Company Details' : 'Company Details'}
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Please provide the following details of your business
        </p>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-5xl">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label
                htmlFor="taxClearanceNumber"
                className="block text-sm font-medium text-gray-700"
              >
                Tax Clearance Number
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="taxClearanceNumber"
                  required
                  {...register('taxClearanceNumber', { required: true })}
                  value={companyDetails.taxClearanceNumber}
                  onChange={(e) =>
                    setCompanyDetails({
                      ...companyDetails,
                      taxClearanceNumber: e.target.value,
                    })
                  }
                  className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${errors.taxClearanceNumber ? 'border-red-500' : ''}`}
                />
                {errors.taxClearanceNumber && (
                  <p className="mt-2 text-sm text-red-500">Tax Clearance Number is required</p>
                )}
                
              </div>
              <p className="mt-2 text-sm text-gray-600">
                Platform notifies the vendors that the TCC shall be shared with
                the Kenya Revenue Authorities in line with the Section 59 of the
                Tax Procedures Act but subject to Section 29 (d) and 28(f) of
                the Data Protection Act
              </p>
            </div>

            <div className="mt-1 flex items-center">
              <input
                type="file"
                name="taxClearanceCertificate"
                onChange={(e) => handleFileUpload(e, 'taxClearanceCertificate')}
                className="hidden"
                id="taxClearanceCertificate"
              />
              <label
                htmlFor="taxClearanceCertificate"
                className="cursor-pointer flex items-center text-blue-600 hover:text-blue-500"
              >
                <AiTwotoneFilePdf className="mr-2" size={20} />
                Choose file
              </label>
              {/* {companyDetails.taxClearanceCertificateName && (
                <span className="ml-2 text-gray-700">{companyDetails.taxClearanceCertificateName}</span>
              )} */}
                {taxClearanceCertFileName && (
               <span className="ml-2 text-gray-700">{taxClearanceCertFileName}</span>
               )}
            </div>

            <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
              <div>
                <h3 className="text-xl font-bold text-gray-900">
                  Legal Representative's Address
                </h3>
                <div className="mt-4">
                  <label
                    htmlFor="addressLine1"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Address Line 1
                  </label>
                  <input
                    type="text"
                    name="addressLine1"
                    required
                    {...register('addressLine1', { required: true })}
                    value={address.addressLine1}
                    onChange={(e) =>
                      setAddress({ ...address, addressLine1: e.target.value })
                    }
                    className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${errors.addressLine1 ? 'border-red-500' : ''}`}
                  />
                  {errors.addressLine1 && (
                    <p className="mt-2 text-sm text-red-500">Address Line 1 is required</p>
                  )}
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="addressLine2"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Address Line 2
                  </label>
                  <input
                    type="text"
                    name="addressLine2"
                    value={address.addressLine2}
                    onChange={(e) =>
                      setAddress({ ...address, addressLine2: e.target.value })
                    }
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium text-gray-700"
                  >
                    City / Town
                  </label>
                  <input
                    type="text"
                    name="city"
                    required
                    {...register('city', { required: true })}
                    value={address.city}
                    onChange={(e) =>
                      setAddress({ ...address, city: e.target.value })
                    }
                    className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${errors.city ? 'border-red-500' : ''}`}
                  />
                  {errors.city && (
                    <p className="mt-2 text-sm text-red-500">City / Town is required</p>
                  )}
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="state"
                    className="block text-sm font-medium text-gray-700"
                  >
                    State / Region
                  </label>
                  <input
                    type="text"
                    name="state"
                    required
                    {...register('state', { required: true })}
                    value={address.state}
                    onChange={(e) =>
                      setAddress({ ...address, state: e.target.value })
                    }
                    className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${errors.state ? 'border-red-500' : ''}`}
                  />
                  {errors.state && (
                    <p className="mt-2 text-sm text-red-500">State / Region is required</p>
                  )}
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="postalCode"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Pincode / Postal Code
                  </label>
                  <input
                    type="text"
                    name="postalCode"
                    value={address.postalCode}
                    onChange={(e) =>
                      setAddress({ ...address, postalCode: e.target.value })
                    }
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <h3 className="text-xl font-bold text-gray-900">
                  Legal Representative's Details
                </h3>
                <div className="mt-4">
                  <label
                    htmlFor="fullName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Full Name
                  </label>
                  <input
                    type="text"
                    name="fullName"
                    required
                    {...register('fullName', { required: true })}
                    value={legalDetails.fullName}
                    onChange={(e) =>
                      setLegalDetails({
                        ...legalDetails,
                        fullName: e.target.value,
                      })
                    }
                    className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${errors.fullName ? 'border-red-500' : ''}`}
                  />
                  {errors.fullName && (
                    <p className="mt-2 text-sm text-red-500">Full Name is required</p>
                  )}
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="idType"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Choose ID Type
                  </label>
                  <Select
                    options={idOptions}
                    value={idOptions.find(option => option.value === legalDetails.idType)}
                    onChange={(selectedOption) =>
                      setLegalDetails({
                        ...legalDetails,
                        idType: selectedOption.value,
                      })
                    }
                    className={`mt-1 ${errors.idType ? 'border-red-500' : ''}`}
                    classNamePrefix="select"
                  />
                  {errors.idType && (
                    <p className="mt-2 text-sm text-red-500">{errors.idType.message}</p>
                  )}
                </div>

                <div className="mt-4">
                  <label
                    htmlFor="taxIdentificationNumber"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Tax Identification Number (TIN)
                  </label>
                  <input
                    type="text"
                    name="taxIdentificationNumber"
                    required
                    {...register('taxIdentificationNumber', { required: true })}
                    value={legalDetails.taxIdentificationNumber}
                    onChange={(e) =>
                      setLegalDetails({
                        ...legalDetails,
                        taxIdentificationNumber: e.target.value,
                      })
                    }
                    className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${errors.taxIdentificationNumber ? 'border-red-500' : ''}`}
                  />
                  {errors.taxIdentificationNumber && (
                    <p className="mt-2 text-sm text-red-500">Tax Identification Number is required</p>
                  )}
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="taxIdentificationCertificate"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Upload Tax Identification Number (TIN)
                  </label>
                  <div className="mt-1 flex items-center">
                    <input
                      type="file"
                      name="taxIdentificationCertificate"
                      onChange={(e) => handleFileUpload(e, 'taxIdentificationCertificate')}
                      className="hidden"
                      id="taxIdentificationCertificate"
                    />
                    <label
                      htmlFor="taxIdentificationCertificate"
                      className="cursor-pointer flex mr-2 items-center text-blue-600 hover:text-blue-500"
                    >
                      <AiTwotoneFilePdf className="mr-2" size={20} />
                      Choose file
                    </label>
                  { taxIdCertfileName || companyDetails.taxIdentificationCertificateName && (
                    <span className="ml-2 text-gray-700">{ taxIdCertfileName || companyDetails.taxIdentificationCertificateName}</span>
                  )}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6">
              <button
                type="submit"
                className="w-full bg-blue-600 border border-transparent rounded-md py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                {isEdit ? 'Update' : 'Submit'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BusinessForm;
