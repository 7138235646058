import { MdEdit, MdDelete } from 'react-icons/md';
import { formatDistanceToNow } from 'date-fns';

const MessageItem = ({ message, handleEdit, setConfirmDeleteMessage }) => {
  const messageTimestamp = message?.createdAt?.toDate(); // Convert Firestore timestamp to JavaScript Date object
  const fifteenMinutesAgo = new Date(Date.now() - 15 * 60 * 1000);
  const showIcons = messageTimestamp && messageTimestamp >= fifteenMinutesAgo;


  const convertTimestampToDate = (timestamp) => {
    const { seconds, nanoseconds } = timestamp;
    return new Date(seconds * 1000 + nanoseconds / 1000000);
  };

  return (
    <div className="flex flex-col items-start">
      {message.text && <p className="text-base text-gray-800">{message.text}</p>}
      {message.file && (
        <img
          src={message.file}
          alt={message.file}
          className="max-w-[300px] max-h-[300px] object-cover rounded-[10px] my-2"
        />
      )}
      <p className="text-sm text-gray-500">
        {/* {formatTimestampToTimeAgo(message?.createdAt)} ago */}
       {/* {JSON.stringify(message?.createdAt)} */}
       <p>
        {message?.createdAt
          ? `Time ago: ${formatDistanceToNow(convertTimestampToDate(message.createdAt))} ago`
          : 'No timestamp available'}
      </p>
        
      </p>
      {showIcons && (
        <div className="flex">
          <MdEdit onClick={() => handleEdit(message)} className="cursor-pointer mr-2" />
          <MdDelete onClick={() => setConfirmDeleteMessage(message)} className="cursor-pointer text-red-500" />
        </div>
      )}
    </div>
  );
};

export default MessageItem;
