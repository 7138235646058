import React, { useState } from "react";
import {
  AiFillHeart,
  AiFillStar,
  AiOutlineEye,
  AiOutlineHeart,
  AiOutlineShoppingCart,
  AiOutlineStar,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";
import { useDispatch, useSelector } from "react-redux";
import ProductDetailsCard from "../ProductDetailsCard/ProductDetailsCard";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../../redux/actions/wishlist";
import { useEffect } from "react";
import { addTocart } from "../../../redux/actions/cart";
import { toast } from "react-toastify";
import Ratings from "../../Products/Ratings";
import useShop from "../../../hooks/useShop";


const ProductCard = ({ data, isEvent }) => {

  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const [click, setClick] = useState(false);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const { shop, loading, error } = useShop(data.shopId);
  console.log('rendered', data?.priceInput[0].discountPrice);
  useEffect(() => {

    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist]);

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    console.log('wishlist', data);

    dispatch(addToWishlist({ ...data, currency: shop?.shop?.currency }));
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    // const isItemExists = cart && cart.find((i) => i._id === id);

    console.log('serkali', cart);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (data.stock < 1) {
        toast.error("Product stock limited!");
      } else if (cart.length > 0 && shop?.shop?.currency !== cart[0]?.currency) {
        toast.error("Cant Add Different Currencies to Cart");
      }
      else {

        const cartData = { ...data, qty: 1, currency: shop?.shop?.currency };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };
  const sortedPrices = data?.priceInput.map(item => Number(item.price || item.discountPrice)).sort((a, b) => a - b);

  // Get the smallest and largest values from the sorted array
  const smallestPrice = sortedPrices[0].toFixed(2);
  const largestPrice = sortedPrices[sortedPrices.length - 1].toFixed(2);

  //min order
  const sortedOrders = data?.priceInput.map(item => Number(item.min)).sort((a, b) => a - b);
  const minOrder = sortedOrders[0].toFixed(2);


  return (
    <>
      <div className="w-full h-[370px] bg-white rounded-lg shadow-sm p-3 relative cursor-pointer">
        <div className="flex justify-end"></div>
        {/* {JSON.stringify(data)}
       */}
        <Link to={`${isEvent === true ? `/product/${data._id}?isEvent=true` : `/product/${data._id}`}`}>
          <img
            src={`${data.images && data.images[0]?.url}`}
            alt=""
            className="w-full h-[170px] object-contain"
          />
        </Link>
        <Link to={`/shop/${shop?._id}`}>
          <h5 className={`${styles.shop_name}`}>{shop?.name}</h5>
        </Link>
        <Link to={`${isEvent === true ? `/product/${data._id}?isEvent=true` : `/product/${data._id}`}`}>
          <h4 className="pb-3 font-[500]">
            {data.name.length > 40 ? data.name.slice(0, 40) + "..." : data.name}
          </h4>


          <div className="py-2 flex items-center justify-between">
            <div className="flex">
              <h5 className={`${styles.productDiscountPrice}`}>
                {shop?.shop?.currency}. (<small >from</small> {smallestPrice} - <small >to</small> {largestPrice})
              </h5>

            </div>

          </div>
          {/* Minimum and Maximum Order Inputs */}
          <div className="flex justify-between">
            <div className="mr-2">
              <label className="pb-2">Min Order:</label>
              <span className="font-[400] text-[17px] text-[#68d284] pl-1">
                {minOrder}
              </span>
            </div>

          </div>
        </Link>

        {/* side options */}
        <div>
          {click ? (
            <AiFillHeart
              size={22}
              className="cursor-pointer absolute right-2 top-5"
              onClick={() => removeFromWishlistHandler(data)}
              color={click ? "red" : "#333"}
              title="Remove from wishlist"
            />
          ) : (
            <AiOutlineHeart
              size={22}
              className="cursor-pointer absolute right-2 top-5"
              onClick={() => addToWishlistHandler(data)}
              color={click ? "red" : "#333"}
              title="Add to wishlist"
            />
          )}
          <Link to={`/product/${data._id}`}>
            <AiOutlineEye
              size={22}
              className="cursor-pointer absolute right-2 top-14"
              // onClick={() => setOpen(!open)}
              color="#333"
              title="Quick view"
            />
          </Link>
          <AiOutlineShoppingCart
            size={25}
            className="cursor-pointer absolute right-2 top-24"
            onClick={() => addToCartHandler(data._id)}
            color="#444"
            title="Add to cart"
          />
          {open ? <ProductDetailsCard setOpen={setOpen} data={data} /> : null}
        </div>
      </div>
    </>
  );
};

export default ProductCard;
