import React, { useState, useEffect } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { server } from '../../server';
import { useSelector } from 'react-redux';


// Register the required components with Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const PerformanceChart = () => {
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 30))); // Default to 30 days ago
  const [endDate, setEndDate] = useState(new Date()); // Default to today
  const [data, setData] = useState([]);
  const { user } = useSelector((state) => state.user);
  const { seller } = useSelector((state) => state.seller);
  const userId = user ? user._id : null;
  const sellerId = seller ? seller._id : null;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${server}/tracker/analytics?sellerId=${sellerId}&startDate=${startDate.toISOString().split('T')[0]}&endDate=${endDate.toISOString().split('T')[0]}`
        );
        const result = await response.json();
        setData(result.rows || []); // Ensure data is an array
      } catch (error) {
        console.error("Error fetching data:", error);
        setData([]); // Set empty array on error
      }
    };

    if (sellerId) {
      fetchData();
    }
  }, [sellerId, userId, startDate, endDate]);

  const processChartData = (data) => {
    const dates = [];
    const views = [];
    const newUsers = [];
    const engagementRate = [];
    const conversions = [];
    const transactions = [];
    const bounceRate = [];
    const screenPageViews = [];
    const cities = [];
    const pagePaths = [];
    const pageTitles = [];
    const eventCounts = [];

    // Populate arrays and handle missing values
    data.forEach(d => {
      dates.push(d.dimensionValues[2]?.value || 'Unknown Date');
      views.push(parseInt(d.metricValues[0]?.value) || 0);
      newUsers.push(parseInt(d.metricValues[1]?.value) || 0);
      screenPageViews.push(parseInt(d.metricValues[2]?.value) || 0);
      engagementRate.push(parseFloat(d.metricValues[3]?.value) || 0);
      conversions.push(parseFloat(d.metricValues[4]?.value) || 0);
      transactions.push(parseInt(d.metricValues[5]?.value) || 0);
      bounceRate.push(parseFloat(d.metricValues[6]?.value) || 0);
      cities.push(d.dimensionValues[3]?.value || 'Unknown City');
      pagePaths.push(d.dimensionValues[4]?.value || 'Unknown Path');
      pageTitles.push(d.dimensionValues[5]?.value || 'Unknown Title');
      eventCounts.push(parseInt(d.metricValues[0]?.value) || 0);
    });

    return { dates, views, newUsers, screenPageViews, engagementRate, conversions, transactions, bounceRate, cities, pagePaths, pageTitles, eventCounts };
  };

  const { dates, views, newUsers, screenPageViews, engagementRate, conversions, transactions, bounceRate, cities, pagePaths, pageTitles, eventCounts } = processChartData(data);

  const chartData = {
    labels: dates,
    datasets: [
      {
        label: 'Views',
        data: views,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: 'New Users',
        data: newUsers,
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1,
      },
      {
        label: 'Engagement Rate',
        data: engagementRate,
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
        borderColor: 'rgba(255, 159, 64, 1)',
        borderWidth: 1,
      },
      {
        label: 'Conversions',
        data: conversions,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: 'Transactions',
        data: transactions,
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1,
      },
      {
        label: 'Bounce Rate',
        data: bounceRate,
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
        borderColor: 'rgba(255, 159, 64, 1)',
        borderWidth: 1,
      }
    ],
  };

  const cityData = {
    labels: cities,
    datasets: [
      {
        label: 'Users by City',
        data: eventCounts,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };
  const averageEngagementRate = engagementRate.length 
  ? (engagementRate.reduce((acc, value) => acc + parseFloat(value), 0) / engagementRate.length).toFixed(2)
  : "N/A";

const displayAverageEngagementRate = isNaN(averageEngagementRate) 
  ? "No engagement data available." 
  : `Average Engagement Rate: ${averageEngagementRate}`;




  const averageBounceRate = bounceRate.length 
    ? (bounceRate.reduce((acc, value) => acc + parseFloat(value), 0) / bounceRate.length).toFixed(2)
    : "N/A";
  
  const displayeAverageBounceRate = isNaN(averageBounceRate) 
    ? "No bounce rate data available." 
    : `Average Bounce Rate: ${averageBounceRate}`;
  
  return (
    <div className="performance-chart p-4 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6 text-gray-700">Performance Chart</h2>
      <div className="date-picker mb-6 flex justify-between items-center space-x-4">
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          className="w-full p-2 border rounded-lg shadow-sm focus:ring focus:ring-blue-500"
          dateFormat="yyyy/MM/dd"
        />
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          className="w-full p-2 border rounded-lg shadow-sm focus:ring focus:ring-blue-500"
          dateFormat="yyyy/MM/dd"
        />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="p-4 bg-gray-50 rounded-lg shadow-lg">
          <h3 className="text-lg font-semibold mb-4 text-gray-600">Views over Time</h3>
          <div className="relative w-full h-64 md:h-96">
            <Line
              data={chartData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: { position: 'top' },
                  title: { display: true, text: 'Views over Time' },
                },
                scales: {
                  x: { title: { display: true, text: 'Date' } },
                  y: { title: { display: true, text: 'Count' } },
                },
              }}
            />
          </div>
        </div>
        <div className="p-4 bg-gray-50 rounded-lg shadow-lg">
          <h3 className="text-lg font-semibold mb-4 text-gray-600">Users by City</h3>
          <div className="relative w-full h-64 md:h-96">
            <Bar
              data={cityData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: { position: 'top' },
                  title: { display: true, text: 'Users by City' },
                },
                scales: {
                  x: { title: { display: true, text: 'City' } },
                  y: { title: { display: true, text: 'Count' } },
                },
              }}
            />
          </div>
        </div>
      </div>
      <div className="numerical-values mt-6 p-6 bg-gray-100 rounded-lg shadow-lg">
        <h3 className="text-xl font-semibold mb-4 text-gray-700">Performance Metrics</h3>
        <p className="text-gray-600">Total New Users: {newUsers.reduce((acc, value) => acc + parseInt(value), 0)}</p>
        <p className="text-gray-600">Total Views: {views.reduce((acc, value) => acc + parseInt(value), 0)}</p>
        <p className="text-gray-600">Average Engagement Rate: {displayAverageEngagementRate}</p>
        <p className="text-gray-600">Total Conversions: {conversions.reduce((acc, value) => acc + parseFloat(value), 0).toFixed(2)}</p>
        <p className="text-gray-600">Total Transactions: {transactions.reduce((acc, value) => acc + parseInt(value), 0)}</p>
        <p className="text-gray-600">Average Bounce Rate: {displayeAverageBounceRate}</p>
        <p className="text-gray-600">Total Screen Page Views: {screenPageViews.reduce((acc, value) => acc + parseInt(value), 0)}</p>
        <p className="text-gray-600">Total Users by City: {Object.values(eventCounts).reduce((acc, value) => acc + value, 0)}</p>
      </div>
    </div>
  );
};

export default PerformanceChart;
