// ConversationItem.js
import React from 'react';
import { formatDistanceToNow } from "date-fns";
import { useSelector } from "react-redux";const ConversationItem = ({ conversation, onClick, selectedConversation }) => {
  const { user } = useSelector((state) => state.user);
  const { seller } = useSelector((state) => state.seller);

  const currentUser = seller ? seller : user;

  const messages = Array.isArray(conversation.messages) && conversation.messages.length > 0
    ? conversation.messages[0]
    : null;

  const convertTimestampToDate = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      return new Date(timestamp.seconds * 1000 + (timestamp.nanoseconds || 0) / 1000000);
    }
    return new Date();
  };

  let otherParticipant = conversation.sender?._id === currentUser?._id
    ? conversation.recipient
    : conversation.sender;

  return (
    <div
    className={`w-full flex items-center p-2 hover:bg-gray-100 cursor-pointer transition duration-300 ease-in-out ${
      selectedConversation === conversation ? 'bg-gray-200' : ''
    }`}
    onClick={() => onClick(conversation)}
  >
    <div className="w-10 h-10 flex-shrink-0 relative">
      <img
        src={otherParticipant?.avatar?.url || otherParticipant?.avatar || 'default-avatar-url'}
        alt={otherParticipant?.name || 'User'}
        className="w-full h-full object-cover rounded-full"
      />
      <div className="w-3 h-3 bg-green-500 rounded-full absolute bottom-0 right-0"></div>
    </div>
    <div className="flex-1 ml-2 overflow-hidden">
      <h2 className="text-sm font-semibold truncate">
        {otherParticipant?.name || 'Unknown User'}
      </h2>
      {messages?.text && (
        <p className="text-xs text-gray-700 truncate">{messages.text}</p>
      )}
      <p className="text-xs text-gray-500">
        {messages?.createdAt ? `${formatDistanceToNow(convertTimestampToDate(messages.createdAt))} ago` : 'No timestamp available'}
      </p>
    </div>
  </div>
  );
};

export default ConversationItem;
