import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../styles/styles";
import { Helmet } from "react-helmet";

const DropDown = ({ categoriesData, setDropDown }) => {
  const [currentCategory, setCurrentCategory] = useState(null);
  const keywords = categoriesData.map(category => category.title).join(', ');
  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    navigate(`/products?category=${category.title}`);
    setDropDown(false);
    window.location.reload();
  };

  const handleMouseEnter = (category) => {
    setCurrentCategory(category);
  };

  const handleMouseLeave = () => {
    setCurrentCategory(null);
  };

  return (
    <>  
      <Helmet>
      <title>Shop Categories</title>
      <meta name="description" content="Explore a wide range of categories on our shop." />
      <meta name="keywords" content={keywords} />
    </Helmet>
      <div
        className="pb-4 w-[270px] bg-[#fff] absolute z-30 rounded-b-md shadow-sm"
        onMouseLeave={handleMouseLeave}
      >
        {categoriesData &&
          categoriesData.map((category, index) => (
            <div
              key={index}
              className={`${styles.noramlFlex}`}
              onClick={() => handleCategoryClick(category)}
              onMouseEnter={() => handleMouseEnter(category)}
            >
              <img
                src={category.image_Url}
                style={{
                  width: "25px",
                  height: "25px",
                  objectFit: "contain",
                  marginLeft: "10px",
                  userSelect: "none",
                }}
                alt=""
              />
              <h3 className="m-3 cursor-pointer select-none">{category.title}</h3>
              {currentCategory === category && category.subCategories && (
                <div className="absolute left-full top-0 ml-2 mt-[-10px] bg-white rounded-md shadow-md py-2 px-4">
                  {category.subCategories.map((subCategory, index) => (
                    <div
                      key={index}
                      className="cursor-pointer hover:bg-gray-100 py-1 px-2 rounded-md"
                      onClick={() => handleCategoryClick(subCategory)}
                    >
                      {subCategory.title}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
      </div>
    </>
  );
};

export default DropDown;
