import React, { useState, useEffect } from 'react';
import { FiMinimize, FiMaximize } from 'react-icons/fi';
import ModalContent from './ModalContent'; // Import your ModalContent component
import useShop from '../../hooks/useShop';



const CartData = ({
  handleSubmit,
  shop,
  shops,
  couponCodes,
  setCouponCode,
  county,
  city,
  address,
  discountPercentage,
  selectedOptions,
  calculatePackaging,
  packageTotals,
  setPackageTotals,
  packageTotal,
  selectedPrice,
  couponCodeKey,
  discountPrice,
  selectedProductPrice,
  handleCouponCodeChange,
  handleDeliveryType,
  deliveryType,
  handlePriceSelection,
  setCountyError,
  setCityError,
  setAddressError,
  setAddressTypeError,
  addressType,
  couponDiscount,
  setCouponDiscount,
  handleDiscountChange,
  selectedPackaging,
  setSelectedPackaging,
  setSelectedPackagingTotal
}) => {
  const [detailsVisibility, setDetailsVisibility] = useState(false); // State to manage visibility of each product
  const [productVisibility, setProductVisibility] = useState({}); // State to manage visibility of each product

  // Function to toggle visibility of a specific product
  const toggleProductVisibility = (shopIndex, productIndex) => {
    const key = `${shopIndex}-${productIndex}`;
    setProductVisibility(prevState => ({
      ...prevState,
      [key]: !prevState[key]
    }));
  };


  const toggleAllProductsVisibility = () => {

    console.log('weezyby', address)
    if (!address) {
      setAddressError("Please choose your delivery address!")

    }
    if (!city) {
      setCityError("Please choose your city!")

    }
    if (!addressType) {
      setAddressTypeError("Please Choose Address Type")
      //return;
    }
    if (!county) {
      setCountyError("Please choose your county!")
      // return;
    }
    if (county && addressType && city && address) {
      setDetailsVisibility(!detailsVisibility);
    }
    return
  };

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [nearestPickupLocation, setNearestPickupLocation] = useState(null);

  console.log("Packaging2", selectedOptions);

  // Calculate packaging totals and update state
  useEffect(() => {
    console.log("Packaging", selectedOptions);

    const shippingProduct = [];
    // Iterate through each shopItem and calculate packaging totals for each product
    const updatedPackageTotals = shops?.map(shopItem => {
      const totalCosts = shopItem?.products?.reduce((acc, product) => {
        const { totalCost } = calculatePackaging(product?.packaging, product?.qty);
        shippingProduct.push(product);
        return acc + totalCost;
      }, 0);
      console.log('system2', totalCosts);
      return totalCosts;
    });

    // Set the package totals state
    setPackageTotals(updatedPackageTotals);
    console.log("Packaging3", selectedOptions);
    if (selectedOptions && Array.isArray(selectedOptions)) {
      // Loop through selectedOptions and extract the selected product
      console.log('sherehe', selectedOptions);
      const selectedProducts = selectedOptions?.map((option) => ({
        label: option.props.children[6],
        value: option.props.value,
      }));

      // Set the selected products to state
      setSelectedProducts(selectedProducts);

      // Log the first selected product for testing
      console.log('First selected product:', selectedProducts);
    } else {
      console.error('selectedOptions is not properly initialized or is not an array');
    }

  }, [selectedOptions, setPackageTotals, shops]); // Only recalculate when shop changes

  const calculateTotalCost = (product) => {
    const { type, selectedRangeIndex, qty, samplePriceInput, priceInput } = product;

    console.log('hello wordl', type, selectedRangeIndex, qty, samplePriceInput, priceInput);
    const isSample = type === "sample";
    const priceData = isSample ? samplePriceInput : priceInput;

    // Get the appropriate price or discount price based on selectedRangeIndex
    const selectedPriceData = priceData[selectedRangeIndex];
    const price = selectedPriceData ? selectedPriceData.price : 0;
    const discountPrice = selectedPriceData ? selectedPriceData.discountPrice : 0;

    // Calculate total cost
    const costPerUnit = discountPrice || price;
    const totalCost = costPerUnit * qty;

    return totalCost;
  };

  // const { shop, loading, error } = useShop(product.shopId);

  return (
    <div className="bg-gray-100 w-full max-w-7xl mx-auto justify-center rounded-md p-4 shadow-lg">
      <div className="flex items-center justify-between mb-6">
        <h5 className="text-xl font-semibold text-gray-800">Shop Details</h5>
        <button
          className="focus:outline-none text-gray-500 hover:text-gray-700"
          onClick={toggleAllProductsVisibility} // Toggle visibility of all products
        >
          <div className="flex items-center text-blue-600">
            {/* {detailsVisibility} */}
            {/* {detailsVisibility &&  Object.values(detailsVisibility).every(visible => visible)  ? ( */}
            {detailsVisibility ? (
              <>
                <span className="mr-1">Close All</span> <FiMinimize />
              </>
            ) : (
              <>
                <span className="mr-1">Open All</span> <FiMaximize />
              </>
            )}
          </div>
        </button>
      </div>
      {detailsVisibility && (
        <div className="flex flex-wrap">

          {shops?.map((shopItem, shopIndex) => (

            <div key={shopIndex} className="bg-white rounded-md p-4 shadow-md hover:shadow-lg transition-shadow duration-300 mr-4 mb-4 w-full">
              <div className="flex items-center justify-between"> {/* Added justify-between for right alignment */}
                {/* {JSON.stringify(shopItem.products)} */}
                <div>
                  <img src={shopItem?.avatar?.url} alt={shopItem?.name} className="w-12 h-12 mr-4 rounded-full" />
                  <h3 className="text-lg font-bold text-gray-700 mb-4">{shopItem?.name}</h3>
                </div>
                <div className="">
                  {/* <h3 className="text-sm font-medium text-gray-600">Shop Location:</h3> */}
                  <h5 className="text-xs font-semibold">Address: {shopItem?.address}</h5>
                  {/* {JSON.stringify(shopItem.products[0])} */}
                  <h5 className="text-xs font-semibold">Shop Total: {shopItem?.currency}. {shopItem?.products?.reduce((acc, product) => acc + calculateTotalCost(product), 0).toFixed(2)}</h5>
                </div>
              </div>
              {/* {JSON.stringify('hello',selectedPackaging)} */}
              <div className="flex flex-wrap ">
                {shopItem?.products?.map((product, productIndex) => {
                  const { packagingQuantities, totalCost } = calculatePackaging(product?.packaging, product?.qty);
                  const totalProductCost = calculateTotalCost(product);
                  const visibilityKey = `${shopIndex}-${productIndex}`;

                  return (
                    <div key={product.id} className="mb-4 m-auto w-full sm:w-1/4">
                      <div className="bg-blue-200 rounded-md p-4 shadow-md hover:shadow-lg transition-shadow duration-300">
                        <div className="flex items-center justify-between mb-3">
                          <div className="flex items-center">
                            <img
                              src={product?.images[0]?.url}
                              alt={product?.name}
                              className="w-12 h-12 mr-1 rounded-full"
                            />
                            <div className="flex flex-col mditems-center justify-between w-full">
                              <p className="font-bold text-xs text-gray-700">{product?.name}</p>
                              <p className="text-red-500 text-sm mt-2 md:mt-0 md:ml-1">{shopItem?.currency}{totalProductCost.toFixed(2)}</p>
                            </div>
                          </div>
                          <button
                            className="focus:outline-none text-blue-600 hover:text-blue-800"
                            onClick={() => toggleProductVisibility(shopIndex, productIndex)} // Toggle visibility of this product only
                          >
                            <div className="flex items-center">
                              {productVisibility[visibilityKey] ? (
                                <>
                                  <span className="mr-1 text-xs">Hide Details</span> <FiMinimize />
                                </>
                              ) : (
                                <>
                                  <span className="mr-1 text-xs">Show Details</span> <FiMaximize />
                                </>
                              )}
                            </div>
                          </button>
                        </div>

                        {productVisibility[visibilityKey] && (
                          <ModalContent
                            product={product}
                            index={productIndex}
                            packagingQuantities={packagingQuantities}
                            totalCost={totalCost}
                            deliveryType={deliveryType}
                            nearestPickupLocation={nearestPickupLocation}
                            handleDeliveryType={handleDeliveryType}
                            selectedProducts={selectedProducts}
                            address={address}
                            city={city}
                            county={county}
                            handlePriceSelection={handlePriceSelection}
                            selectedProductPrice={selectedProductPrice}
                            setNearestPickupLocation={setNearestPickupLocation}
                            discountPrice={discountPrice}
                            packageTotal={packageTotal}
                            discountPercentage={discountPercentage}
                            totalProductCost={totalProductCost}
                            handleSubmit={handleSubmit}
                            handleCouponCodeChange={handleCouponCodeChange}
                            shopItem={shopItem}
                            couponDiscount={couponDiscount}
                            setCouponDiscount={setCouponDiscount}
                            handleDiscountChange={handleDiscountChange}
                            selectedPackaging={selectedPackaging}
                            setSelectedPackaging={setSelectedPackaging}
                            setSelectedPackagingTotal={setSelectedPackagingTotal}

                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>)}
    </div>
  )
}

export default CartData;
