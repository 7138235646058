import React, { useState } from 'react';

const TagInput = ({ tags, setTags }) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyPress = (e) => {
    if (e.key === 'Enter' && inputValue.trim() !== '') {
      setTags([...tags, inputValue.trim()]);
      setInputValue('');
    }
  };

  const handleRemoveTag = (index) => {
    const updatedTags = tags.filter((_, i) => i !== index);
    setTags(updatedTags);
  };

  return (
    <div>
   
      <div className="flex flex-wrap">
        {tags.map((tag, index) => (
          <div key={index} className="bg-blue-100 text-blue-800 px-2 py-1 rounded mr-2 mt-1">
            {tag}
            <button type="button" className="ml-2" onClick={() => handleRemoveTag(index)}>
              &times;
            </button>
          </div>
        ))}
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyPress={handleInputKeyPress}
          className="mt-2 appearance-none flex-grow px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          placeholder="Enter your tags..."
        />
      </div>
    </div>
  );
};

export default TagInput;
