import React, { useState, useEffect } from 'react';

const ProductSelect = ({ allProducts, seller, setSelectedProducts, coupons }) => {
  const [selectedProduct, setSelectedProduct] = useState('');

  useEffect(() => {
    if (selectedProduct) {
      setSelectedProduct(selectedProduct);
    }
  }, [selectedProduct]);

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedProduct(selectedValue);
    setSelectedProducts(selectedValue);
  };

  // Filter products based on seller ID
  const filteredProducts = allProducts.filter(product => product.shopId === seller._id);

  // Check if a product is in the coupons for the same seller and shop ID
  const isProductInCoupons = (productId) => {
    return coupons.some(coupon => coupon.selectedProduct === productId && coupon.shopId === seller._id);
  };

  return (
    <>
      {/* {JSON.stringify(coupons)} */}
      <select
        className="w-full mt-2 border h-[35px] rounded-[5px]"
        value={selectedProduct}
        onChange={handleSelectChange}
      >
        <option value="">Choose a selected product</option>
        {filteredProducts.length === 0 && <option disabled>No products available</option>}
        {filteredProducts.map((product) => (
          <option
            value={product._id}
            key={product._id}
            disabled={isProductInCoupons(product._id)}
          >
            {product.name}
          </option>
        ))}
      </select>
    </>
  );
};

export default ProductSelect;
