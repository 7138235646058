// hooks/useNotification.js

import { useState } from 'react';
import axios from 'axios';
import { server } from "../server";
const useNotification = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const sendEmailNotification = async (recipient, subject, message,order) => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    console.log('response', recipient, subject, message,order);
    try {
      const response = await axios.post(`${server}/notification/email`, {
        recipient,
        subject,
        message,
        order
      });
      console.log('response', response);
      setSuccess(response.data.message);
    } catch (err) {
      setError(err.response?.data?.error || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  const sendSMSNotification = async (recipient, message) => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    try {
      const response = await axios.post(`${server}/api/v2/notification/sms`, {
        recipient,
        message,
      });
      setSuccess(response.data.message);
    } catch (err) {
      setError(err.response?.data?.error || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    success,
    sendEmailNotification,
    sendSMSNotification,
  };
};

export default useNotification;
