import React from "react";
import { useSelector } from "react-redux";
import EventCard from "../components/Events/EventCard";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";
import { Helmet } from "react-helmet";


const EventsPage = () => {
  const { allEvents, isLoading } = useSelector((state) => state.events);
  return (
    <>
      <Helmet>
        <title>Upcoming Events - Indazetu</title>
        <meta name="description" content="Stay updated with the latest events and happenings. Explore a wide range of events including trade shows, exhibitions, and more at Indazetu." />
        <meta name="keywords" content="upcoming events, Indazetu events, trade shows, exhibitions, business events, networking events, event calendar, Indazetu" />
        <meta property="og:title" content="Upcoming Events - Indazetu" />
        <meta property="og:description" content="Stay updated with the latest events and happenings. Explore a wide range of events including trade shows, exhibitions, and more at Indazetu." />
        <meta property="og:image" content="https://indazetu.com/static/media/logoo-removebg-preview.041ee5fc1e771ab093a6.png" />
        <meta property="og:url" content="https://indazetu.com/events" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://indazetu.com/events" />
      </Helmet>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Header activeHeading={4} />
          <EventCard active={true} data={allEvents && allEvents[0]} />
        </div>
      )}
    </>
  );
};


export default EventsPage;
