import React from "react";
import { GoogleMap, LoadScript } from "@react-google-maps/api";


const MapContainer = ({ address }) => {
    const apiKey = 'AIzaSyCLQZ4zK4i688X3f9zEjJmk8OdHR6Zdxis';

  
    // Define the map options
    const mapStyles = {
        height: "100%",
        width: "100%",
        borderRadius: "8px",
    };

    // Set the center of the map based on the address

    return (
        <LoadScript googleMapsApiKey={apiKey}>
          <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={10}
            center={address}
          ></GoogleMap>
        </LoadScript>
        // <>
        //     <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2990.274257380938!2d-70.56068388481569!3d41.45496659976631!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e52963ac45bbcb%3A0xf05e8d125e82af10!2sDos%20Mas!5e0!3m2!1sen!2sus!4v1671220374408!5m2!1sen!2sus"
        //     title="Map" width="400" height="250" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        // </>
    );
};

export default MapContainer;
