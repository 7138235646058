import { useState } from 'react';

const EditForm = ({ message, onSubmit, onCancel }) => {
  const [editedText, setEditedText] = useState(message.text);

  const handleChange = (e) => {
    setEditedText(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Call onSubmit prop with the edited message object
    onSubmit({ ...message, text: editedText });
  };

  const handleCancel = () => {
    // Call onCancel prop to cancel editing
    onCancel();
  };

  return (
    <form onSubmit={handleSubmit} className="bg-white rounded-lg p-4 shadow-md">
      <textarea
        value={editedText}
        onChange={handleChange}
        rows={6}
        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
        placeholder="Enter your edited message..."
        required
      />
      <div className="flex justify-end mt-4">
        <button type="button" onClick={handleCancel} className="mr-4 px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
          Cancel
        </button>
        <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          Save
        </button>
      </div>
    </form>
  );
};

export default EditForm;
